import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  Col,
  Row,
  Container,
  Button,
  Card,
  Image,
  Modal
} from "react-bootstrap";
import { toast } from "react-toastify";

import api from "../../../services/api";

import * as IO from "react-icons/io5";
import * as MD from "react-icons/md";
import PolicyGroupImg from "../../../assets/img/policy-group.svg"
import history from "../../../services/history";

function RegisteredPolicies(props) {
  const [modalDeletingConfirm, setModalDeletingConfirm] = useState(false);
  const tenant = useSelector((state) => state.user.profile.tenants[0]);
  const [loading, setLoading] = useState(false);
  const [editGroupPolicy, setEditGroupPolicy] = useState(false)
  const [editGroupPolicyData, setEditGroupPolicyData] = useState([])
  const [allAcEntry, setAllAcEntry] = useState([]);
  const [selectedAvailableTerminal, setSelectedAvailableTerminal] = useState([]);
  const [selectedTerminalData, setSelectedTerminalData] = useState([]);
  const [selectedTerminal, setSelectedTerminal] = useState([]);
  const [delSelectedTerminal, setDelSelectedTerminal] = useState([]);
  const [updateAllAcEntry, setUpdateAllAcEntry] = useState(false);
  const [removePolicy, setRemovePolicy] = useState({});

  useEffect(() => {
    if (updateAllAcEntry) {
      UpdateAllAcEntry()
    }
    if (!loading) {
      GetAllAcEntry();
      setLoading(true)
      return
    }
    if (editGroupPolicy) {
      SendInfo(editGroupPolicyData)
    } else {
      setEditGroupPolicy(false)
      SendInfo()
    }
    setLoading(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editGroupPolicy, allAcEntry, selectedTerminalData, delSelectedTerminal])

  function AddPoliciesGroup() {
    props.addPoliciesGroup(true)
  }

  async function GetAllAcEntry() {
    try {
      const response = await api.get(`/AdminArea/GetAllAcEntry/${tenant}`);
      const { status, data } = response;

      if (response.status === 200 && status) {
        setAllAcEntry(data);
        setLoading(true);
      } else {
        toast.error(`Erro na solicitação!`);
      }
    } catch (error) {
      if (!!error.response && error.response.status !== 401) {
        toast.error("Erro na solicitação.. tente mais tarde");
      }
    }
  }

  function SendInfo(data) {
    if (editGroupPolicy) {
      props.edit(data, editGroupPolicy, props.sched[0])
      setEditGroupPolicy(true)
    } else {

    }
  }

  function SelectedAvailableTerminal(x, y, data) {
    if (selectedAvailableTerminal.find((y) => y === x)) {
      let arr = selectedAvailableTerminal.filter((a) => a !== x);
      setSelectedAvailableTerminal(arr)
    } else {
      setSelectedAvailableTerminal((selectedAvailableTerminal) => [...selectedAvailableTerminal, x]);
    }
  }

  function SelectedTerminal(x, y, data) {
    if (selectedTerminal.find((y) => y === x)) {
      let arr = selectedTerminal.filter((a) => a !== x);
      setSelectedTerminal(arr)
    } else {
      setSelectedTerminal((selectedTerminal) => [...selectedTerminal, x]);
    }
  }

  function AddSelectedTerminal() {
    if (selectedAvailableTerminal.length > 0) {

      selectedAvailableTerminal.map((x) => {
        let data = allAcEntry.find(acEntryId => acEntryId.acEntryId === x)
        setSelectedTerminalData((selectedTerminalData) => [...selectedTerminalData, data]);
        setDelSelectedTerminal([])
        setUpdateAllAcEntry(true)
        setSelectedAvailableTerminal([])

        return x
      })
    } else {
      toast.warning("Selecione pelo menos um terminal para adicionar!")
    }
  }

  function DelSelectedTerminal() {
    if (selectedTerminal.length > 0) {

      selectedTerminal.map((x) => {
        let data = selectedTerminalData.find(acEntryId => acEntryId.acEntryId === x)
        setDelSelectedTerminal((delSelectedTerminal) => [...delSelectedTerminal, data]);
        setUpdateAllAcEntry(true)
        setSelectedTerminal([])

        return x
      })
    } else {
      toast.warning("Selecione pelo menos um terminal para remover!")
    }
  }

  function UpdateAllAcEntry() {
    if (selectedTerminalData.length > 0) {
      let arr = allAcEntry;
      let values = selectedTerminalData;
      arr = arr.filter(item => !values.includes(item));
      setAllAcEntry(arr)
      setUpdateAllAcEntry(false)
    }
    if (delSelectedTerminal.length > 0) {
      delSelectedTerminal.map((x) => {
        setAllAcEntry((allAcEntry) => [...allAcEntry, x])
        return x
      })
      setDelSelectedTerminal([])
      let arr = selectedTerminalData;
      let values = delSelectedTerminal;
      arr = arr.filter(item => !values.includes(item));
      setSelectedTerminalData(arr)
    }
  }

  async function OnSubmit() {
    let AcEntryGroupEnrollments = []
    selectedTerminalData.map((x) => {
      AcEntryGroupEnrollments.push({ "AcEntryId": x.acEntryId })
      return x
    })

    let dataToApi = {
      "Name": props.ident.name,
      "Description": props.ident.description,
      "DataKey": tenant,
      "AcEntryGroupEnrollments": AcEntryGroupEnrollments,
      "EntryGroupPolicies": props.sched[0]
    }
    if (AcEntryGroupEnrollments.length <= 0) {
      toast.warning("Adicione pelo menos um terminal!")
      return
    }
    try {
      await api.post(`/AdminArea/PostEntryGroup`, dataToApi);
      toast.success(`Grupo cadastrado com sucesso!`);
      history.push("/PolicyGroup")
    } catch (err) {
      toast.error(err.message)
      console.log(err.message);
    }

  }

  function RemovePolicy(policy) {
    props.removePolicy(policy)
  }

  function ConfirmRemovePolicy(x) {
    setRemovePolicy(x); setModalDeletingConfirm(true)
  }

  return (
    <>
      <Row>

        <Col>
          <Col xs={12} md={5} >
            <Row>
              <Card style={{ backgroundColor: "#212529", color: "#f1f3f5" }}>
                <Card.Body>
                  <Row>
                    <Col>
                      <Image
                        fluid
                        src={PolicyGroupImg}
                        style={{ width: 65 }}
                      />
                    </Col>
                    <Col xs={9} md={9}>
                      <Card.Title as="small">IDENTIFICAÇÃO DO GRUPO</Card.Title>
                      <Card.Text>{props.ident.name}</Card.Text>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Row>
          </Col>
          <Row className="justify-content-between mb-3">
            <Container>
              <Row>
                <Col>
                  <Card className="my-2">
                    <Card.Body className="px-0">
                      <Row className="pb-3 py-2 ms-3">
                        <Col md={7}>
                          <Card.Title as="h5">Políticas em Grupo</Card.Title>
                        </Col>
                        <Col
                          md={{ span: 3, offset: 2 }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <small className="me-1">ADICIONAR POLÍTICAS</small>
                          <MD.MdAddCircle
                            size="30"
                            style={{ color: "#37b24d", cursor: "pointer" }}
                            onClick={() => { AddPoliciesGroup() }}

                          />
                        </Col>
                      </Row>
                      <Row className="ps-3 p-2 border-terminal m-0">
                        <Col as="h6">
                          Nome
                        </Col>
                        <Col className="text-center pe-1" as="h6">
                          Descrição
                        </Col>
                        <Col className="text-end pe-5" as="h6">
                          Ações
                        </Col>

                      </Row>
                      <Col className="terminal-card py-2 p-0">
                        {props.sched[0].map((x, id) => (

                          <Card key={id + 1} className="mt-2 py-2 text-center" style={{ borderRadius: "0.1px" }}>
                            <Card.Body>
                              <Row>
                                <Col className="p-0 ps-5 mx-1 text-start">
                                  <strong> <span>
                                    {x.Name == null ? "..." : x.Name}
                                  </span></strong>
                                </Col>
                                <Col className=" text-center  ">
                                  {x.RequiredKey === "" ? "..." : x.RequiredKey}
                                </Col>
                                <Col className="p-0 pe-5 text-end">
                                  <MD.MdModeEdit

                                    onClick={() => { setEditGroupPolicyData(x); setEditGroupPolicy(true) }}
                                    className="me-4"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <IO.IoTrash onClick={() => { props.sched[0].length > 1 ? ConfirmRemovePolicy(x) : toast.warning("Grupo não pode ficar sem políticas!") }} style={{ cursor: "pointer" }} />
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        )
                        )}
                      </Col>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <Card.Body className="px-0">
                      <Card.Title className="ps-3">Terminais Disponíveis</Card.Title>
                      <Row className="ps-3 p-2 border-terminal m-0">
                        <Col xs={3} md>
                          Nome
                        </Col>
                        <Col xs={6} md>
                          N.Serie
                        </Col>
                        <Col xs={3} md>
                          AcEntryId
                        </Col>
                      </Row>
                      <Col className="p-0 py-2 terminal-card" >
                        {allAcEntry.map((x, y) => (
                          <>

                            <Card key={x.acEntryId} className={
                              Object.values(selectedAvailableTerminal).indexOf(x.acEntryId) >= 0
                                ? "mt-2 py-2 text-center selected-border"
                                : "mt-2 py-2 text-center "
                            } onClick={() => { SelectedAvailableTerminal(x.acEntryId, y, x) }} style={{ borderRadius: "0.1px" }}>
                              <Card.Body style={{ cursor: "pointer" }}>
                                <Row>
                                  <Col className="p-0 mx-1">
                                    <span>
                                      <strong>{x.name}</strong>
                                    </span>
                                  </Col>
                                  <Col className="pl-sm-1 p-0">
                                    <span>{x.referenceCode}</span>
                                  </Col>
                                  <Col className="pl-sm-1 p-0 text" title={x.acEntryId}>
                                    <span>{x.acEntryId}</span>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </>
                        ))}
                      </Col>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="pt-5" sm={2}>
                  <Row onClick={() => AddSelectedTerminal()}>
                    <MD.MdAddCircle
                      size="50"
                      style={{ color: "#37b24d", cursor: "pointer" }}
                    />
                  </Row>

                  <Row onClick={() => DelSelectedTerminal()}>
                    <MD.MdRemoveCircle
                      size="50"
                      style={{ color: "#e03131", cursor: "pointer" }}
                    />
                  </Row>

                </Col>
                <Col>
                  <Card>
                    <Card.Body className="px-0">
                      <Card.Title className="ps-3">Terminais Adicionandos</Card.Title>
                      <Row className="ps-3 p-2 border-terminal m-0">
                        <Col xs={3} md>
                          Nome
                        </Col>
                        <Col xs={6} md>
                          N.Serie
                        </Col>
                        <Col xs={3} md>
                          AcEntryId
                        </Col>
                      </Row>
                      <Col className="p-0 py-2 terminal-card" >
                        {selectedTerminalData.map((x, y) => (

                          <Card key={x.acEntryId} className={
                            Object.values(selectedTerminal).indexOf(x.acEntryId) >= 0
                              ? "mt-2 py-2 text-center selected-border"
                              : "mt-2 py-2 text-center "
                          } onClick={() => { SelectedTerminal(x.acEntryId, y, x) }} style={{ borderRadius: "0.1px" }}>
                            <Card.Body style={{ cursor: "pointer" }}>
                              <Row>
                                <Col className="p-0 mx-1">
                                  <span>
                                    <strong>{x.name}</strong>
                                  </span>
                                </Col>
                                <Col className="pl-sm-1 p-0">
                                  <span>{x.referenceCode}</span>
                                </Col>
                                <Col className="pl-sm-1 p-0 text" title={x.acEntryId}>
                                  <span>{x.acEntryId}</span>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        ))}
                      </Col>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="d-flex justify-content-md-center">
                <Col md={{ span: 6, offset: 3 }}>
                  <Button onClick={() => { OnSubmit() }} className="BtnPrimary">FINALIZAR CADASTRO</Button>
                </Col>
              </Row>
            </Container>
          </Row>
        </Col>
      </Row >


      <Modal
        dialogClassName="border-radius-2"
        show={modalDeletingConfirm}
        onHide={() => setModalDeletingConfirm(false)}
        centered
        size="sm"
        // animation={false}
        style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      >
        <Modal.Body>
          <Row className="justify-content-center text-center pb-3">
            <h5 className="my-3 pb-4 border-bottom">Excluir Política?</h5>

            <Button
              style={{ border: "none", backgroundColor: "var(--bg-red)" }}
              className="col-4 me-1" onClick={() => setModalDeletingConfirm(false)}
            >Não</Button>
            <Button
              style={{ border: "none", backgroundColor: "var(--bg-green)" }}
              className="col-4 ms-1" onClick={() => { RemovePolicy(removePolicy); setModalDeletingConfirm(false) }}
            >Sim</Button>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RegisteredPolicies;
