import React from "react";
import PropTypes from "prop-types";

import HeaderClient from '../../../components/HeaderClient';
/* import Footer from "../../../components/Footer"; */

function ClientLayout({ children }) {
    return (
        <>
            <HeaderClient />
            {children}

        </>
    );
}

export default ClientLayout;

ClientLayout.prototype = {
    children: PropTypes.element.isRequired
}