import { createGlobalStyle } from "styled-components";
import "../assets/bootstrap-5.1.3/css/bootstrap.css"
import "react-toastify/dist/ReactToastify.css";

export default createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Ropa+Sans&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@import url(//db.onlinewebfonts.com/c/5fd82f3bb8dcfc3ce611fab541b92241?family=DIN+Pro+Medium);

@font-face {font-family: "DIN Pro Medium"; src: url("//db.onlinewebfonts.com/t/5fd82f3bb8dcfc3ce611fab541b92241.eot"); src: url("//db.onlinewebfonts.com/t/5fd82f3bb8dcfc3ce611fab541b92241.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/5fd82f3bb8dcfc3ce611fab541b92241.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/5fd82f3bb8dcfc3ce611fab541b92241.woff") format("woff"), url("//db.onlinewebfonts.com/t/5fd82f3bb8dcfc3ce611fab541b92241.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/5fd82f3bb8dcfc3ce611fab541b92241.svg#DIN Pro Medium") format("svg"); }

@import url(//db.onlinewebfonts.com/c/15a478cc0eb1d84f4077a2a9d28df0b2?family=DIN+Pro+Regular);
    *{
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
       font-family: "DIN Pro Regular", 'Roboto', sans-serif;
    }

    :root {
        --bg-primary: #003366;
        --bg-secondary: #f04f23;
        --bg-off-white: #f3f2f3;
        --bg-green: #40C057;
        --bg-gray: #D5D5D5;
        --bg-red: #E03131;
        
        --bg-blue-light: #3F7FBF;

        --text-title: #212529;
        --text-body: #495057;

        --background: #F1F3F5;
        --shape: #FFFFFF;
        --shape-add: #E9ECEF;


        --shadow:
            0 2px 23px 0 rgba(0, 0, 0, 0.1),
            0 2px 49px 0 rgba(0, 0, 0, 0.06),
    }


    *:focus {
        outline: 0;
    }

    html, body, #root {
        height: 100%;
    }

    body{
        background: var(--background);
        -webkit-font-smoothing: antialiased;
    }
a {
       color:white;
 text-decoration: none
}
   a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
       color:white;

}

a:active {
  text-decoration: none;
}

    ul{
        list-style: none;
    }

    button {
        cursor: pointer;      
    }



    /* Forms and Inputs */
    .form-control {
        border: none;
        border-bottom: 2px solid var(--bg-gray);
        background-color: transparent;
        border-radius: 0;
        color: black
    }
  
    .form-control:focus {
        border-color: var(--bg-secondary);
        background-color: transparent;
        box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0.25);
                  
    }

    .form-select:focus {
        border-color: var(--bg-secondary);
        
        box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0.25);
    }

    .form-select {
        border: none;
        border-bottom: 2px solid var(--bg-gray);
        border-radius: 0;
    }

    .input-group-text {
        background: none;
        border: none;
        /* border-bottom: 2px solid var(--bg-gray); */
        border-radius: 0;
        padding: 0.375rem 0rem;
    }

    /* Elementos */
    .BtnIcon{
        border-radius: 6px;
        border:none;
        padding:3px 7px;
    }

    .BtnPrimary{
        background-color: #003366;
        color: #f3f2f3;     
        border: none;
    }
    .BtnPrimary:focus {
        background-color: #003366;
        box-shadow: none;    
    }
    .BtnPrimary:hover{
        background-color: rgba(0, 51, 102, 0.85);
        color: white;
        box-shadow: none;
        border: none;
    }

    .text{
    overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 250px;
  
}
`