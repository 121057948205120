import React, { useEffect, useState } from "react";
import {
  Button, Card,
  Col, Modal, Row
} from "react-bootstrap";
import * as MD from "react-icons/md";
import { toast } from "react-toastify";
import PolicyGroupImg from "../../assets/img/policy-group.svg";
import api from "../../services/api";
import history from "../../services/history";
import "./styles.css";


function GroupCard(props) {


  const [modalDeletingConfirm, setModalDeletingConfirm] = useState(false);
  // const [cardShow, setCardShow] = useState(false);

  const terminalPolicy = () => {
    history.push(`/PolicyGroupDetails/id:${props.acEntryGroup.entryGroupId}`);
  };

  useEffect(() => {

  }, []);
  async function DeleteData(id) {
    try {
      const response = await api.delete(`/AdminArea/DeleteEntryGroup/${id}`)
      const { status } = response

      if (status === 200 && status) {
        toast.success("Excluído com sucesso!")
        props.updateFunction(); //Chama a Função do HomeScreen para trazer os cards atualizados do banco
        /* setCardShow(false) */;//Fecha o modal da edição

      } else {
        toast.error(`Erro na exclusão!`);
      }
    } catch (error) {
      if ((!!error.response && error.response.status !== 401)) {
        toast.error("Erro na exclusão.. tente mais tarde");
      }
    }
  }

  return (
    <>

      <Row >
        <Col>
          <Card className="px-3 py-2" style={{ cursor: "pointer", height: '15rem' }}>
            <Row className="mt-2">
              <Card.Img onClick={terminalPolicy}
                className="me-auto"
                src={PolicyGroupImg}
                style={{ width: 90 }}
              />
              <Col style={{ textAlign: "right" }}>

                <Button
                  onClick={() => setModalDeletingConfirm(true)}
                  style={{
                    color: "#868e96",
                    paddingTop: 0,
                    paddingLeft: 5,
                    paddingRight: 5,
                    background: "#ced4da",
                    border: "none",
                  }}
                >
                  <MD.MdDelete size={18} />
                </Button>{" "}
              </Col>
            </Row>
            <Row className="pt-2 p-2 " >
              {/*  <Card.Img
                className="me-auto"
                src={TerminalImg}
                style={{ width: 90 }}
              /> */}
            </Row>

            <Card.Body onClick={terminalPolicy}>
              <h6>
                Nome: <span> {props.acEntryGroup.name}</span>
              </h6>
              {/*  <h6>
                IP: <span> 10.125.353.58</span>
              </h6> */}
            </Card.Body>
            <Card.Footer>
              <div className="card-container">
                {/*  <button type="button"  className="btn-card">
                  VER MAIS
                </button> */}
                <button
                  type="button"
                  onClick={terminalPolicy}
                  className="btn-card"
                >
                  VER DETALHES
                </button>
              </div>
            </Card.Footer>
          </Card>
        </Col>
      </Row>

      {/* Modal Viewr Details */}



      <Modal
        dialogClassName="border-radius-2"
        show={modalDeletingConfirm}
        onHide={() => setModalDeletingConfirm(false)}
        centered
        size="sm"
        // animation={false}
        style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      >
        <Modal.Body>
          <Row className="justify-content-center text-center pb-3">
            <h5 className="my-3 pb-4 border-bottom">Excluir política em grupo?</h5>

            <Button
              style={{ border: "none", backgroundColor: "var(--bg-red)" }}
              className="col-4 me-1" onClick={() => setModalDeletingConfirm(false)}
            >Não</Button>
            <Button
              style={{ border: "none", backgroundColor: "var(--bg-green)" }}
              className="col-4 ms-1" onClick={() => { DeleteData(props.acEntryGroup.entryGroupId); setModalDeletingConfirm(false) }}
            >Sim</Button>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default GroupCard;
