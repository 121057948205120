import { React } from "react";
import { Container, Nav } from "react-bootstrap";
import * as MD from "react-icons/md";
import { LinkContainer } from "react-router-bootstrap";
import { withRouter } from "react-router-dom";
import "./styles.css";


function Sidebar() {
  const page = window.location.pathname;

  return (
    <>
      <Container className="mt-5">
        <Nav
          style={{ gap: "1rem" }}
          variant="pills"
          className="flex-column sidebar py-4"
        >
          <Nav.Item>
            <LinkContainer to="/Homescreen">
              <Nav.Link className="sidebar-link">
                <div
                  className={
                    page === "/Homescreen" ? "border-sidebar" : "sidebar-link"
                  }
                >
                  <MD.MdDashboard className="mb-1" size={20} /> Terminais
                </div>
              </Nav.Link>
            </LinkContainer>
          </Nav.Item>
          <Nav.Item>
            <LinkContainer to="/PolicyGroup">
              <Nav.Link className="sidebar-link">
                <div
                  className={
                    page === "/PolicyGroup" ? "border-sidebar" : "sidebar-link"
                  }
                >
                  <MD.MdSecurity className="mb-1" size={20} /> Política em grupo
                </div>
              </Nav.Link>
            </LinkContainer>
          </Nav.Item>
        </Nav>
      </Container>
    </>
  );
}

export default withRouter(Sidebar);
