import React from "react";

import { Card } from "react-bootstrap";

import * as MD from "react-icons/md";
import history from "../../services/history";
import "./styles.css";

function AddCardPolicyGroup() {
  const AddGroup = () => {
    history.push("/AddGroup");
  };
  return (
    <>
      <Card
        className="card_addterminal"
        onClick={AddGroup}
        style={{ cursor: "pointer", height: '13rem' }}
      >
        <Card.Body>
          <div>
            <MD.MdAdd size={40} />
          </div>
          <span>Adicionar grupo</span>
        </Card.Body>
      </Card>
    </>
  );
}

export default AddCardPolicyGroup;
