import { addDays, endOfDay, isBefore } from 'date-fns';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Button, Col, Container, Row, Table, Modal } from 'react-bootstrap';
import * as MD from 'react-icons/md';
import ReactToPrint from 'react-to-print';
import { toast } from 'react-toastify';
import bradescoLogo from '../../assets/img/bradescoLogo.png';
import './style.css'
import Barcode from 'react-barcode';

function BankSlipDisplay({ data, modal = true }) {
    const canPayYet = useMemo(() => {
        const fimDoDiaMaxPagar = endOfDay(
            addDays(
                new Date(data.dtLimitePagamentoBoleto.split("/").reverse().join("-")), 1
            )
        );
        return isBefore(Date.now(), fimDoDiaMaxPagar);
    });

    useEffect(() => {

    }, []);

    const [showModal, setShowModal] = useState(modal);
    const componentRef = useRef();
    const pdfRef = useRef();
    const options = {
        orientation: 'landscape',
        unit: 'in',
        format: [3508, 2480]
    };


    function Copy(text) {
        try {
            var textArea = document.createElement("textarea");

            textArea.style.position = "fixed";
            textArea.style.top = 0;
            textArea.style.left = 0;
            textArea.style.width = "2em";
            textArea.style.height = "2em";
            textArea.style.padding = 0;
            textArea.style.border = "none";
            textArea.style.outline = "none";
            textArea.style.boxShadow = "none";
            textArea.style.background = "transparent";
            textArea.value = text;

            document.body.appendChild(textArea);
            textArea.select();

            var successful = document.execCommand("copy");
            successful ? toast.info("Texto copiado") : toast.warn("Texto não copiado");
        } catch (err) {
            toast.error("Ocorreu um erro ao copiar");
        }
        document.body.removeChild(textArea);
    };


    return (
        <>
            <Row className="justify-content-center">


                <Col className="col-auto p-4">
                    <Button className='BtnPrimary' onClick={() => setShowModal(true)}><MD.MdPictureAsPdf size={20} className="me-1" />Abrir PDF</Button >
                </Col>
                <Col className="col-auto py-4">
                    <Button className='BtnPrimary' onClick={() => Copy(data.linhaDigitavel)}><MD.MdCopyAll size={20} className="me-1" />Copiar código de barra</Button >
                </Col>

            </Row>
            <Row>
                <Col className='p-5'>

                    <Table striped bordered hover>

                        <tbody>
                            <tr>
                                <td style={{ fontWeight: "bold" }}>Linha Digitavel</td>
                                <td>{data.linhaDigitavel}</td>

                            </tr>
                            <tr>
                                <td style={{ fontWeight: "bold" }}>Banco</td>
                                <td>Bradesco</td>

                            </tr>
                            <tr>
                                <td style={{ fontWeight: "bold" }}>Cod. do Banco</td>
                                <td>237-2</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: "bold" }}>Emissão</td>
                                <td>{data.dtEmissao}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: "bold" }}>Vencimento</td>
                                <td>{data.dtVencimentoBoleto}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: "bold" }}>Valor</td>
                                <td>{data.priceFormatted}</td>
                            </tr>




                        </tbody>
                    </Table>
                    <Row>

                        <Barcode value={data.cdBarras} format="itf" />
                    </Row>
                </Col>
            </Row>
            <Modal show={showModal} fullscreen={true} onHide={() => setShowModal(false)} >

                <Modal.Header closeButton>
                </Modal.Header>
                <Row className="justify-content-center mt-5">

                    <Col className="col-auto me-2">
                        <ReactToPrint
                            trigger={() => <Button className='BtnPrimary'><MD.MdPrint size={20} className="me-1" />Imprimir</Button>}
                            content={() => componentRef.current}
                        />
                    </Col>
                    <Col className="col-auto me-2">
                        <Button className='BtnPrimary' onClick={() => Copy(data.linhaDigitavel)}><MD.MdCopyAll size={20} className="me-1" />Copiar código de barra</Button >
                    </Col>


                </Row>

                <Row>
                    <Col >
                        {!canPayYet && (
                            <Alert variant={`danger`}>
                                Este boleto já passou do prazo limite para pagamento.
                            </Alert>
                        )}
                        <Container align="center" ref={pdfRef}  >
                            <Modal.Body ref={componentRef}>
                                <table border='0' className='Boleto' >
                                    <div className=' mx-2'>

                                        <tr>
                                            <td colspan='11' class='BoletoPontilhado'>&nbsp;</td>
                                        </tr>
                                        <tr >
                                            <td colspan="4" className="BoletoLogo"><img src={bradescoLogo} /></td>
                                            <td colspan="2" className="BoletoCodigoBanco">237-2</td>
                                            <td colspan="6" className="BoletoLinhaDigitavel">{data.linhaDigitavel}</td>
                                        </tr>
                                        <tr >
                                            <td colspan='10' className='BoletoTituloEsquerdo'>Local de Pagamento</td>
                                            <td className='BoletoTituloDireito'>Vencimento </td>
                                        </tr>
                                        <tr>
                                            <td colspan="10" className='BoletoValorEsquerdo' style={{ textAlign: "left", paddingLeft: "0.1cm" }}>Até o vencimento, preferencialmente no Banco Bradesco</td>
                                            <td className='BoletoValorDireito'>{data.dtVencimentoBoleto}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="10" className='BoletoTituloEsquerdo'>
                                                Nome do Beneficiário / CNPJ / CPF / Endereço:</td>
                                            <td className='BoletoTituloDireito'>Data do Documento</td>

                                        </tr>
                                        <tr>
                                            <td colspan="10" className='BoletoValorEsquerdo' style={{ textAlign: "left", paddingLeft: "0.1cm" }}> {data.nomeBeneficiario}  {`${data.logradouroBeneficiario} ${data.nuLogradouroBeneficiario} ${data.complementoLogradouroBeneficiario} - ${data.municipioBeneficiario} - ${data.ufBeneficiario} - ${data.cepBeneficiario}`}</td>
                                            <td className='BoletoValorDireito'>{data.dtEmissao}</td>
                                        </tr>


                                        <tr>
                                            <td colspan="7" className='BoletoTituloEsquerdo'>Número do titulo</td>
                                            <td className='BoletoTituloEsquerdo'>
                                                Espécie doc
                                            </td>
                                            <td className='BoletoTituloEsquerdo'>
                                                Aceite
                                            </td>
                                            <td colspan="0" className='BoletoTituloDireito'>
                                                Data Processamento
                                            </td>

                                        </tr>
                                        <tr>
                                            <td colspan="7" className='BoletoValorEsquerdo'>{data.nuTituloGerado}</td>
                                            <td className='BoletoValorEsquerdo'>DS</td>
                                            <td className='BoletoValorEsquerdo'>N</td>
                                            <td colspan="4" className='BoletoValorDireito'>{data.dtEmissao}</td>
                                        </tr>

                                        <tr>
                                            <td colspan="5" className='BoletoTituloEsquerdo'>Uso do Banco</td>

                                            <td colspan="2" className='BoletoTituloEsquerdo'>Moeda</td>
                                            <td colspan="2" className='BoletoTituloEsquerdo'>Quantidade</td>
                                            <td className='BoletoTituloEsquerdo'>(x) Valor</td>
                                            <td className='BoletoTituloDireito'>(=) Valor do Documento</td>
                                        </tr>
                                        <tr>
                                            <td colspan="5" className='BoletoValorEsquerdo'>&nbsp;</td>
                                            <td colspan="2" className='BoletoValorEsquerdo'>R$</td>
                                            <td colspan="2" className='BoletoValorEsquerdo'>N</td>
                                            <td className='BoletoValorEsquerdo'>{data.priceFormatted}</td>
                                            <td className='BoletoValorDireito'>{data.priceFormatted}</td>
                                        </tr>

                                        <tr>
                                            <td colspan="10" className='BoletoTituloEsquerdo'>Instruções</td>
                                            <td className='BoletoTituloDireito'>(-) Desconto</td>
                                        </tr>
                                        <tr>
                                            <td colspan="10" rowspan='9' className='BoletoValorEsquerdo'
                                                style={{ textAlign: "left", verticalAlign: "top", paddingLeft: "0.1cm" }}> Instruções de responsabilidade do BENEFICIÁRIO. Qualquer
                                                dúvida sobre este boleto contate o beneficiário.</td>
                                            <td className='BoletoValorDireito'>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td className='BoletoTituloDireito'>(-) Outros Abatimentos</td>
                                        </tr>
                                        <tr>
                                            <td className='BoletoValorDireito'>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td className='BoletoTituloDireito'>(+) Mora/Multa/Juros</td>
                                        </tr>
                                        <tr>
                                            <td className='BoletoValorDireito'>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td className='BoletoTituloDireito'>(+) Outros Acréscimos</td>
                                        </tr>
                                        <tr>
                                            <td className='BoletoValorDireito'>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td className='BoletoTituloDireito'>(=) Valor Cobrado</td>
                                        </tr>
                                        <tr>
                                            <td className='BoletoValorDireito'>&nbsp;</td>
                                        </tr>

                                        <tr>
                                            <td rowspan='3' className='BoletoTituloSacado'>Nome do Pagador:</td>
                                            <td colspan="8" className='BoletoValorSacado'>{data.nomePagador}</td>
                                            <td colspan="2" className='BoletoValorSacado'>CPF/CNPJ: {data.cpfcnpjPagador}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="10" className='BoletoValorSacado'>{data.enderecoPagador} -{" "}
                                                {data.bairroPagador} - {data.municipioPagador} -{" "}
                                                {data.ufPagador} - {data.cepPagador}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="9" >
                                                <Barcode value={data.cdBarras} format="itf" />
                                                {/*  <svg
                                                id="barcode"
                                                jsbarcode-format="itf"
                                                jsbarcode-value={data.cdBarras}
                                                jsbarcode-textmargin="0"
                                                jsbarcode-fontoptions="bold"
                                                jsbarcode-displayvalue="true"
                                                jsbarcode-background="#ffffff"
                                            ></svg> */}</td>
                                        </tr>



                                    </div>


                                </table>

                            </Modal.Body>
                        </Container>


                    </Col>
                </Row>
            </Modal>

        </>
    );
}

export default BankSlipDisplay;
