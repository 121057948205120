import React from "react";
import {
  Container,
  Dropdown, Image, Nav, Navbar, NavDropdown
} from "react-bootstrap";
import * as MD from "react-icons/md";
import { useSelector } from "react-redux";
import UnaspLogo from "../../assets/img/unasp-wallet.svg";
import history from "../../services/history";
import "./styles.css";


function HeaderClient() {
  const profile = useSelector((state) => state.user.profile);
  const profileFirstName = profile?.nome?.split(" ", 1);
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        style={{ backgroundColor: "#003366" }}
      >
        <Container>
          <Navbar.Brand>
            {" "}
            <Image
              src={UnaspLogo}
              width={200}
              className="mb-1"
              alt="unasp logo"
            />
          </Navbar.Brand>

          <Navbar className="justify-content-end">
            <NavDropdown
              id="navbarScrollingDropdown"
              className="drop"
              eventKey={1}
              title={
                <MD.MdAccountCircle
                  size={30}
                  style={{ color: "var(--shape)" }}
                />
              }
            >
              <Dropdown.Item
                eventKey={1.1}
                onClick={() =>
                  history.push({
                    pathname: "/customerArea",
                    state: "MeusDados",
                  })
                }
              >
                {/* {history.push({ pathname: '/customerArea', state: "MeusDados" })} */}
                Alterar Senha
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={1.3}
                onClick={() => history.push("/logout")}
              >
                Sair
              </Dropdown.Item>
            </NavDropdown>

            <Nav>
              <Navbar.Text style={{ color: "white" }}>
                {profileFirstName}
              </Navbar.Text>
            </Nav>
          </Navbar>
        </Container>
      </Navbar>
    </>
  );
}

export default HeaderClient;
