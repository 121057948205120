import createSagaMiddleware from "redux-saga";
import {applyMiddleware, createStore} from "redux";
import { persistStore } from "redux-persist";

import rootReducer from "./modules/rootReducer";
import rootSaga from "./modules/rootSaga";
import persistReducers from "./persistReducers";

const sagaMiddleware = createSagaMiddleware();

const store = createStore(persistReducers(rootReducer), applyMiddleware(sagaMiddleware));
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export {store, persistor};