import storage from "redux-persist/lib/storage";

import { persistReducer } from "redux-persist";

// eslint-disable-next-line 
export default reducers => {
    const persistedReducer = persistReducer(
        {
            key: "enrollmentprocess",
            storage,
            whitelist: ["auth", "user", "expiration"]
        }, reducers
    );
    return persistedReducer;
}
