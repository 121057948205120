import { React, useRef } from "react";
import {
  Container, Image, Navbar
} from "react-bootstrap";
import * as MD from "react-icons/md";
import { useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import AcontrolLogo from "../../assets/img/acontrol-logo.svg";
import history from "../../services/history";
import { useDetectOutsideClick } from "../useDetectOutsideClick";
import "./styles.css";

function HeaderAdmin() {
  const profile = useSelector((state) => state.user.profile);
  const profileFirstName = profile.nome.split(" ", 1);
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = /*  useState(false); */ useDetectOutsideClick(
    dropdownRef,
    false
  );
  // const onClick = () => setIsActive(!isActive);

  return (
    <>
      <Navbar fixed="top" style={{ backgroundColor: "#003366" }}>
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              {" "}
              <Image
                src={AcontrolLogo}
                width={190}
                className="mb-1"
                alt="unasp logo"
              />
            </Navbar.Brand>
          </LinkContainer>

          <div className="dropdown">
            <button className="dropbtn">
              <span>{profileFirstName}</span>
              <MD.MdAccountCircle size={30} style={{ color: "#003366" }} />
            </button>
            <div className="dropdown-content">
              <a
                onClick={() => {
                  history.push({
                    pathname: "/adminArea/usermanagement",

                  });
                }}
              >
                Gerenciar Usuários
              </a>
              {/* <a
                onClick={() => {
                  history.push({
                    pathname: "/PolicyKey",

                  });
                }}
              >
                Chave de Politica
              </a> */}
              {/* <a
                onClick={() => {
                  history.push({
                    pathname: "/customerArea",
                    state: "MeusDados",
                  });
                }}
              >
                Alterar senha
              </a> */}
              <a
                onClick={() => {
                  history.push("/Wallet");
                }}
              >
                Wallet
              </a>
              <a
                onClick={() => {
                  history.push("/logout");
                  setIsActive(!isActive)
                }}
              >
                Sair
              </a>
            </div>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default HeaderAdmin;
