import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom'
import { Row, Card, Form, Button, InputGroup, Container, Col, Modal } from 'react-bootstrap'
// import { toast } from 'react-toastify';
import UnaspLogo from '../../assets/img/unasp_logo.svg';
import * as MD from 'react-icons/md';
import * as RI from 'react-icons/ri';
import { signInRequest } from "../../store/modules/auth/actions";
import api from "../../services/api"
import "./styles.css"

function SignIn(props) {
    const [email, setEmail] = useState("");
    const [modalSendNewPassword, setModalSendNewPassword] = useState();

    let returnRoute = null;
    try {
        returnRoute = props.location.state.returnRoute;
    } catch (error) {
        returnRoute = null;
    }
    const dispatch = useDispatch();
    const loading = useSelector(state => state.auth.loading);

    const [modalShow, setModalShow] = useState(false);

    const { register, handleSubmit } = useForm(); //watch, errors

    function OnSubmitLogin({ email, password }) {
        dispatch(signInRequest(email, password, returnRoute));
    }

    async function SendNewPassword({ emailNewPassword }) {
        setEmail(emailNewPassword);
        try {
            const response = await api.post(`/Authentication/PasswordRecover/${emailNewPassword}`);
            const data = response.data;
            const { status } = data;
            if (response.status === 200 && status) {
                setModalSendNewPassword(true);
            } else {
            }
        } catch (error) {
            if ((!!error.response && error.response.status !== 401)) {
                toast.error("Erro na solicitação.. tente mais tarde");

            }
        }
    }

    return (
        <>
            <Container >
                <div className="center">
                    <div className="d-flex align-items-center justify-content-center ">
                        <Row >
                            <Col >

                                <Card.Body className="cardLogin text-center" style={{ background: ' var(--bg-primary)' }}>
                                    <div className="unaspLogo">
                                        <img className="mb-4" src={UnaspLogo} width="150" alt="unasp logo" />
                                    </div>

                                    <Form onSubmit={handleSubmit(OnSubmitLogin)} >
                                        <InputGroup className="mb-4">
                                            <InputGroup.Text className="basic-addon1"><MD.MdMailOutline size={20} style={{ color: '#FFFFFF' }} /></InputGroup.Text>
                                            <Form.Control
                                                required
                                                type="text"
                                                className="input"
                                                name="email"
                                                placeholder="Email"
                                                aria-label="Email"
                                                {...register('email')}
                                            // isInvalid={!!errors.email} 
                                            />
                                        </InputGroup>

                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1"><RI.RiLockPasswordLine size={20} style={{ color: 'var(--shape' }} /></InputGroup.Text>
                                            <Form.Control
                                                required
                                                type="password"
                                                className="input"
                                                name="password"
                                                placeholder="Senha"
                                                aria-label="Password"
                                                aria-describedby="basic-addon1"
                                                {...register('password')}
                                            /* isInvalid={!!errors.password} */
                                            />

                                            {/*  <Form.Control.Feedback type='invalid'>
                                                {errors.password}
                                            </Form.Control.Feedback>
 */}
                                        </InputGroup>

                                        <Button className="btn-submit shadow-none" type="submit" block>{loading ? "Carregando..." : "Entrar"}</Button>
                                    </Form>
                                    <Row className="mt-3">
                                        <Link onClick={() => setModalShow(true)} className="forgetPassword" >Esqueceu a senha?</Link>
                                    </Row>

                                </Card.Body>


                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
            <Modal centered size="md" show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header className="pl-4">
                    <Modal.Title className="mt-2">
                        <h4><strong>Esqueceu a senha?</strong></h4>
                    </Modal.Title>
                    <div className="close">
                        <MD.MdClose size={30} onClick={() => { setModalSendNewPassword(false); setModalShow(false) }} />
                    </div>
                </Modal.Header>
                <Modal.Body className="pl-4 pr-4 pb-4 pt-3">
                    <form className="px-4" onSubmit={handleSubmit(SendNewPassword)}>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <p>Não tem problema! Acesse o portal/TOTVS ou entre em contato com o RH para trocar sua senha.</p>
                                    </Col>
                                </Row>
                                {/* <Row className="pt-2">
                                    <Col>
                                        <label htmlFor="emailNewPassword">E-mail de cadastro</label>


                                        <input style={{ color: 'black' }} className="form-control" type="email" placeholder="Digite seu Email" id="emailNewPassword" name="emailNewPassword"  {...register('emailNewPassword')} />
                                    </Col>
                                </Row> */}
                                <Row className="pt-3">
                                    <Col>
                                        <Button className="btn-submit" onClick={() => { setModalShow(false) }} block >{loading ? "Carregando..." : "Fechar"}</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
            <Modal
                centered
                className="mt-5 pt-3"
                show={modalSendNewPassword}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header className="justify-content-center">
                    <Modal.Title className="text-center" id="example-modal-sizes-title-lg">
                        {`Pedido recebido. Caso exista um usuário com o email ${email}, será enviado um link para recuperar a senha`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="justify-content-center">
                        <Col className="col-3 text-center">
                            <Button className="btn-submit" type="button" variant="primary" block onClick={() => { setModalSendNewPassword(false); setModalShow(false) }}>OK</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

        </>
    );
}

export default SignIn;