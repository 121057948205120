import React, { Fragment, useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import CAProfile from "../../components/CustomerArea/CAProfile";
import "./style.css";

function CustomerArea(props) {
  const [customerOption, setCustomerOption] = useState(""); //alterna os elementos

  useEffect(() => {
    setCustomerOption(props.location.state);
  }, [props.location.state]);

  return (
    <Fragment>
      <Container className="_PageForm mt-sm-5">
        <Row>{customerOption === "MeusDados" ? <CAProfile /> : null}</Row>
      </Container>
    </Fragment>
  );
}

export default CustomerArea;
