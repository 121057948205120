import React, { createContext, useState } from "react";

export const PolicyGroupDataContext = createContext();

const PolicyGroupDataProvider = ({ children }) => {
    const [dataPolicyGroup, setDataPolicyGroup] = useState({})
    const [editedPolicyGroup, setEditedPolicyGroup] = useState({
        Name: "",
        EntryGroupPolicies: []
    });
    const [dataToEditPolicyGroup, setDataToEditPolicyGroup] = useState({});
    const [currentPage, setCurrentPage] = useState(
        {
            currentPage: "",
            lastData: {}
        });

    const setData = (data) => {
        setDataPolicyGroup(data);
    }
    const setNewData = (newData) => {
        setEditedPolicyGroup(newData)
    }
    const setPolicyGroupEditData = (data) => {
        setDataToEditPolicyGroup(data)
    }
    const setCurrentPageData = (data) => {
        setCurrentPage(data)
    }

    return (
        <PolicyGroupDataContext.Provider value={{ dataPolicyGroup, editedPolicyGroup, dataToEditPolicyGroup, currentPage, setData, setNewData, setPolicyGroupEditData, setCurrentPageData }}>
            {children}
        </PolicyGroupDataContext.Provider>
    )
};

export default PolicyGroupDataProvider;
