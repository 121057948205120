import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Container, Card, Navbar, Nav } from "react-bootstrap";
import Sidebar from "../SideBar";
import * as MD from "react-icons/md";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import Identification from "./Identification";
import Schedules from "./Schedules";
import RegisteredPolicies from "./RegisteredPolicies";

import "./styles.css";
import { toast } from "react-toastify";
import { PolicyGroupDataContext } from "../../pages/PolicyGroup/Context";

function AddGroup() {
  const context = useContext(PolicyGroupDataContext)

  const Back = useHistory().goBack; // React-router-dom goBack Page Function
  const tenant = useSelector((state) => state.user.profile.tenants[0]);
  const [policyOptions, setPolicyOptions] = useState("I");
  const [identification, setIdentification] = useState({});
  const [schedules, setSchedules] = useState([]);
  const [editPolicyGroup, setEditPolicyGroup] = useState({
    data: schedules,
    status: false,
    schedules: []
  })

  useEffect(() => {
    if (context.currentPage.currentPage === "A") {
      setIdentification(context.currentPage.lastData)

      setPolicyOptions("A")
    }
  }, []);

  function Ident(name, description) {
    if (name.length !== 0) {
      let data = {
        name: name,
        description: description,
        dataKey: tenant
      }
      context.setCurrentPageData({
        currentPage: "A",
        lastData: data
      })
      setIdentification(data)
      setPolicyOptions("A")

      EditPolicyGroup(editPolicyGroup, false, editPolicyGroup)
    } else { }
  }

  function Sched(entry) {
    let data =
      entry
    setSchedules(data)
    setPolicyOptions("P")
    context.setCurrentPageData("P")


  }

  function EditPolicyGroup(groupPolicyData, status, sched) {
    let data = {
      data: groupPolicyData,
      status: status,
      schedules: sched

    }
    setEditPolicyGroup(data)
    setPolicyOptions("A")

  }

  function AddPoliciesGroup(status) {
    if (status) {
      EditPolicyGroup(editPolicyGroup.data, false, editPolicyGroup.schedules)
      setPolicyOptions("A")

    }
  }

  function RemovePolicy(data) {
    let arr = schedules.filter((y) => y !== data);
    setSchedules(arr)
  }

  return (
    <>
      <Container className="p-4" fluid="xxl">
        <Row>
          <Col className="col-auto">
            <Sidebar />
          </Col>
          <Col>
            <Row className="d-flex align-items-center mt-5 mb-3">
              <Col className="col-auto">
                <h4 className="d-flex align-items-center justify-content-center ">
                  <MD.MdArrowBack
                    style={{ cursor: "pointer", color: "#000", backgroundColor: "#cccccc", borderRadius: "50%", padding: "5px" }}
                    size="35"
                    className="me-4"
                    onClick={() => Back()}
                  />
                  Adicionar Grupo
                </h4>
              </Col>
              <Col>
                <Navbar>
                  <Row>
                    <Col>
                      <Nav>
                        <Card className="px-3 py-0">
                          <Row align="center" className="d-flex align-items-end">
                            <Col xs={12} md>
                              <Nav.Link
                                onClick={() => setPolicyOptions("I")}
                                className={
                                  policyOptions === "I"
                                    ? "border-navbar pb-2"
                                    : "navbar-link pb-2"
                                }
                              >
                                <small>IDENTIFICAÇÃO</small>
                              </Nav.Link>
                            </Col>

                            <Col xs={12} md>
                              <Nav.Link
                                onClick={() => { Object.keys(identification).length === 0 ? toast.warning("Preencha o Nome e clique em Proxímo!") : editPolicyGroup.status ? EditPolicyGroup(editPolicyGroup.data, false, editPolicyGroup.schedules) : setPolicyOptions("A"); }}
                                className={
                                  policyOptions === "A"
                                    ? "border-navbar"
                                    : "navbar-link"
                                }
                              >
                                <small>POLÍTICAS E AGENDAMENTOS</small>
                              </Nav.Link>
                            </Col>

                            <Col>
                              <Nav.Link
                                onClick={() => schedules.length === 0 ? toast.warning("Crie pelo menos uma política para continuar") : (setPolicyOptions("P"), context.setCurrentPageData("P"))}
                                className={
                                  policyOptions === "P"
                                    ? "border-navbar"
                                    : "navbar-link"
                                }
                              >
                                <small>POLÍTICAS CADASTRADAS</small>
                              </Nav.Link>
                            </Col>
                          </Row>
                        </Card>
                      </Nav>
                    </Col>
                  </Row>
                </Navbar>

              </Col>
              <Container fluid="sm">
                <Row>
                  {policyOptions === "I" ? (
                    <Col md={8}>
                      <Identification ident={Ident} />
                    </Col>
                  ) : policyOptions === "A" ? (
                    <Schedules ident={identification} sched={Sched} edit={editPolicyGroup} schedules={schedules} />
                  ) : policyOptions === "P" ? (
                    <RegisteredPolicies edit={EditPolicyGroup} addPoliciesGroup={AddPoliciesGroup} removePolicy={RemovePolicy} ident={identification} sched={[schedules]} />
                  ) : (
                    <Identification />
                  )}
                </Row>
              </Container>
            </Row>
          </Col>
        </Row>
      </Container >
    </>
  );
}

export default AddGroup;
