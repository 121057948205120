import React from 'react';
import { Navbar, Container, Image, Row, Col } from 'react-bootstrap';
import DevTeamLogo from "../../assets/img/footer-dev-team.svg"
import "./styles.css"

function Footer() {
    return (
        <>
            <Container>
                <Row className="pb-4 pt-0">
                    <Col className='ps-5' md={{ offset: 3 }}>
                        <Image
                            src={DevTeamLogo}
                            className="footer mb-1"
                            alt="unasp logo"
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Footer;