import React from 'react';
import { Router } from 'react-router-dom';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";

import GlobalStyle from './styles/global';
import Routes from './routes/Routes';
import history from './services/history';
import { store, persistor } from "./store";
import PolicyGroupDataProvider from './pages/PolicyGroup/Context';

function App() {
  return (
    <>
      <Provider store={store}>
        <PolicyGroupDataProvider>
          <PersistGate persistor={persistor}>
            <GlobalStyle />
            <ToastContainer autoClose={5000} />
            <Router history={history}>
              <Routes />
            </Router>
          </PersistGate>
        </PolicyGroupDataProvider>
      </Provider>
    </>
  );
}

export default App;
