import { all, takeLatest, call, put } from "redux-saga/effects";
//import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

import history from "../../../services/history";
import api from "../../../services/api";
import { signInSuccess, signFailure } from "./actions";

export function* signIn({ payload }) {
    try {
        const { email, password, returnRoute } = payload;
        const userLogin = { Username: email, Password: password };
        const response = yield call(api.post, "/Authentication", userLogin);

        const { authenticated, token, user, expiration } = response.data;

        if (!authenticated) {
            toast.error("Falha na autenticação, verifique seus dados");
            yield put(signFailure());
            return;
        }
        api.defaults.headers.Authorization = `Bearer ${token}`;
        yield put(signInSuccess(token, user, expiration));

        history.push(
            {
                pathname: `/Wallet`,

            }
        );
        //window.location.reload();

    } catch (error) {
        toast.error("Falha na comunicação com o servidor");
        yield put(signFailure());
    }
}

export function setToken({ payload }) {
    if (!payload) {
        return;
    }
    const { token } = payload.auth;
    if (token) {
        api.defaults.headers.Authorization = `Bearer ${token}`;
    }
}

export function signOut({ href }) {
    if (href === undefined) {
        history.push("/");
    } else {
        history.push(
            {
                pathname: `/`,
                state: { returnRoute: href }
            }
        );
    }
}

export default all([
    takeLatest("persist/REHYDRATE", setToken),
    takeLatest("@auth/SIGN_IN_REQUEST", signIn),
    takeLatest("@auth/SIGN_OUT", signOut)
]);