import React from "react";
import PropTypes from "prop-types";

/* import HeaderDefault from '../../../components/HeaderDefault';
import Footer from "../../../components/Footer"; */

function DefaultLayout({ children }) {
    return (
        <>
            {children}
        </>
    );
}

export default DefaultLayout;

DefaultLayout.prototype = {
    children: PropTypes.element.isRequired
}