import { React, useContext, useState } from "react";
import { Button, Card, Col, Form, Image, InputGroup, Row } from "react-bootstrap";
import * as Bi from "react-icons/bi";
import PolicyGroupImg from "../../../../assets/img/policy-group.svg";
import { PolicyGroupDataContext } from "../../Context";

function Identification(props) {
  const context = useContext(PolicyGroupDataContext)
  const [groupName, setGroupName] = useState(context.dataPolicyGroup.name);
  const [groupDescription, setGroupDescription] = useState(context.dataPolicyGroup.description);

  function SendInfo(e) {
    e.preventDefault()
    context.setNewData({
      EntryGroupId: context.dataPolicyGroup.entryGroupId,
      Name: groupName,
      Description: groupDescription,
      DataKey: context.dataPolicyGroup.dataKey,
      EntryGroupPolicies: []
    })
    props.ident()
  }

  return (

    <Form onSubmit={() => SendInfo()} >
      <Card>
        <Card.Body className="my-0">
          <Card.Text>IDENTIFICAÇÃO DO GRUPO</Card.Text>
          <Row className="p-0">
            <Col >
              <Image
                fluid
                src={PolicyGroupImg}
                style={{ width: 120 }}
              />
            </Col>

            <Col xs={9}>

              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <Bi.BiShieldQuarter size={20} />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Nome do grupo"
                  defaultValue={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                  required
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <Bi.BiShieldQuarter size={20} />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Descrição"
                  defaultValue={groupDescription}
                  onChange={(e) => setGroupDescription(e.target.value)}
                />
              </InputGroup>

            </Col>

          </Row>


          <Row>
            <Col align="end">
              <Button className=" BtnPrimary shadow-none" onClick={() => {
                context.setNewData({
                  EntryGroupId: context.dataPolicyGroup.entryGroupId,
                  Name: groupName,
                  Description: groupDescription,
                  DataKey: context.dataPolicyGroup.dataKey,
                  EntryGroupPolicies: context.dataPolicyGroup.entryGroupPolicies
                }); props.ident("a")
              }} >
                Próximo
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Form >
  );
}

export default Identification;
