import React from "react";
import Summary from "../../components/Summary";

function Wallet() {
  return (
    <>
      <Summary />
    </>
  );
}

export default Wallet;
