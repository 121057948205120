import axios from "axios";
import history from "../services/history";
import { toast } from "react-toastify";

const domain = window.location.hostname;

const api = axios.create({
    baseURL:
        process.env.NODE_ENV === "development"
            ? "https://localhost:7002"
            //? "https://api.acontrol.unasp.edu.br"
            : domain === "homolog.acontrol.unasp.edu.br"
                ? "https://homolog.api.acontrol.unasp.edu.br"
                : "https://api.acontrol.unasp.edu.br"
});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status !== 401) {
            return Promise.reject(error);
        }
        toast.error("Sua sessão expirou. Faça o login novamente");
        history.push("/logout");
    }
);

export default api