import axios from 'axios';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Container, Dropdown, DropdownButton, Form, FormControl, Modal, Row, Spinner, Stack, Table } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import * as MD from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';
import './style.css';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import Transactions from '../../../components/Transactions';
import Summary from '../../../components/Summary';


function CustomerArea(props) {
  //const tenant = useSelector((state) => state.user.profile.tenants[0]);
  const { register, handleSubmit, formState: { errors } } = useForm();

  const { register: registerCodCard, handleSubmit: handleSubmitCodCard, reset, formState: { errors: errorsCodCard } } = useForm();

  const { register: registerCreateUser, handleSubmit: handleSubmitCreateUser, reset: resetUser, formState: { errors: errorsCreateUser } } = useForm();
  const [customerOption, setCustomerOption] = useState(""); //alterna os elementos
  const [dataToEditUser, setDataToEditUser] = useState({});
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  const [modalEditUser, setModalEditUser] = useState(false);
  const [modalDeleteUser, setModalDeleteUser] = useState(false);
  const [modalAccess, setModalAccess] = useState(false);
  const [modalCodCard, setModalCodCard] = useState(false);
  const [modalCreateUser, setModalCreateUser] = useState(false);
  const [modalTransaction, setModalTransaction] = useState(false);
  const [modalTransactions, setModalTransactions] = useState(false);
  const [userEmail, setUserEmail] = useState();
  const [modalDebit, setModalDebit] = useState(false);

  const [editField, setEditField] = useState({
    email: "",
    field: "",
    edit: false,

  });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [searchUser, setSearchUser] = useState("");
  const [searchUserTransaction, setSearchUserTransaction] = useState("");
  const [userPolicies, setUserPolicies] = useState([]);
  const [availablePolicies, setAvailablePolicies] = useState([]);

  const [userTenant, setUserTenant] = useState({});
  const [availableTenants, setAvailableTenants] = useState([]);
  const [userData, setUserData] = useState([]);
  const [userResult, setUserResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAllentryGroup, setLoadingAllentryGroup] = useState(true);
  const [loadingPolicies, setLoadingPolicies] = useState(true);
  const [allEntryGroup, setAllEntryGroup] = useState([]);
  const [user, setUser] = useState();

  const [policyValue, setPolicyValue] = useState({})
  const [selectedEntryGroupId, setSelectedEntryGroupId] = useState(27);
  const [selectedUserId, setSelectedUserId] = useState("");

  const [startDate, setStartDate] = useState(new Date());

  const [userBalance, setUserBalance] = useState();
  useEffect(() => {
    setCustomerOption(props.location.state);
    GetAllEntryGroup()
  }, [props.location.state]);


  const searchItems = (searchValue) => {
    setSearchInput(searchValue)
    if (searchInput !== '') {
      const filteredData = availablePolicies.filter((item) => {
        return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
      })
      setFilteredResults(filteredData)
    }
    else {
      setFilteredResults(availablePolicies)
    }
  }

  const cancelTokenSource = useRef();

  async function GetUserBalance(userEmail) {
    try {
      const response = await api.get(`/AdminArea/TransactionSummary/${userEmail}`);
      const { status } = response;

      if (response.status === 200 && status) {
        setUserBalance(response.data.balance)
      }
      return "erro na consulta"
    } catch (error) {
      return "Erro na consulta"
    }

  }


  const GetAllEntryGroup = async () => {
    setLoadingAllentryGroup(true)
    try {
      const response = await api.get(`/AdminArea/GetAllEntryGroup`);

      const { status, data } = response;

      if (response.status === 200 && status) {
        /*  const acEntry = data;
        const pluginData = JSON.parse(acEntry.pluginData);
        setAllAcEntry(acEntry); */
        setAllEntryGroup(data.sort((a, b) => a.name.localeCompare(b.name)));
        setLoadingAllentryGroup(false)
        // setLoading(true);
      } else {
        toast.error(`Erro na solicitação!`);
      }
    } catch (error) {
      if (!!error.response && error.response.status !== 401) {
        toast.error("Erro na solicitação.. tente mais tarde");
      }
    }
  }

  async function GetUserListByNameEmail(emailName) {
    if (emailName.length < 3) {
      setUserData([])
      setUserResult([])
      return
    }
    setLoading(true)
    setUserData([])

    try {
      cancelTokenSource.current = axios.CancelToken.source();
      const response = await api.get(`AdminArea/UserListByNameEmail/${selectedEntryGroupId}/${emailName}`,
        {
          cancelToken: cancelTokenSource.current.token
        }
      )

      setUserResult(response.data)
      setLoading(false)

    } catch (err) {
      console.log(err);
    }

  }

  async function GetUserPolicyValue(userId, entryGroupId, dateTime) {
    setSelectedUserId(userId)
    if (entryGroupId != "") {

      try {
        const response = await api.get(`AdminArea/GetUserPolicyValue/${userId}/${entryGroupId}/${dateTime}`)
        if (response.data == "") {
          toast.warning("Nada encontrado, verifique a data e horario!")
          return
        }
        setPolicyValue(response.data)
        setModalDebit(true)
      } catch (e) {

      }
    } else {
      toast.warning("Selecione um grupo de política!")
    }
  }

  async function PostTransaction() {
    const data = {
      "UserId": selectedUserId,
      "EntryGroupId": selectedEntryGroupId,
      "DateTime": format(new Date(startDate), "yyyy-MM-dd HH:mm:ss")
    }
    try {
      const response = await api.post(`AdminArea/PostTransaction`, data)
      if (response.data.status) {
        toast.success("Valor debitado com sucesso!")
        setModalDebit(false)
      } else {
        toast.error("Valor não debitado!")
        setModalDebit(false)
      }
    } catch (e) {

    }
  }

  async function UsersTransaction(emailName) {



    try {
      cancelTokenSource.current = axios.CancelToken.source();
      const response = await api.get(`AdminArea/UserListByNameEmail/${selectedEntryGroupId}/${emailName}`,
        {
          cancelToken: cancelTokenSource.current.token
        }
      )
      setUserResult(response.data)
    } catch (err) {

    }


  }



  const cancel = () => {

    if (!!cancelTokenSource.current) {

      cancelTokenSource.current.cancel();
    }
  };

  async function ChangePassword(data) {

    const obj = {
      UserEmail: dataToEditUser.email,
      password: data.password
    }
    try {
      const response = await api.post(`AdminArea/ChangePassword`, obj)
      if (response.data.status) {

        toast.success("Senha alterada com sucesso!")
        GetUserListByNameEmail()

      } else {
        toast.error("Senha não alterada!")
      }

      GetUserListByNameEmail()
    } catch (err) {
      console.log(err);
    }
    setModalEditUser(false)

  }
  async function GetUserAvailablePolicyKey(userId) {
    setLoadingPolicies(true)
    try {
      const response = await api.get(`AdminArea/GetUserAvailablePolicyKey/${userId}`)

      setAvailablePolicies(response.data)
    } catch (err) {
      console.log(err);
    }
    setLoadingPolicies(false)


  }

  async function GetUserPolicies(userId) {


    try {
      const response = await api.get(`AdminArea/GetUserPolicies/${userId}`)

      setUserPolicies(response.data)
      GetUserAvailablePolicyKey(userId)
      setModalAccess(true)
    } catch (err) {
      console.log(err);
    }


  }

  async function PostUserPolicyKey(policyKey, dataKey) {
    try {
      const obj = {
        userId: dataToEditUser.id,
        policyKey: policyKey,
        dataKey: dataKey
      }
      const response = await api.post(`AdminArea/PostUserPolicyKey/`, obj)

      GetUserPolicies(dataToEditUser.id)

    } catch (err) {
      console.log(err);
    }

  }

  async function DeleteUserPolicyKey(userPolicyKey) {


    try {
      const response = await api.delete(`AdminArea/DeleteUserPolicyKey/${userPolicyKey}`)
      GetUserPolicies(dataToEditUser.id)

    } catch (err) {
      console.log(err);
    }


  }

  async function PutCardCodUser(cardCod) {


    try {
      const obj = {
        UserId: dataToEditUser.id,
        CardCod: cardCod.codCard.length > 0 ? cardCod.codCard : 0
      }

      const response = await api.put(`AdminArea/PutCardCodUser`, obj);
      if (response.data.status) {
        setModalCodCard(false)

        toast.success("Código cadastrado com sucesso!")
      }

    } catch (err) {
      console.log(err);
    }


  }

  // async function PostCreateUser(data) {


  //   try {
  //     const obj = {
  //       Name: data.name,
  //       Email: data.email,
  //       Document: data.document,
  //       BirthDate: data.birthDate,
  //       DataKey: tenant
  //     }

  //     const response = await api.post(`AdminArea/PostCreateUser`, obj);
  //     if (response.data.status) {
  //       setModalCreateUser(false)

  //       toast.success("Usuário criado com sucesso!")
  //       resetUser("name")
  //       resetUser("email")
  //       resetUser("document")
  //       resetUser("birthDate")

  //     } else {
  //       toast.error(response.data.message);
  //     }

  //   } catch (err) {
  //     toast.error(err)

  //   }


  // }

  const { format: formatPrice } = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });


  return (
    <Fragment>
      <Container className="_PageForm mt-sm-5" >
        <Row className="justify-content-md-center _PageForm pe-4 ps-4 pb-4 pt-2">

          <Row className="mt-5 mt-md-3">
            <Col>
              <Card id="table" className="mt-4"   >
                <Card.Body>

                  <Col>
                    <h3 className="pl-2 mb-0">
                      <strong>Débito Manual</strong>
                    </h3>
                  </Col>

                  <Stack direction="horizontal">

                    <Col>
                      <Form.Text>
                        Selecione um grupo de política
                      </Form.Text>
                      {allEntryGroup.length > 0 &&

                        <Form.Select onChange={(e) => setSelectedEntryGroupId(e.target.value)} required>
                          {allEntryGroup.length > 0 && allEntryGroup.map((x) =>
                            <option value={x.entryGroupId} selected={x.entryGroupId == selectedEntryGroupId} className="p-0 mx-1">{x.name}</option>

                          )}
                        </Form.Select>

                      }
                    </Col>

                    <Col className="col-auto p-4">
                      <Form.Text>
                        Escolha uma data
                      </Form.Text>
                      <DatePicker

                        selected={new Date(startDate)}
                        onChange={(date) => setStartDate(format(new Date(date), "yyyy-MM-dd HH:mm"))}
                        locale={ptBR}
                        timeInputLabel="Time:"
                        dateFormat="dd/MM/yy - HH:mm"
                        showTimeInput
                      />
                    </Col>

                  </Stack>

                  <div class="input-group rounded p-2 pt-4">
                    <span class="input-group-text border-0" id="search-addon">
                      <MD.MdSearch size={20} />

                    </span>
                    <input onChange={(e) => { cancel(); GetUserListByNameEmail(e.target.value) }} type="search" class="form-control rounded" placeholder="Pesquisar" aria-label="Search" aria-describedby="search-addon" />
                  </div>

                  {

                    loading ?
                      <Col className="d-flex justify-content-md-center mt-5">
                        <Spinner animation="border" />
                      </Col> :
                      !loading && userResult.length > 0 ?

                        <Table striped bordered hover size="sm">
                          <tbody>
                            {

                              userResult.map((x) =>
                                <tr>

                                  <td>

                                    <Col className="p-0 ms-5"><strong>{x.userName}</strong> </Col>

                                  </td>

                                  <td >
                                    <div className='d-grid'>

                                      <Button className="shadow-none BtnPrimary" onClick={() => { GetUserPolicyValue(x.id, selectedEntryGroupId, format(new Date(startDate), "yyyy-MM-dd HH:mm:ss")); GetUserBalance(x.email) }} size="sm"><MD.MdMoneyOff />Debitar</Button>

                                    </div>

                                  </td>
                                  <td width="10%" >
                                    <div className='d-grid'>

                                      <Button className="shadow-none BtnPrimary" onClick={() => { setDataToEditUser(x); setModalTransactions(true) }} size="sm"><MD.MdArticle />Extrato</Button>

                                    </div>

                                  </td>
                                </tr>
                              )
                            }
                          </tbody>


                        </Table>
                        :
                        <Row className="pt-2 mb-0" >
                          <Col className="text-center">
                            <h6><strong>Nada encontrado!</strong></h6>
                          </Col>
                        </Row>
                  }
                </Card.Body>
              </Card>



            </Col>
          </Row>

        </Row>
      </Container>

      {/* Modal CreateUser*/}



      {/* Modal EditUser*/}

      <Modal className='special_modal' show={modalEditUser} onHide={() => setModalEditUser(false)} centered size="md">
        <Form onSubmit={handleSubmit(ChangePassword)}>
          <Modal.Header >
            <Modal.Title><MD.MdEdit size={30} /> <span className="align-middle">Trocar Senha</span></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Digite uma nova senha</Form.Label>
              <Form.Control  {...register("password", { required: true, minLength: 6 })} type="text" placeholder="Senha" />
              <span>
                {errors.password && <Form.Text id="passwordHelpBlock" muted>
                  As senhas devem ter pelo menos 6 caracteres.
                </Form.Text>}
              </span>
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>

            <Button className="shadow-none BtnPrimary" type="submit">Salvar</Button>{' '}
          </Modal.Footer>
        </Form>

      </Modal>

      {/* Modal Crachá*/}
      <Modal className='special_modal' show={modalCodCard} onHide={() => setModalCodCard(false)} centered size="md">
        <Form onSubmit={handleSubmitCodCard(PutCardCodUser)}>
          <Modal.Header >
            <Modal.Title><MD.MdEdit size={30} /> <span className="align-middle">Código crachá</span></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Col} controlId="formGridCardCod">
              <Form.Label>Digite o código do crachá</Form.Label>
              <Form.Control defaultValue={dataToEditUser?.idNavigation?.cardCod} {...registerCodCard("codCard")} type="number" placeholder="Código" />
              <span>
                {errorsCodCard.codCard && <Form.Text id="CardCod" muted>
                  Digite um número.
                </Form.Text>}
              </span>
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>

            <Button className="shadow-none BtnPrimary" type="submit">Salvar</Button>{' '}
          </Modal.Footer>
        </Form>

      </Modal>

      {/* Modal Policy*/}








      <Modal className='special_modal' show={modalTransaction} onHide={() => { setModalTransaction(false); setUserResult([]) }} centered size="md">
        <Modal.Header >
          <Modal.Title><MD.MdEdit size={30} /> <span className="align-middle">Débito Manual</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Text>
            Selecione um grupo de política
          </Form.Text>
          <Form.Select onChange={(e) => setSelectedEntryGroupId(e.target.value)} required>
            <option value=""></option>
            {allEntryGroup.map((x) =>
              <option value={x.entryGroupId} className="p-0 mx-1">{x.name}</option>

            )}
          </Form.Select>
          <Row className="justify-content-center pt-4">
            <Form.Text>
              Escolha uma data
            </Form.Text>
            <Col className="col-auto p-4">
              <DatePicker
                inline
                selected={new Date(startDate)}
                onChange={(date) => setStartDate(format(new Date(date), "yyyy-MM-dd HH:mm"))}
                locale={ptBR}
                timeInputLabel="Time:"
                dateFormat="dd/MM/yy - HH:mm"
                showTimeInput
              />
            </Col>

          </Row>
          <div class="input-group rounded p-2 pt-4">
            <span class="input-group-text border-0" id="search-addon">
              <MD.MdSearch size={20} />

            </span>
            <input onChange={(e) => { cancel(); setSearchUserTransaction(e.target.value) }} type="search" class="form-control rounded" placeholder="Pesquisar" aria-label="Search" aria-describedby="search-addon" />
          </div>

          {searchUserTransaction.length >= 3 && userResult.length > 0 ?
            userResult.map((x) =>
              <Card className="mt-2 ">
                <Card.Body>

                  <Row>
                    <Col className="p-0 mx-1"><strong>{x.userName}</strong> </Col>

                    <Col className="text-end">
                      <Button className="shadow-none BtnPrimary" onClick={() => { GetUserPolicyValue(x.id, selectedEntryGroupId, format(new Date(startDate), "yyyy-MM-dd HH:mm:ss")); GetUserBalance(x.email) }} size="sm"><MD.MdMoneyOff />Debitar</Button>
                    </Col>
                  </Row>

                </Card.Body>
              </Card>
            )
            :
            <Row className="pt-2 mb-0" >
              <Col className="text-center">
                <h6><strong>Nada encontrado!</strong></h6>
              </Col>
            </Row>
          }
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName="border-radius-2"
        show={modalDebit}
        onHide={() => { setModalDebit(false) }}
        centered
        size="sm"
        // animation={false}
        style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      >
        <Modal.Body>
          <Row className="justify-content-center text-center pb-3">
            <h5 className="my-3 pb-4 border-bottom">Debitar Valor?</h5>
            <h6 className="my-3 pb-4 border-bottom"><strong>Política</strong>: {policyValue.requiredKey}</h6>
            <h6 className="my-3 pb-4 border-bottom"><strong>Descontar</strong> {formatPrice(policyValue.value)}</h6>
            <h6 className="my-3 pb-4 border-bottom"><strong>Saldo</strong> {formatPrice(userBalance)}</h6>

            <Button
              style={{ border: "none", backgroundColor: "var(--bg-red)" }}
              className="col-4 me-1" onClick={() => setModalDebit(false)}
            >Não</Button>
            <Button
              style={{ border: "none", backgroundColor: "var(--bg-green)" }}
              className="col-4 ms-1" onClick={() => PostTransaction()}
            >Sim</Button>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal fullscreen className='special_modal' show={modalTransactions} onHide={() => { setModalTransactions(false) }}>
        <Modal.Header closeButton>
          <Modal.Title><MD.MdList size={30} /> <span className="align-middle">Extrato</span></Modal.Title>

        </Modal.Header>
        <Modal.Body>
          <Summary userEmail={dataToEditUser.email} />
          {/*    <Transactions userEmail={dataToEditUser.email} /> */}
        </Modal.Body>
      </Modal>


    </Fragment>
  );
}

export default CustomerArea;

