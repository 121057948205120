import { React, useEffect, useState } from "react";
import {
  Button,
  Card, Col, Form,
  InputGroup,
  Modal, Row
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as Bi from "react-icons/bi";
import * as FA from "react-icons/fa";
import * as IO from "react-icons/io5";
import * as MD from "react-icons/md";
import { useSelector } from "react-redux";
import Switch from "react-switch";
import { toast } from "react-toastify";
import api from "../../../services/api";
import history from "../../../services/history";



function Identification(props) {
  const tenant = useSelector((state) => state.user.profile.tenants[0]);

  const [getAllAcEntryPolicyByAcEntry, setGetAllAcEntryPolicyByAcEntry] =
    useState([]);
  const [allPolicyKey, setAllPolicyKey] = useState([]);

  const acEntryId = props.id.match.params.acId;
  const acEntryPolicyId = props.id.match.params.id

  const [policyName, setPolicyName] = useState("");
  const [policyValue, setPolicyValue] = useState(null);

  const [policyRequiredKey, setPolicyRequiredKey] = useState("");
  const [policyLevel, setPolicyLevel] = useState(0);
  const [policyIsDeny, setPolicyIsDeny] = useState(false);

  const [isLoaded, setLoaded] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);

  const [policyKeyId, setPolicyKeyId] = useState("");
  const [isImported, setImported] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isDeleted, deleted] = useState(false);

  useEffect(() => {
    GetAllPolicyKey();
    GetAllAcEntryPolicyByAcEntry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyLevel, policyRequiredKey]);

  const { handleSubmit } = useForm();

  function FormatMoney(value, setX) {
    var v = value.replace(/\D/g, "");
    v = (v / 100).toFixed(2) + "";
    v = v.replace(".", ",");
    v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
    v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
    setX(`${v}`);
  }

  async function GetAllPolicyKey() {
    try {
      const response = await api.get(`/AdminArea/GetAllPolicyKey/${tenant}`);
      const data = response.data;
      setAllPolicyKey(data);
      data.map((x) => {
        if (policyRequiredKey === x.name) {
          if (!x.import) {
            setImported(false);
            setPolicyKeyId(x.policyKeyId);
          } else {
            setImported(true);
          }
        }
        return x
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function GetAllAcEntryPolicyByAcEntry() {
    if (!firstLoading) {
      try {
        const response = await api.get(
          `/AdminArea/GetAllAcEntryPolicyByAcEntry/${props.id.match.params.acId}`
        );
        const data = response.data;
        data.map((x) => {
          if (x.acEntryPolicyId === props.id.match.params.id) {
            setGetAllAcEntryPolicyByAcEntry(x);
            setPolicyValue(x.value)
            setPolicyName(x.name);
            setPolicyRequiredKey(x.requiredKey);
            setPolicyLevel(x.policyLevel);
            setPolicyIsDeny(x.isDeny);
          }
          return x
        });
        setFirstLoading(true);
      } catch (err) {
        console.log(err);
      }
    }
  }

  function OnSubmit() {
    props.t(
      acEntryPolicyId,
      acEntryId,
      policyName,
      policyValue,
      policyRequiredKey,
      policyLevel,
      policyIsDeny
    );
  }

  async function ConfirmDelAcEntryPolicy() {
    try {
      await api.delete(
        `/AdminArea/DeletePolicyKey/${policyKeyId}`
      );
      toast.success("Chave de política excluída com sucesso!");
      deleted(!isDeleted);
      setPolicyRequiredKey("");
      setImported(true);
      setLoaded(true);
    } catch (err) {
      console.log(err);
    }
    setShowModal(!showModal);
  }

  return (
    <>
      <Card>
        <Card.Body className="my-2">
          {!firstLoading ? (
            <h1>Carregando...</h1>
          ) : (
            <Form onSubmit={handleSubmit(OnSubmit)}>
              <Card.Title>Identificação</Card.Title>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <Bi.BiShieldQuarter size={20} />
                </InputGroup.Text>
                <Form.Control
                  disabled={!firstLoading}
                  defaultValue={getAllAcEntryPolicyByAcEntry.name}
                  autoFocus
                  required
                  onChange={(e) => setPolicyName(e.target.value)}
                  placeholder={
                    !firstLoading ? "Carregando..." : "Nome da política"
                  }
                  aria-label="Nome da política"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
              <Row>
                <Col className="my-2">
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <MD.MdOutlineAttachMoney size={20} />
                    </InputGroup.Text>
                    <Form.Control
                      disabled={!firstLoading}
                      defaultValue={policyValue == null ? 0 : policyValue}
                      value={policyValue}
                      placeholder="0,00"
                      onChange={(e) =>
                        FormatMoney(e.target.value, setPolicyValue)
                      }
                    />
                  </InputGroup>
                </Col>
                <Col className="my-2">
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <MD.MdOutlineSignalCellularAlt size={20} />
                    </InputGroup.Text>
                    <Form.Control
                      disabled={!firstLoading}
                      defaultValue={getAllAcEntryPolicyByAcEntry.policyLevel}
                      required
                      type="number"
                      placeholder="(Nível da política)"
                      onChange={(e) => setPolicyLevel(Number(e.target.value))}
                    />
                  </InputGroup>
                </Col>
                <Col className="my-2">
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <FA.FaKey size={18} />
                    </InputGroup.Text>
                    <Form.Group
                      disabled={!firstLoading}
                      required
                      as={Col}
                      controlId="formGridState"
                    >
                      <Form.Control
                        as="select"
                        disabled={!firstLoading}
                        required
                        onChange={(e) => setPolicyRequiredKey(e.target.value)}
                      >
                        <option value="" selected={isLoaded} disabled>
                          Chave de Política...
                        </option>
                        <option
                          defaultValue={
                            getAllAcEntryPolicyByAcEntry.requiredKey
                          }
                        >
                          {getAllAcEntryPolicyByAcEntry.requiredKey}
                        </option>
                        {allPolicyKey.map((x, y) => {
                          return (
                            <option key={y} value={x.name}>
                              {x.name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                    <InputGroup.Text hidden={isImported} id="basic-addon1">
                      <MD.MdDelete
                        title="Excluir chave da política"
                        style={{ cursor: "pointer" }}
                        size={18}
                        onClick={() => setShowModal(!showModal)}
                      />
                    </InputGroup.Text>
                    <InputGroup.Text id="basic-addon1">
                      <MD.MdAddCircle
                        title="Criar chave de política"
                        size="20"
                        style={{ color: "#37b24d", cursor: "pointer" }}
                        onClick={() => history.push(`/PolicyKey`)}
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
                <Row className="mt-2">
                  <Col>
                    <IO.IoAlertCircleOutline size={25} />
                    <span className="px-2">Negar acesso</span>
                  </Col>
                  <Col className="mt-1">
                    <Switch
                      defaultValue={getAllAcEntryPolicyByAcEntry.isDeny}
                      checked={policyIsDeny}
                      onChange={(e) => setPolicyIsDeny(!policyIsDeny)}
                      className="react-switch"
                      height={20}
                      width={48}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    />
                  </Col>
                  <Col align="end">
                    <Button className="shadow-none BtnPrimary" type="submit">
                      {" "}
                      Próximo
                    </Button>
                  </Col>
                </Row>
              </Row>
            </Form>
          )}
        </Card.Body>
      </Card>

      <Modal
        dialogClassName="border-radius-2"
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="sm"
        style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      >
        <Modal.Body>
          <Row className="justify-content-center text-center pb-3">
            <h5 className="my-3 pb-4 border-bottom">
              Excluir chave de política?
            </h5>

            <Button
              style={{ border: "none", backgroundColor: "var(--bg-red)" }}
              className="col-4 me-1"
              onClick={() => setShowModal(!showModal)}
            >
              Não
            </Button>
            <Button
              style={{ border: "none", backgroundColor: "var(--bg-green)" }}
              className="col-4 ms-1"
              onClick={() => ConfirmDelAcEntryPolicy()}
            >
              Sim
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Identification;
