import produce from "immer";

const INITIAL_STATE = {
    date: null
}

export default function user(state = INITIAL_STATE, action) {
    return produce(state, draft => {
        switch (action.type) {
            case "@auth/SIGN_IN_SUCCESS": {
                draft.date = action.payload.expiration;
                break;
            }
            case "@auth/SIGN_OUT": {
                draft.date = null;
                break;
            }
            case "@expiration": {
                draft.date = action.payload.expiration;
                break;
            }
            default:
                return state;
        }
    });
}