import React, { useEffect, useState } from "react";
import { Col, Container, Dropdown, Row, Spinner } from "react-bootstrap";
import * as FI from "react-icons/fi";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AddCardTerminal from "../../components/AddCardTerminal";
import CardTerminal from "../../components/Cards";
import Sidebar from "../../components/SideBar";
import api from "../../services/api";
import history from "../../services/history";
import "./styles.scss";

function HomeScreen() {
  const tenant = useSelector((state) => state.user.profile.tenants[0]);
  const [loading, setLoading] = useState(false);
  const [allAcEntry, setAllAcEntry] = useState([]);
  useEffect(() => {
    GetAllAcEntry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function GetAllAcEntry() {
    try {
      const response = await api.get(`/AdminArea/GetAllAcEntry/${tenant}`);
      const { status, data } = response;

      if (response.status === 200 && status) {

        setAllAcEntry(data.sort((a, b) => a.name.localeCompare(b.name)));
        setLoading(true);
      } else {
      }
    } catch (error) {
      if (!!error.response && error.response.status !== 401) {
      }
    }
  }
  const AddTerminal = () => {
    history.push("/AddTerminal:");
  };

  return (
    <>
      {/* sm, md, lg, xl, xxl */}
      <Container className="p-4" fluid="xxl">
        <Row>
          <Col md={12 / 3} xl={12 / 4} lg={12 / 4} className="col-auto">
            <Sidebar />
          </Col>
          <Col>
            <Row className="justify-content-between mt-5 mb-3">
              <Col className="col-auto">
                <h3>Terminais</h3>
              </Col>

              <Col className="col-auto mr-4">
                <Dropdown className="rounded-circle">
                  <Dropdown.Toggle
                    bsPrefix="circle-filter p-0 m-auto"
                    style={{ background: "#adb5bd", border: "none" }}
                  >
                    <FI.FiFilter size={15} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ background: "#212529" }}>
                    <Dropdown.Item style={{ color: "#adb5bd" }}>
                      Ativos
                    </Dropdown.Item>
                    <Dropdown.Item style={{ color: "#adb5bd" }}>
                      Desativados
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>

            {/* Cards Terminais */}

            <Row>

              {loading ? (
                <>
                  <Col sm={12 / 1} md={12 / 2} lg={12 / 4}>
                    <AddCardTerminal onClick={AddTerminal} />
                    <div className="skeleton"></div>
                  </Col>
                  {allAcEntry.map((x) => (
                    <Col sm={12 / 1} lg={12 / 4} md={12 / 2} key={x.acEntryId} >
                      <CardTerminal acEntry={x} updateFunction={GetAllAcEntry} />
                    </Col>
                  ))}
                </>
              ) : (
                <>
                  <Col className="d-flex justify-content-md-center mt-5">
                    <Spinner animation="border" />
                  </Col>
                </>
              )}

            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default HomeScreen;
