import moment from 'moment';
import 'moment/locale/pt-br';
import PropTypes from "prop-types";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { toast } from "react-toastify";
import AdminLayout from "../pages/_layouts/admin";
import ClientLayout from "../pages/_layouts/client";
import DefaultLayout from "../pages/_layouts/default";
import history from "../services/history";
import { store } from "../store";

export default function RouteWrapper({
    component: Component,
    isPrivate,
    verifiedEmail,
    ...rest
}) {

    const { signed } = store.getState().auth;

    if (!signed && isPrivate) {
        return <Redirect to={{ pathname: `/`, state: { returnRoute: rest.computedMatch.url } }} />
    }
    const profile = store.getState().user;

    const admin = store.getState().user.profile?.admin

    if (rest.location.pathname === "/adminArea/usermanagement" && signed && !admin) {
        toast.warn("Você não tem permissão para acessar essa página!")
        return <Redirect to="/Wallet" />;
    }


    if (rest.location.pathname === "/" && signed && admin) {
        return <Redirect to="/adminArea/usermanagement" />;
    }

    if (rest.location.pathname === "/" && signed && !admin) {
        return <Redirect to="/Wallet" />;
    }


    let Layout = DefaultLayout;

    if (signed && profile !== null) {
        admin ? Layout = AdminLayout : Layout = ClientLayout
        // profile.roles.map((x) => (
        //     x === "Admin" ? Layout = AdminLayout
        //         : x !== "Admin" ? Layout = ClientLayout
        //             : Layout = DefaultLayout
        // ));
    }

    /*  if (signed && moment().format('YYYY-MM-DD HH:mm:ss') < store.getState().expiration.date) {
 
         setTimeout(() => history.push("/logout"), 5000)
         toast.warn("Sua sessão expirou, você será desconectado em 5 segundos. Faça login novamente para continuar.")
 
         setTimeout(() => window.location.reload(), 7000)
     } */


    return <Route {...rest} render={props => (
        <Layout {...rest.computedMatch.params}>
            <Component {...props} />
        </Layout>
    )} />;

}


RouteWrapper.prototype = {
    isPrivate: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
        .isRequired
}


RouteWrapper.defaultProps = {
    isPrivate: false
}


