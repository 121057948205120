export function signInRequest(email, password, returnRoute) {
    return {
        type: "@auth/SIGN_IN_REQUEST",
        payload: { email, password, returnRoute }
    }
}

export function signInSuccess(token, user, expiration) {
    return {
        type: "@auth/SIGN_IN_SUCCESS",
        payload: { token, user, expiration }
    }
}

export function signFailure() {
    return {
        type: "@auth/SIGN_FAILURE"
    }
}

export function signOut(href) {
    return {
        type: "@auth/SIGN_OUT",
        href: href
    }
}