import { Filter } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import * as FI from "react-icons/fi";
import * as MD from "react-icons/md";
import { toast } from "react-toastify";
import api from "../../services/api";
import history from "../../services/history";
import Footer from "../Footer";
import Transactions from "../Transactions";
import "./styles.css";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


function Summary({ userEmail }) {
  const [loading, setLoading] = useState(true);
  const [transactionSummary, setTransactionSummary] = useState({
    balance: 0,
    valueEntry: 0,
    valueOutput: 0,
  });

  useEffect(() => {


    GetTransactionSummary();
  }, []);

  const [showValue, setShowValue] = useState(false);
  const [showModal, setShowModal] = useState(localStorage.getItem("AControl.NotShowAgain") === null ? true : localStorage.getItem("AControl.NotShowAgain") === "false");
  async function GetTransactionSummary() {

    if (!!!userEmail) {

      try {
        const response = await api.get(`/Transaction/TransactionSummary`);
        const { status } = response;

        if (response.status === 200 && status) {
          setTransactionSummary(response.data);
        }
      } catch (error) {

      }
    }
    else {
      try {
        const response = await api.get(`/AdminArea/TransactionSummary/${userEmail}`);
        const { status } = response;

        if (response.status === 200 && status) {
          setTransactionSummary(response.data);
        }
      } catch (error) {

      }
    }
    setLoading(false)
  }

  const { format: formatPrice } = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  return (
    <>
      <div className="relative-parent">
        <Container className="relative">
          <Row className="justify-content-center">
            <Row className="mb-5">
              <Col hidden={!!!userEmail}>
                <h3 style={{ color: "var(--shape)" }}>{transactionSummary.name}</h3>
              </Col>
              <Col hidden={!!userEmail}>
                <h3 style={{ color: "var(--shape)" }}>Minha carteira <FI.FiEye
                  onClick={() => setShowValue(!showValue)}
                  style={{ color: "white" }}
                  size={20}
                /></h3>
              </Col>
              <Col className="col-auto" hidden={!!userEmail}>
                <button
                  className="button-add-credit"
                  onClick={() => history.push("/Wallet/Payment")}
                >
                  Adicionar Crédito
                </button>

              </Col>


            </Row>

            <Col sm={12} md={4} onClick={() => setShowValue(!showValue)}>
              <Card style={{ background: "var(--bg-green)" }}>
                <Row className="pt-4 ps-4">
                  <Col>
                    <h6 style={{ color: "var(--shape)" }}>Saldo</h6>
                  </Col>
                  <Col className="me-4" align="end">
                    <MD.MdAttachMoney
                      style={{ color: "var(--shape)" }}
                      size={'2rem'}
                    />
                  </Col>

                </Row>
                {/*  <terminalImg size={40} /> */}
                <Card.Body className={showValue ? "ps-4 pt-1 pb-4" : "ps-4 pt-1 pb-4 blur"}>
                  <h3 style={{ color: "var(--shape)" }} className="fw-bold " >



                    {loading ?
                      <SkeletonTheme baseColor="rgba(255,255,255,0.4)" highlightColor="rgba(255,255,255,0.8)">

                        <Skeleton width={100} height={31} />

                      </SkeletonTheme>


                      : formatPrice(transactionSummary.balance)}

                  </h3>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={6} md={4} onClick={() => setShowValue(!showValue)} >

              <Card>
                <Row className="pt-4 ps-4">
                  <Col>
                    <h6 style={{ color: "var(--text-body)" }}>Entradas</h6>
                  </Col>
                  <Col className="me-4" align="end">
                    <FI.FiArrowUpCircle
                      style={{ color: "var(--bg-green)" }}
                      size={'2rem'}
                    />
                  </Col>
                </Row>
                {/*  <terminalImg size={40} /> */}
                <Card.Body className={showValue ? "ps-4 pt-1 pb-4" : "ps-4 pt-1 pb-4 blur"}>
                  <h3 className="fw-bold">
                    {
                      loading ? <SkeletonTheme >

                        <Skeleton width={100} height={31} />

                      </SkeletonTheme> :
                        formatPrice(transactionSummary.valueEntry)

                    }
                  </h3>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={6} md={"4"} onClick={() => setShowValue(!showValue)}>
              <Card>
                <Row className="pt-4 ps-4">
                  <Col>
                    <h6 style={{ color: "var(--text-body)" }}>Saídas</h6>
                  </Col>
                  <Col className="me-4" align="end">
                    <FI.FiArrowDownCircle
                      style={{ color: "var(--bg-red)" }}
                      size={'2rem'}
                    />
                  </Col>
                </Row>
                {/*  <terminalImg size={40} /> */}
                <Card.Body className={showValue ? "ps-4 pt-1 pb-4" : "ps-4 pt-1 pb-4 blur"}>
                  <h3 className="fw-bold">
                    {
                      loading ? <SkeletonTheme >

                        <Skeleton width={100} height={31} />

                      </SkeletonTheme> :
                        formatPrice(transactionSummary.valueOutput)
                    }
                  </h3>
                </Card.Body>
              </Card>
            </Col>


            <Transactions userEmail={userEmail} />
          </Row>
          <Footer />
        </Container>
        <Modal show={false} onHide={() => setShowModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Atenção!!!!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            A partir de 01/06 não venderemos mais pacotes de refeição.
            O valor da refeição avulsa será de <strong> R$13,00</strong>. A validade será de 1 ano a partir da data da compra.</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)} >
              Fechar
            </Button>
            <Button className="BtnPrimary" onClick={() => { setShowModal(false); localStorage.setItem("AControl.NotShowAgain", true) }} >
              Não mostrar novamente
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default Summary;
