import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import TextField from '@mui/material/TextField';
import { format } from "date-fns";
import { pt } from "date-fns/esm/locale";
import { React, useEffect, useState } from "react";
import {
  Button,
  Card, Col, Container, Form,
  InputGroup, Row
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Io from "react-icons/io";
import * as IO from "react-icons/io5";
import * as MD from "react-icons/md";
import { toast } from "react-toastify";
import api from "../../../services/api";
import history from "../../../services/history";
import "./styles.css";


function Schedules(props) {
  const [isLoading, setIsLoading] = useState(true);

  const [getAllAcEntryPolicyByAcEntry, setGetAllAcEntryPolicyByAcEntry] = useState([])

  const [sTime, setSTime] = useState(new Date());
  const [eTime, setETime] = useState(new Date());
  const startTime = format(new Date(sTime), 'HH:mm:ss')
  const endTime = format(new Date(eTime), 'HH:mm:ss')
  const [startDate, setStartDate] = useState(new Date());
  const [clockwise, setClockwise] = useState(true)
  const [anticlockwise, setAnticlockwise] = useState(true)
  const [isOverrideDateSchedule, setIsOverrideDateSchedule] = useState(false)
  const weekDays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
  const [daysSelected, setDaysSelected] = useState([]);

  const [scheduledPolicies, setScheduledPolicies] = useState([]);

  const [allDaysChecked, setAllDaysChecked] = useState(false)
  const [clearChecked, setClearChecked] = useState(null)

  useEffect(() => {
  }, [scheduledPolicies]);

  useEffect(() => {
    async function GetAllAcEntryPolicyByAcEntry() {
      try {
        const response = await api.get(`/AdminArea/GetAllAcEntryPolicyByAcEntry/${props.i.AcEntryId}`);
        const data = response.data;

        data.map((x) => {
          if (x.acEntryPolicyId === props.i.AcEntryPolicyId) {
            setGetAllAcEntryPolicyByAcEntry(x)
            if (isLoading) {
              x.acEntryPolicySecuritySchedules.map((x) => {
                setScheduledPolicies(scheduledPolicies => [...scheduledPolicies, x]);
                return x
              })

            }
          }
          return x
        })
      } catch (err) {
        console.log(err);
      }
    }

    setIsLoading(true);
    GetAllAcEntryPolicyByAcEntry()
    setClearChecked(null)
    setAllDaysChecked(false)

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearChecked]);

  function addSchedulesPolicies() {

    if (allDaysChecked) {
      setDaysSelected(weekDays)
    }

    if (!isOverrideDateSchedule) {
      daysSelected.map((x) => {

        let data = {
          "clockwise": clockwise,
          "anticlockwise": anticlockwise,
          "dayWeek": x === "Sáb" ? "SAB" : x.toUpperCase(),
          "startTime": startTime,
          "endTime": endTime,
          "applyAnyOverrideDate": false,
          "isOverrideDateSchedule": false
        }

        setScheduledPolicies(scheduledPolicies => [...scheduledPolicies, data]);
        setClearChecked(false)
        setDaysSelected([]);

        return x
      })
    } else {
      const dateSchedule = format(new Date(startDate), 'yyy-MM-dd', {
        locale: pt
      })

      let data = {

        "clockwise": clockwise,
        "anticlockwise": anticlockwise,
        "dayWeek": null,
        "startTime": startTime,
        "endTime": endTime,
        "applyAnyOverrideDate": false,
        "isOverrideDateSchedule": true,
        "overrideDate":
        {
          "name": props.i.Name,
          "date": dateSchedule
        }
      }
      setScheduledPolicies(scheduledPolicies => [...scheduledPolicies, data]);
      setClearChecked(false)
    }
  }

  async function OnSubmit() {
    if (scheduledPolicies.length === 0) {
      toast.warning("Adicione pelo menos um agendamento da política!")
      return

    }

    const dataToAPI = {

      "AcEntryPolicyId": getAllAcEntryPolicyByAcEntry.acEntryPolicyId,
      "acEntryId": getAllAcEntryPolicyByAcEntry.acEntryId,
      "name": props.i.Name,
      "requiredKey": props.i.RequiredKey,
      "value": props.i.Value == null ? null : Number((props.i.Value.replace(/\D/g, "") / 100).toFixed(2) + ""),
      "isDeny": props.i.IsDeny,
      "policyLevel": props.i.PolicyLevel,
      "acEntryPolicySecuritySchedules": scheduledPolicies

    }
    try {
      await api.put(`/AdminArea/PutAcEntryPolicy`, dataToAPI);
      toast.success(`Politicas editadas com sucesso!`);
      history.push(`/TerminalPolicy/id:${props.i.AcEntryId}`)
    } catch (err) {
      toast.error(err.message)
      console.log(err.message);
    }

  }

  function AddOrDeleteDay(status, day) {

    if (status) {
      setDaysSelected(daysSelected => [...daysSelected, day])
    } else {
      daysSelected.splice(daysSelected.indexOf(day), 1);
      setDaysSelected(daysSelected)
    }
  }

  function DeletePolicy(policy) {
    if (scheduledPolicies.length === 1) {
      toast.warning("Política não pode ficar sem agendamento")
      return

    }

    var arr = scheduledPolicies
    var i = arr.indexOf(policy)
    if (i !== -1) {
      arr.splice(i, 1)
      setScheduledPolicies(scheduledPolicies => ([...arr]));
    }
    /*   setScheduledPolicies.splice(scheduledPolicies.indexOf(policy), 1);
      setScheduledPolicies(scheduledPolicies) */
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Card>
              <Card.Body className="my-2">
                <Card.Title>Agendamento da Política de Segurança</Card.Title>
                <InputGroup className="mt-3">
                  <Form.Check
                    checked={!isOverrideDateSchedule}
                    defaultValue="selected"
                    inline
                    label="Dias da semana"
                    name="group1"
                    type="radio"
                    onClick={(e) => setIsOverrideDateSchedule(false)}

                  />
                  <Form.Check
                    inline
                    label="Datas Fixa"
                    name="group1"
                    type="radio"
                    onClick={(e) => setIsOverrideDateSchedule(true)}

                  />
                </InputGroup>
                <Row>
                  <Col className="mt-2">
                    <InputGroup>
                      <Row>
                        {
                          isOverrideDateSchedule ?
                            <> <Row>

                              <InputGroup.Text className="ps-2">
                                Sentido do giro
                              </InputGroup.Text>
                              <Col>
                                <Form.Check type="checkbox" defaultChecked={clockwise} inline label="Horário" onChange={(e) => setClockwise(e.target.checked)} />
                                <Form.Check type="checkbox" inline defaultChecked={anticlockwise} label="Anti-Horário" onChange={(e) => setAnticlockwise(e.target.checked)} />
                              </Col>
                            </Row>
                              <Row>
                                <Col className="pt-3">
                                  <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} inline />
                                </Col>
                              </Row>
                              <Row className="pt-3" xs={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <Col >
                                    <TimePicker
                                      ampm={false}
                                      openTo="hours"
                                      views={['hours', 'minutes', 'seconds']}
                                      inputFormat="HH:mm:ss"
                                      mask="__:__:__"
                                      label="Hora início"
                                      value={sTime}
                                      onChange={(newValue) => {
                                        setSTime(newValue);
                                      }}
                                      renderInput={(params) => <TextField {...params} />}
                                    />
                                  </Col>
                                  <Col >
                                    <TimePicker
                                      ampm={false}
                                      openTo="hours"
                                      views={['hours', 'minutes', 'seconds']}
                                      inputFormat="HH:mm:ss"
                                      mask="__:__:__"
                                      label="Hora final"
                                      value={eTime}
                                      onChange={(newValue) => {
                                        setETime(newValue);
                                      }}
                                      renderInput={(params) => <TextField {...params} />}
                                    />
                                  </Col>
                                </LocalizationProvider>
                              </Row>
                              <Row>
                                <Col>
                                  {/*  <Form.Check checked={clockwise} inline label="Sobreescrever datas anteriores" onChange={(e) => setClockwise(e.target.checked)} /> */}
                                </Col>
                              </Row>
                            </>
                            :
                            <>
                              <Row>

                                <InputGroup.Text className="ps-2">
                                  Sentido do giro
                                </InputGroup.Text>
                                <Col>
                                  <Form.Check type="checkbox" defaultChecked={clockwise} inline label="Horário" onChange={(e) => setClockwise(e.target.checked)} />
                                  <Form.Check type="checkbox" inline defaultChecked={anticlockwise} label="Anti-Horário" onChange={(e) => setAnticlockwise(e.target.checked)} />
                                </Col>
                              </Row>
                              <Row>
                                <Col className="mt-3">
                                  <InputGroup.Text>
                                    <Io.IoMdCalendar className="me-4" size={25} />
                                    Selecione os dias da Semana
                                  </InputGroup.Text>
                                  <Form.Group controlId="formBasicCheckbox">
                                    <Form.Check
                                      checked={clearChecked}
                                      inline
                                      onChange={(e) => { e.target.checked ? setDaysSelected(weekDays) : setDaysSelected([]); setAllDaysChecked(!allDaysChecked) }}
                                      label="Todos os dias"
                                      name="group1"
                                      type="checkbox" />
                                  </Form.Group>

                                  <Form.Group>
                                    {weekDays.map((weekDays, id) => (
                                      <Form.Check
                                        disabled={allDaysChecked ? true : clearChecked}
                                        checked={allDaysChecked ? true : clearChecked}
                                        id={id + 1}
                                        value={weekDays}
                                        onChange={(e) => { AddOrDeleteDay(e.target.checked, e.target.value); }}
                                        key={weekDays}
                                        inline
                                        label={weekDays}
                                        type="checkbox" />
                                    ))}
                                  </Form.Group>
                                  <Row className="pt-3" xs={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <Col >
                                        <TimePicker
                                          ampm={false}
                                          openTo="hours"
                                          views={['hours', 'minutes', 'seconds']}
                                          inputFormat="HH:mm:ss"
                                          mask="__:__:__"
                                          label="Hora início"
                                          value={sTime}
                                          onChange={(newValue) => {
                                            setSTime(newValue);
                                          }}
                                          renderInput={(params) => <TextField {...params} />}
                                        />
                                      </Col>
                                      <Col >
                                        <TimePicker
                                          ampm={false}
                                          openTo="hours"
                                          views={['hours', 'minutes', 'seconds']}
                                          inputFormat="HH:mm:ss"
                                          mask="__:__:__"
                                          label="Hora final"
                                          value={eTime}
                                          onChange={(newValue) => {
                                            setETime(newValue);
                                          }}
                                          renderInput={(params) => <TextField {...params} />}
                                        />
                                      </Col>
                                    </LocalizationProvider>
                                  </Row>
                                </Col>
                              </Row>

                            </>
                        }

                      </Row>
                    </InputGroup>

                    <Button onClick={(e) => { addSchedulesPolicies() }} className="shadow-none mt-5 BtnPrimary" type="submit">
                      {" "}
                      ADICIONAR AGENDAMENTO
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card className="my-2">
              <Card.Body className="px-0">

                <Row className="pb-3 py-2 ms-3">
                  <Col md={7}>
                    <Card.Title as="h5">Agendamentos da política:</Card.Title>
                  </Col>
                </Row>

                <Row className=" text-center p-2 border-terminal m-0">
                  <Col xs={3} md as="h6">
                    Dia da Semana
                  </Col>
                  <Col xs={3} md as="h6">
                    Data Fixa
                  </Col>
                  <Col xs={2} md as="h6">
                    Hora Início
                  </Col>
                  <Col xs={2} md as="h6">
                    Hora Final
                  </Col>
                  <Col xs={2} md as="h6">
                    Sentido do Giro
                  </Col>
                  <Col xs={2} md as="h6">
                    Ações
                  </Col>
                </Row>


                <Col className="terminal-card py-2 p-0">
                  {scheduledPolicies.map((x, id) => (
                    <Card key={id + 1} className="mt-2 py-2 text-center"
                      style={{
                        borderRadius: "0.1px",
                      }}>
                      <Card.Body>
                        <Row>
                          <Col className="p-0 mx-1">
                            <span>
                              <strong>{x.dayWeek == null ? "..." : x.dayWeek}</strong>
                            </span>
                          </Col>
                          <Col className="pl-sm-1 p-0">
                            <span>{x.overrideDate == null ? "..." : x.overrideDate.date}</span>
                            {/* <span>...</span> */}
                          </Col>
                          <Col className="pl-sm-1 p-0">
                            <span>{x.startTime}</span>
                          </Col>
                          <Col className="pl-sm-1 p-0">
                            <span>{x.endTime}</span>
                          </Col>
                          <Col className="pl-sm-1 p-0">
                            {x.clockwise ? <MD.MdRotateRight title="Horário" size={25} /> : ""}
                            {x.anticlockwise ? <MD.MdRotateLeft title="Anti-Horário" size={25} /> : ""}
                          </Col>
                          <Col onClick={() => DeletePolicy(x)} className="p-0 text-right">
                            <IO.IoTrash style={{ cursor: "pointer" }} />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  )
                  )}
                </Col>


              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col align="end" className="mt-3">
            <Button onClick={() => { OnSubmit(); }} className="shadow-none BtnPrimary " type="submit">
              {" "}
              SALVAR AGENDAMENTOS
            </Button>
          </Col>
        </Row>
      </Container >
    </>
  );
}

export default Schedules;
