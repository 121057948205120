import React, { useState } from "react";
import { Button, ButtonGroup, Card, Col, Container, FormControl, Image, InputGroup, Modal, Row } from "react-bootstrap";
import * as FA from "react-icons/fa";
import * as MD from "react-icons/md";
import { toast } from "react-toastify";
import { ReactComponent as Rfidcard } from "../../assets/img/rfidcard.svg";
import TerminalImg from "../../assets/img/terminal.svg";
import api from "../../services/api";
import history from "../../services/history";
import "./styles.scss";




function CardTerminal(props) {

  /* Data */
  const [name, setName] = useState(props.acEntry.name);
  const [description, setDescription] = useState(props.acEntry.description);
  const [deviceModel, setDeviceModel] = useState(props.acEntry.pluginData.deviceModel);
  const [turnStyleMode, setTurnStyleMode] = useState(props.acEntry.pluginData.turnstyleMode);
  const [clockwiseAllowed, setClockwiseAllowed] = useState(props.acEntry.pluginData.clockwiseAllowed); //sentido Livre - sentido Horario
  const [anticlockwiseAllowed, setAnticlockwiseAllowed] = useState(props.acEntry.pluginData.anticlockwiseAllowed); //sentido Livre - sentido anti-Horario
  const [clockwise, setClockwise] = useState(props.acEntry.pluginData.clockwise); //sentido do giro - sentido horario
  const [anticlockwise, setAnticlockwise] = useState(props.acEntry.pluginData.anticlockwise); //sentido do giro - sentido anti-horario
  const [fingerprintReading, setFingerprintReading] = useState(props.acEntry.pluginData.fingerprintReading);
  const [barCodeReading, setBarCodeReading] = useState(props.acEntry.pluginData.barCodeReading);
  const [rfidReading, setRfidReading] = useState(props.acEntry.pluginData.rfidReading);

  /* Modal */
  const [cardShow, setCardShow] = useState(false);
  const [modalDeletingConfirm, setModalDeletingConfirm] = useState(false);
  /* Modal Edit sector */
  const [activeEditing, setActiveEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function UpdateData(t) {
    setIsLoading(true);  //mudar texto enquanto tenta realizar o update no banco
    document.body.style.cursor = "wait"; //cursor enquanto tenta realizar o update no banco

    const data = {
      acEntryId: t.acEntryId,
      dataKey: t.dataKey,
      description: description,
      name: name,
      pluginData: {
        active: t.pluginData.active,
        anticlockwise: anticlockwise,
        anticlockwiseAllowed: anticlockwiseAllowed,
        barCodeReading: barCodeReading,
        clockwise: clockwise,
        clockwiseAllowed: clockwiseAllowed,
        clockwiseEntrance: t.pluginData.clockwiseEntrance,
        deviceModel: deviceModel,
        fingerprintReading: fingerprintReading,
        fwVersion: t.pluginData.fwVersion,
        ipAddress: t.pluginData.ipAddress,
        mac: t.pluginData.mac,
        rfIdReading: rfidReading,
        turnstyleMode: turnStyleMode,
        type: t.pluginData.type
      },
      referenceCode: t.referenceCode,
      type: t.type
    }

    try {
      const response = await api.put(`/AdminArea/PutAcEntry`, data)
      const { status } = response

      if (status === 200 && status) {
        toast.success("Atualizado com sucesso!")

        props.updateFunction(); //Chama a Função do HomeScreen para trazer os cards atualizados do banco
        document.body.style.cursor = "default"; //Volta o Cursor para o padrão ao final do Update
        setActiveEditing(false); //desabilita os campos de edição durante o processo de atualização
        setIsLoading(false); //altera o texto e o curso enquanto tenta realizar o update
        setCardShow(false);//Fecha o modal da edição

      } else {
        toast.error(`Erro na solicitação!`);
      }
    } catch (error) {
      if ((!!error.response && error.response.status !== 401)) {
        toast.error("Erro na solicitação.. tente mais tarde");
      }
    }
  }

  async function DeleteData(id) {
    try {
      const response = await api.delete(`/AdminArea/DeleteAcEntry/${id}`)
      const { status } = response

      if (status === 200 && status) {
        toast.success("Excluído com sucesso!")
        props.updateFunction(); //Chama a Função do HomeScreen para trazer os cards atualizados do banco
        setCardShow(false);//Fecha o modal da edição

      } else {
        toast.error(`Erro na exclusão!`);
      }
    } catch (error) {
      if ((!!error.response && error.response.status !== 401)) {
        toast.error("Erro na exclusão.. tente mais tarde");
      }
    }
  }

  function CancelUpdate() {
    setName(props.acEntry.name);
    setDescription(props.acEntry.description);
    setDeviceModel(props.acEntry.pluginData.deviceModel);
    setTurnStyleMode(props.acEntry.pluginData.turnstyleMode);
    setClockwiseAllowed(props.acEntry.pluginData.clockwiseAllowed);
    setAnticlockwiseAllowed(props.acEntry.pluginData.anticlockwiseAllowed);
    setClockwise(props.acEntry.pluginData.clockwise);
    setAnticlockwise(props.acEntry.pluginData.anticlockwise);
    setFingerprintReading(props.acEntry.pluginData.fingerprintReading);
    setBarCodeReading(props.acEntry.pluginData.barCodeReading);
    setRfidReading(props.acEntry.pluginData.rfidReading);
  }

  return (
    <>
      <Row>
        <Col>
          <Card className="p-1" style={{ cursor: "pointer", height: '15rem' }} onClick={() => setDeviceModel(props.acEntry.pluginData.deviceModel)} >

            <Row className="pt-2 p-2 " onClick={() => setCardShow(true)}>
              <Card.Img
                className="me-auto"
                src={TerminalImg}
                style={{ width: 90 }}
              />
              <div
                className={
                  props.acEntry.pluginData.active
                    ? "circle-active"
                    : "circle-disabled"
                }
              ></div>
            </Row>

            <Card.Body onClick={() => setCardShow(true)}>
              <h6>
                Nome: <span> {props.acEntry.name}</span>
              </h6>
              <h6>
                IP: <span> {props.acEntry.pluginData.ipAddress}</span>
              </h6>
            </Card.Body>

            <Card.Footer>
              <div className="card-container">
                <button type="button" onClick={() => setCardShow(true)} className="btn-card">
                  VER MAIS
                </button>
                <button
                  type="button"
                  onClick={() => history.push(`/TerminalPolicy/id:${props.acEntry.acEntryId}`)}
                  className="btn-card"
                >
                  POLÍTICA
                </button>
              </div>
            </Card.Footer>

          </Card>
        </Col>
      </Row>

      {/* Modal Viewer Details */}

      <Modal
        dialogClassName="border-radius-2"
        show={cardShow}
        onHide={() => { setCardShow(false); setActiveEditing(false); CancelUpdate() }}
        centered
        animation={false}
      >
        <Modal.Header className="pb-0">
          <Row className="col-12">
            <Col className="px-4">
              <Col className="" hidden={!activeEditing}>
                <h5 >{isLoading ? <strong>Atualizando Terminal</strong> : <strong>Editar Terminal</strong>}</h5>
              </Col>
              <Col className="d-flex align-items-center" as="h6">
                <div className={`${props.acEntry.pluginData.active ? "circle-active" : "circle-disabled"} ms-0 me-2`}></div>
                <span>Status</span>
              </Col>
            </Col>

            <Col className="d-flex justify-content-end p-0">
              <MD.MdClose
                size={30}
                onClick={() => setCardShow(false)}
                style={{ cursor: "pointer" }}
              />
            </Col>
          </Row>
        </Modal.Header>

        <Modal.Body className="show-grid">
          <Container>
            <Row>
              <Col xs={4}>
                <Image
                  fluid
                  src={TerminalImg}
                  style={{ width: 155 }}
                  alt="status do terminal"
                />
              </Col>
              <Col xs={8}>
                <Modal.Title as="h6" className="mx-3">
                  Nº Serial: {props.acEntry.referenceCode}
                </Modal.Title>
                <Modal.Title as="h6" className="mx-3">
                  IP Adress: {props.acEntry.pluginData.ipAddress}
                </Modal.Title>
                <Modal.Title as="h6" className="mx-3">
                  Ativo:{" "}
                  {props.acEntry.pluginData.active ? "Sim" : "Desativado"}
                </Modal.Title>

                <Modal.Title as="h6" className="mx-3">
                  Mac: {props.acEntry.pluginData.mac}
                </Modal.Title>
                <Modal.Title as="h6" className="mx-3">
                  Tipo: {props.acEntry.pluginData.type}
                </Modal.Title>
                <Modal.Title as="h6" className="mx-3">
                  Fw. Versão: {props.acEntry.pluginData.fwVersion}
                </Modal.Title>
              </Col>
            </Row>

            {/* Edit Forms */}
            <Row className="mt-2">
              <Col style={{ textAlign: "right" }}>
                <Button
                  className="me-2"
                  onClick={() => setActiveEditing(!activeEditing)}
                  style={{
                    paddingTop: 0,
                    paddingLeft: 7,
                    paddingRight: 7,
                    background: "var(--bg-green)",
                    border: "none"
                  }}
                >
                  <MD.MdEdit size={15} />
                </Button>

                <Button
                  onClick={() => setModalDeletingConfirm(true)}
                  style={{
                    paddingTop: 0,
                    paddingLeft: 7,
                    paddingRight: 7,
                    background: "var(--bg-red)",
                    border: "none"
                  }}
                >
                  <MD.MdDelete size={15} />
                </Button>

                {/*                 
                <Button className="mx-1 BtnRed" hidden={!activeEditing} onClick={() => setActiveEditing(false)} >Excluir</Button>
                <Button className="mx-1 BtnBlue" hidden={!activeEditing} onClick={() => setActiveEditing(false)} >Cancelar</Button>
                <Button className="mx-1 BtnGreen" hidden={!activeEditing} onClick={() => setActiveEditing(false)} >Salvar</Button> 
                 */}
                {/* 
                <Button className="mx-1 BtnIcon" style={{backgroundColor:"var(--bg-red)"}} hidden={!activeEditing} onClick={() => setActiveEditing(false)} ><MD.MdDelete size={15} /></Button>
                <Button className="mx-1 BtnIcon" style={{backgroundColor:"var(--bg-secondary)"}} hidden={!activeEditing} onClick={() => setActiveEditing(false)} ><MD.MdClear size={15} /></Button>
                <Button className="mx-1 BtnIcon" style={{backgroundColor:"var(--bg-green)"}} hidden={!activeEditing} onClick={() => setActiveEditing(false)} ><MD.MdCheck size={15} /></Button>
                 */}
              </Col>
            </Row>

            {/* Form  */}
            <Row className="mt-4">
              <Col>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <MD.MdDashboard size={20} />
                  </InputGroup.Text>
                  <FormControl
                    disabled={!activeEditing}
                    defaultValue={props.acEntry.name}
                    onChange={(e) => setName(e.target.value)}
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <MD.MdDescription size={20} />
                  </InputGroup.Text>
                  <FormControl
                    disabled={!activeEditing}
                    defaultValue={props.acEntry.description}
                    onChange={(e) => setDescription(e.target.value)}
                    aria-label="Description"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <label className="input-group-text" htmlFor="inputGroupSelect01">
                  <MD.MdList size={30} />
                  <span className="ms-2">Modelo Terminal</span>
                  <select disabled={!activeEditing} className="form-select ms-2" id="inputGroupSelect01"
                    onChange={(e) => setDeviceModel(Number(e.target.value))} defaultValue={props.acEntry.pluginData.deviceModel}
                  >
                    <option value={deviceModel}>{deviceModel === 5 ? "TiketGateNKRollLight" : deviceModel}</option>
                    {/* 
                      {model.map((x) => {
                        return <option value={x + 1}>{`${x + 1}`}</option>;
                      })} 
                    */}
                  </select>
                </label>

                {/* 
                    <div className="input-group mb-3">
                      <label
                        className="input-group-text"
                        htmlFor="inputGroupSelect01"
                      >
                        <MD.MdList />                   
                      </label>
                      <select disabled={!activeEditing} className="form-select" id="inputGroupSelect01"  >
                        <option value="">Modelo Terminal</option>
                        {deviceModel.map((x) => {
                          return <option value={x + 1}>{`${x + 1}`}</option>;
                        })}
                      </select>
                    </div>  
                  */}

              </Col>
            </Row>
            <Row>
              <Col>
                <label className="input-group-text" htmlFor="inputGroupSelect01">
                  <MD.MdList size={30} />
                  <span className="ms-2">Modo Virada</span>
                  <select disabled={!activeEditing} className="form-select ms-2" id="inputGroupSelect01"
                    onChange={(e) => setTurnStyleMode(Number(e.target.value))} defaultValue={props.acEntry.pluginData.turnStyleMode}
                  >
                    <option value={turnStyleMode}>{turnStyleMode === 3 ? "QuadratureNormal" : turnStyleMode}</option>
                    {/* {turnMode.map((x) => {
                      return <option value={x + 1}>{`${x + 1}`}</option>;
                    })} */}
                  </select>
                </label>

                {/*
                  <div className="input-group mb-3">
                    <label
                      className="input-group-text"
                      htmlFor="inputGroupSelect01"
                    >
                      <MD.MdList />
                    </label>
                    <select disabled={!activeEditing} className="form-select" id="inputGroupSelect01">
                      <option>Modo Virada</option>
                      {turnstyleMode.map((x) => {
                        return <option value={x + 1}>{`${x + 1}`}</option>;
                      })}
                    </select>
                  </div> 
                */}

              </Col>
            </Row>

            <Row>
              <Col>
                <Row className="mt-3">
                  <Col>
                    <h6>Sentido Livre</h6>
                    <ButtonGroup>
                      <Button className="me-2" ml="4"
                        bsPrefix={clockwiseAllowed ? "btn-modalActive" : "btn-modal"}
                        onClick={() => setClockwiseAllowed(!clockwiseAllowed)}
                        disabled={!activeEditing}
                      >
                        <MD.MdRotateRight size={40} />
                      </Button>
                      <Button
                        bsPrefix={anticlockwiseAllowed ? "btn-modalActive" : "btn-modal"}
                        onClick={() => setAnticlockwiseAllowed(!anticlockwiseAllowed)}
                        disabled={!activeEditing}
                      >
                        <MD.MdRotateLeft size={40} />
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <h6>Sentido do Giro</h6>
                  <Col>
                    <ButtonGroup>
                      <Button
                        className="me-2"
                        bsPrefix={clockwise ? "btn-modalActive" : "btn-modal"}
                        onClick={() => setClockwise(!clockwise)}
                        disabled={!activeEditing}
                      >
                        <MD.MdRotateRight size={40} />
                      </Button>
                      <Button
                        bsPrefix={anticlockwise ? "btn-modalActive" : "btn-modal"}
                        onClick={() => setAnticlockwise(!anticlockwise)}
                        disabled={!activeEditing}
                      >
                        <MD.MdRotateLeft size={40} />
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </Col>



              <Col sm={7} className="d-flex justify-content-md-center align-items-center pt-5" >
                <Card
                  className="p-4"
                  style={{ backgroundColor: "#212529", color: "#f1f3f5" }}
                >
                  <h6 className="text-center pb-2"> Opções de Acesso </h6>
                  <Card.Body>
                    <Col>
                      <MD.MdFingerprint
                        className="me-3"
                        size={40}
                        cursor="pointer"
                        style={{ color: `${fingerprintReading ? "#ea4e23" : "#f1f3f5"}` }}
                        onClick={() => activeEditing ? setFingerprintReading(!fingerprintReading) : null}
                      />
                      <FA.FaBarcode
                        className="me-3"
                        size={40}
                        cursor="pointer"
                        style={{ color: `${barCodeReading ? "#ea4e23" : "#f1f3f5"}` }}
                        onClick={() => activeEditing ? setBarCodeReading(!barCodeReading) : null}
                      />
                      <Rfidcard
                        src={Rfidcard}
                        cursor="pointer"
                        style={{ fill: `${rfidReading ? "#ea4e23" : "#f1f3f5"}`, width: 45 }}
                        onClick={() => activeEditing ? setRfidReading(!rfidReading) : null}
                      />

                    </Col>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <Button className="BtnPrimary" hidden={!activeEditing} onClick={() => UpdateData(props.acEntry)}>
            {isLoading ? "Salvando" : "Salvar"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogClassName="border-radius-2"
        show={modalDeletingConfirm}
        onHide={() => setModalDeletingConfirm(false)}
        centered
        size="sm"
        // animation={false}
        style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      >
        <Modal.Body>
          <Row className="justify-content-center text-center pb-3">
            <h5 className="my-3 pb-4 border-bottom">Excluir Terminal?</h5>

            <Button
              style={{ border: "none", backgroundColor: "var(--bg-red)" }}
              className="col-4 me-1" onClick={() => setModalDeletingConfirm(false)}
            >Não</Button>
            <Button
              style={{ border: "none", backgroundColor: "var(--bg-green)" }}
              className="col-4 ms-1" onClick={() => { DeleteData(props.acEntry.acEntryId); setModalDeletingConfirm(false) }}
            >Sim</Button>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CardTerminal;
