import React from "react";

import { Card } from "react-bootstrap";

import * as MD from "react-icons/md";
import history from "../../services/history";
import "./styles.css";

function AddCardTerminal() {
  const AddTerminal = () => {
    history.push("/AddTerminal");
  };
  return (
    <>
      <Card
        className="card_addterminal"
        onClick={AddTerminal}
        style={{ cursor: "pointer" }}
      >
        <Card.Body>
          <div>
            <MD.MdAdd size={40} />
          </div>
          <span>Adicionar Terminal</span>
        </Card.Body>
      </Card>
    </>
  );
}

export default AddCardTerminal;
