import { addDays, format } from "date-fns";
import { pt } from "date-fns/esm/locale";
import React, { Fragment, useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Modal, Row, Table } from "react-bootstrap";
import * as FI from "react-icons/fi";
import * as HI from "react-icons/hi";
import * as AI from "react-icons/ai";
import * as MD from 'react-icons/md';

import PaginationComponent from "react-js-pagination";
import { toast } from "react-toastify";
import api from "../../services/api";
import BankSlipDisplay from "../BankSlipDisplay";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function Transactions({ userEmail }) {
  const [loading, setLoading] = useState(true);
  //CustomerOrders
  const [transactions, setTransactions] = useState([]);

  //Pagination
  const [totalItens, setTotalItens] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [itensPerPage] = useState(7);
  const [detailOrder, setDetailOrder] = useState({});

  const [slipData, setSlipData] = useState(null);

  const { format: formatPrice } = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  const outIcon = (
    <FI.FiArrowDownCircle style={{ color: "var(--bg-red)" }} size={25} />
  );
  const inIcon = (
    <FI.FiArrowUpCircle style={{ color: "var(--bg-green)" }} size={25} />
  );
  const pendingIcon = (
    <HI.HiOutlineClock style={{ color: "#F7CB73" }} size={25} />
  );
  const barcodeIcon = (
    <AI.AiOutlineBarcode style={{ color: "black" }} size={25} />
  );
  const creditCardIcon = (
    <AI.AiOutlineCreditCard style={{ color: "black" }} size={25} />
  );
  const [showDetailModal, setShowDetailModal] = useState(false);

  useEffect(() => {
    GetTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  async function GetTransaction() {
    if (!!!userEmail) {


      try {
        const response = await api.get(
          `/Transaction/TransactionsTotvs/${activePage}/${itensPerPage}/`
        );
        const { status } = response;

        if (response.status === 200 && status) {
          setTransactions(response.data.entities);
          setTotalItens(response.data.indexSize);
        } else {
        }
      } catch (error) {
        if (!!error.response && error.response.status !== 401) {
        }
      }
    } else {
      try {
        const response = await api.get(
          `/AdminArea/Transactions/${userEmail}/${activePage}/${itensPerPage}/`
        );
        const { status } = response;

        if (response.status === 200 && status) {
          setTransactions(response.data.entities);
          setTotalItens(response.data.indexSize);
        } else {
        }
      } catch (error) {
        if (!!error.response && error.response.status !== 401) {
        }
      }
    }
    setLoading(false)
  }

  function explodeDate(data, type) {
    if (type == 1) {
      //const dt = data.split(".");
      return `${data.substr(6, 4)}-${data.substr(3, 2)}-${data.substr(0, 2)}`;
    } else if (type == 2) {
      return `${data.substr(4, 4)}-${data.substr(2, 2)}-${data.substr(0, 2)}`;
    }
  }

  function BankSlipParse(data) {
    const responsePay = JSON.parse(data);
    const entity = {
      nuTituloGerado: responsePay.nuTituloGerado,
      //nuCnpjBeneficiario: responsePay.nuCnpjBeneficiario,
      nomeBeneficiario: responsePay.nomeBeneficiario,
      logradouroBeneficiario: responsePay.logradouroBeneficiario,
      nuLogradouroBeneficiario: responsePay.nuLogradouroBeneficiario,
      complementoLogradouroBeneficiario:
        responsePay.complementoLogradouroBeneficiario,
      cepBeneficiario: `${responsePay.cepBeneficiario}-${responsePay.cepComplementoBeneficiario}`,
      municipioBeneficiario: responsePay.municipioBeneficiario,
      ufBeneficiario: responsePay.ufBeneficiario,
      nomePagador: responsePay.nomePagador,
      cpfcnpjPagador: `${responsePay.cpfcnpjPagador.substr(
        0,
        9
      )}${responsePay.cpfcnpjPagador.substr(13, 2)}`,
      enderecoPagador: responsePay.enderecoPagador,
      bairroPagador: responsePay.bairroPagador,
      municipioPagador: responsePay.municipioPagador,
      ufPagador: responsePay.ufPagador,
      cepPagador: `${responsePay.cepPagador}-${responsePay.cepComplementoPagador}`,
      dtEmissao: explodeDate(responsePay.dtEmissao, 2),
      dtVencimentoBoleto: explodeDate(responsePay.dtVencimentoBoleto, 1),
      dtLimitePagamentoBoleto: explodeDate(
        responsePay.dtLimitePagamentoBoleto,
        1
      ),
      vlTitulo: responsePay.vlTitulo,
      cdBarras: responsePay.cdBarras,
      linhaDigitavel: responsePay.linhaDigitavel,
    };
    return {
      ...entity,
      priceFormatted: formatPrice(parseInt(entity.vlTitulo) / 100),
      dtEmissao: format(addDays(new Date(entity.dtEmissao), 1), "dd/MM/yyyy"),
      dtLimitePagamentoBoleto: format(
        addDays(new Date(entity.dtLimitePagamentoBoleto), 1),
        "dd/MM/yyyy"
      ),
      dtVencimentoBoleto: format(
        addDays(new Date(entity.dtVencimentoBoleto), 1),
        "dd/MM/yyyy"
      ),
    };
  }

  return (
    <Fragment>
      <Container fluid>
        <Card>
          <Card.Body>
            {loading ?
              <Skeleton count={7} height={50} />
              :
              <>

                <Row className="py-3 border-bottom">
                  <Col>
                    <h3 className="pl-2 mb-0">
                      <strong>Meus Pedidos</strong>
                    </h3>
                  </Col>
                </Row>
                <Col className="mt-4">
                  <Row className="justify-content-space-between align-items-center">
                    <Col as="h6">Histórico</Col>
                    <Col as="h6">Data</Col>
                    <Col as="h6">Valor</Col>
                    <Col as="h6">Detalhes</Col>
                  </Row>
                </Col>

                <Col className="p-0" id="OrderCards">
                  {!!transactions && transactions.length === 0 ? <h5 className="d-flex justify-content-center text-center pt-3">Nenhum pedido realizado até o momento.</h5> : transactions.map((x, i) => (
                    <Card key={i} className="mt-2 OrderCards">
                      <Card.Body>
                        <Row style={{ cursor: x.transactionType === 1 && "pointer" }} onClick={() => { x.transactionType === 1 && setDetailOrder(x); x.transactionType === 1 && setShowDetailModal(true) }}>
                          <Col className="p-0 mx-1">
                            <span>
                              <strong>
                                {(x.paymentStatus === 10 && x.transactionType === 1 && pendingIcon)}
                                {(x.paymentStatus === 30 && x.transactionType === 1 ? inIcon : x.transactionType === 0 && outIcon)}
                              </strong>
                            </span>
                          </Col>
                          {/* <Col className="pl-sm-1 p-0"><span>{RenameMonth(x.creationDate)}</span></Col> */}
                          <Col className="pl-sm-1 p-0">
                            <span>
                              {
                                x.paymentStatus === 10 ?
                                  "Aguardando Pagamento" :

                                  format(new Date(x.paymentDate), `dd/MM/yy - HH:mm`, {
                                    locate: pt,
                                  })}
                            </span>
                          </Col>
                          <Col as="h6" className="p-0 text-right">
                            {formatPrice(x.paymentStatus === 10 ? x.value : x.paidValue)}
                          </Col>
                          <Col className="p-0 text-right">
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "between" }}>

                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: '0px' }} className="pe-2">
                                {(x.paymentMethodCode === 20 && barcodeIcon)}
                                {(x.paymentMethodCode === 50 && creditCardIcon)}
                              </div>
                              <small className="pr-sm-2">{x.description}</small>
                            </div>

                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  ))}
                </Col>
                <Col
                  id="pagination"
                  className={`col-12 mt-4 mb-5 justify-content-center ${totalItens <= itensPerPage ? "d-none" : "d-flex"
                    }`}
                >
                  <Col xs={10} sm={5} className="d-flex justify-content-center">
                    <PaginationComponent
                      itemClass="page-item"
                      linkClass="page-link"
                      innerClass="pagination mb-0"
                      activePage={activePage}
                      itemsCountPerPage={itensPerPage}
                      totalItemsCount={totalItens}
                      pageRangeDisplayed={5}
                      onChange={(e) => setActivePage(e)}
                    />
                  </Col>
                </Col>
              </>

            }
          </Card.Body>
        </Card>
      </Container>
      <Modal
        onHide={() => { setShowDetailModal(false); setSlipData(null) }}
        show={showDetailModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

            <div style={{ display: "flex", alignItems: "center", justifyContent: "between" }}>

              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: '0px' }} className="pe-2">
                {(detailOrder.paymentStatus === 10 && detailOrder.transactionType === 1 && pendingIcon)}
                {(detailOrder.paymentStatus === 30 && detailOrder.transactionType === 1 ? inIcon : detailOrder.transactionType === 0 && outIcon)}
              </div>
              Detalhes da transação
            </div>


          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert className="my-2" hidden={detailOrder.paymentStatus === 30} variant={"info"}>
            Pagamentos feitos por Boleto levam até 3 dias para serem aprovados!
          </Alert>
          <Table striped bordered hover>

            <tbody>
              <tr>
                <td style={{ fontWeight: "bold" }}>Descrição</td>
                <td>{detailOrder.description}</td>

              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Valor Pago</td>
                <td>{formatPrice(detailOrder.paidValue)}</td>

              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Valor</td>
                <td>{formatPrice(detailOrder.value)}</td>

              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}> {detailOrder?.paymentStatus === 10 ? "Data do Vencimento do Boleto" : 'Data do Pagamento'}</td>
                <td>
                  {
                    detailOrder.paymentStatus === 10 ?
                      format(new Date(detailOrder?.dueDate), `dd/MM/yyyy`, {
                        locate: pt,
                      }) :
                      !!detailOrder?.paymentDate ?
                        format(new Date(detailOrder?.paymentDate), `dd/MM/yy - HH:mm`, {
                          locate: pt,
                        }) : ""
                  }


                </td>

              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Método de Pagamento</td>
                <td>{detailOrder.paymentMethodCode === 20 ? "Boleto" : 'Cartão de Crédito'}</td>

              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Status do Pagamento</td>
                <td>{detailOrder.paymentStatus === 30 ? "Pagamento confirmado" : detailOrder.paymentStatus === 10 ? "Aguardando confirmação do pagamento" : "Carregando..."}</td>

              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>ID da Transação</td>
                <td>{detailOrder.transactionId}</td>
              </tr>
            </tbody>
          </Table>
          <Col hidden={detailOrder.paymentStatus === 30} style={{ display: "flex" }} className="justify-content-center">
            <Button onClick={() => setSlipData(BankSlipParse(detailOrder.pluginReceivedData))} className="BtnPrimary "><MD.MdVisibility size={20} className="me-1" />Vizualizar Boleto</Button>

          </Col>


        </Modal.Body>
        <Modal.Footer>
          {
            slipData !== null ?
              <Card>
                <BankSlipDisplay modal={false} data={slipData} />
              </Card>
              : null
          }
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default Transactions;
