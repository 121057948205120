import { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AddCardPolicyGroup from "../../components/AddCardPolicyGroup";
import GroupCard from "../../components/GroupCard";
import Sidebar from "../../components/SideBar";
import api from "../../services/api";

function PolicyGroup() {

  useEffect(() => {
    GetAllEntryGroup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [allEntryGroup, setAllEntryGroup] = useState([]);
  const tenant = useSelector((state) => state.user.profile.tenants[0]);
  const [loading, setLoading] = useState(true);

  const GetAllEntryGroup = async () => {
    try {
      const response = await api.get(`/AdminArea/GetAllEntryGroup/${tenant}`);

      const { status, data } = response;

      if (response.status === 200 && status) {
        /*  const acEntry = data;
        const pluginData = JSON.parse(acEntry.pluginData);
        setAllAcEntry(acEntry); */
        setAllEntryGroup(data.sort((a, b) => a.name.localeCompare(b.name)));
        setLoading(false)
        // setLoading(true);
      } else {
        toast.error(`Erro na solicitação!`);
      }
    } catch (error) {
      if (!!error.response && error.response.status !== 401) {
        toast.error("Erro na solicitação.. tente mais tarde");
      }
    }
  }

  return (
    <>

      <Container className="p-4" fluid="xxl">
        <Row>
          <Col className="col-auto">
            <Sidebar />
          </Col>
          <Col>
            <Row className="justify-content-between mt-5 mb-3">
              <Col className="col-auto">
                <h3>Política em grupo</h3>
              </Col>
            </Row>
            <Row>
              {!loading ?
                <>
                  <Col sm={12 / 2} xl={12 / 4}>

                    <AddCardPolicyGroup />
                  </Col>
                  {allEntryGroup.map((x, i) => (
                    <Col key={i} sm={12 / 2} xl={12 / 4}>

                      <GroupCard acEntryGroup={x} updateFunction={GetAllEntryGroup} />

                    </Col>
                  ))}
                </>
                :
                <Col className="d-flex justify-content-md-center mt-5">
                  <Spinner animation="border" />
                </Col>

              }
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default PolicyGroup;
