import React from "react";
import PropTypes from "prop-types";

import HeaderAdmin from "../../../components/HeaderAdmin";
import Footer from "../../../components/Footer";

function AdminLayout({ children }) {
  return (
    <>
      <HeaderAdmin />
      {children}
      {window.location.pathname !== "/Wallet" ? <Footer /> : ""}

    </>
  );
}

export default AdminLayout;

AdminLayout.prototype = {
  children: PropTypes.element.isRequired,
};
