import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Card, Col, Container, Image, Row, Spinner
} from "react-bootstrap";
import * as IO from "react-icons/io5";
import * as MD from "react-icons/md";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import PolicyGroupImg from "../../../assets/img/policy-group.svg";
import SideBar from "../../../components/SideBar";
import api from "../../../services/api";
import history from "../../../services/history";
import { PolicyGroupDataContext } from "../Context";

function PolicyGroupDetails(props) {
    const context = useContext(PolicyGroupDataContext)

    const tenant = useSelector((state) => state.user.profile.tenants[0]);
    const [loadingAllAcEntry, setLoadingAllAcEntry] = useState(false);
    const [loading, setLoading] = useState(false);
    const back = useHistory();
    const [allAcEntry, setAllAcEntry] = useState([
        {
            acEntryId: "",
            referenceCode: "",
            name: "",
        }
    ]);

    const [entryGroup, setEntryGroup] = useState({
        name: "",
        description: "",
        entryGroupPolicies: [],
        acEntryGroupEnrollments: []

    });

    useEffect(() => {
        if (!loading) {
            async function GetEntryGroup() {

                try {
                    const response = await api.get(`/AdminArea/GetEntryGroup/${props.match.params.id}`);
                    const { status, data } = response;

                    if (response.status === 200 && status) {
                        setEntryGroup(data);
                        setLoading(true);
                    } else {
                        toast.error(`Erro na solicitação!`);
                    }
                } catch (error) {
                    if (!!error.response && error.response.status !== 401) {
                        toast.error("Erro na solicitação.. tente mais tarde");
                    }
                }
            }
            GetEntryGroup()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (!loadingAllAcEntry) {
            async function GetAllAcEntry() {

                try {
                    const response = await api.get(`/AdminArea/GetAllAcEntry/${tenant}`);
                    const { status, data } = response;

                    if (response.status === 200 && status) {
                        setAllAcEntry(data);
                        setLoadingAllAcEntry(true);

                    } else {
                        toast.error(`Erro na solicitação!`);
                    }
                } catch (error) {
                    if (!!error.response && error.response.status !== 401) {
                        toast.error("Erro na solicitação.. tente mais tarde");
                    }
                }
            }
            GetAllAcEntry()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        let arr = allAcEntry
        entryGroup.acEntryGroupEnrollments.map((x) => {
            arr = arr.filter((item) => item.acEntryId !== x.acEntryId)
            return x
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingAllAcEntry, loading]);

    return (
        <>

            <Container className="p-4" fluid="xxl">
                <Row>
                    <Col className="col-auto">
                        <SideBar />
                    </Col>
                    <Col>
                        {loadingAllAcEntry ?

                            <Row className="d-flex align-items-center mt-5 mb-3">
                                <Col className="col-auto">
                                    <h3 className="d-flex align-items-center justify-content-center ">
                                        <IO.IoArrowBackCircle
                                            style={{ cursor: "pointer" }}
                                            size="35"
                                            className="me-4"
                                            onClick={() => back.goBack()}
                                        />
                                        Detalhes da política em grupo
                                    </h3>
                                </Col>
                                <Row>

                                    <Row align="end">
                                        <Col >
                                            <Button className="BtnPrimary btn-sm rounded-pill " onClick={() => { context.setData(entryGroup); history.push(`/PolicyGroupEdit/id:${props.match.params.id}`) }}> <MD.MdEdit className="me-1 mb-1" /> EDITAR</Button>
                                        </Col>
                                    </Row>
                                    <Col xs={12} md={5} >
                                        <Row>
                                            <Card style={{ backgroundColor: "#212529", color: "#f1f3f5" }}>
                                                <Card.Body>
                                                    <Row>
                                                        <Col md={1}>
                                                            <Image
                                                                src={PolicyGroupImg}
                                                                style={{ width: 65 }}
                                                            />

                                                        </Col>
                                                        <Col className="ps-5" md={"auto"}>
                                                            <Card.Title className="ps-3" as="small">IDENTIFICAÇÃO DO GRUPO</Card.Title>
                                                            <Card.Text title={entryGroup.name} className="ps-3 my-0 text">Nome: <strong>{entryGroup.name}</strong></Card.Text>
                                                            <Card.Text title={Object.keys(entryGroup.description).length !== 0 ? entryGroup.description : "Sem descrição"} className="ps-3 text">Descrição: {Object.keys(entryGroup.description).length !== 0 ? <strong>{entryGroup.description}</strong> : <em style={{ color: "#ffffff38" }}>Sem descrição</em>}</Card.Text>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="justify-content-between mb-3">
                                    <Container>
                                        <Row>
                                            <Col>
                                                <Card className="my-2">
                                                    <Card.Body className="px-0">
                                                        <Row className="pb-3 py-2 ms-3">
                                                            <Col md={7}>
                                                                <Card.Title as="h5">Políticas em Grupo</Card.Title>
                                                            </Col>
                                                        </Row>
                                                        <Row className="ps-3 p-2 border-terminal m-0">
                                                            <Col className="p-0 ps-5 mx-1 text-start" as="h6">
                                                                Nome
                                                            </Col>

                                                            <Col className=" text-center" as="h6" xs={6} md>
                                                                Chave Requerida
                                                            </Col>

                                                        </Row>
                                                        <Col className="terminal-card py-2 p-0">
                                                            {entryGroup.entryGroupPolicies.map((x) => (
                                                                <Card className="mt-2 py-2 text-center" style={{ borderRadius: "0.1px" }}>
                                                                    <Card.Body>
                                                                        <Row>
                                                                            <Col className="p-0 ps-5 mx-1 text-start">
                                                                                <strong> <span>
                                                                                    {x.name == null ? "..." : x.name}
                                                                                </span></strong>
                                                                            </Col>
                                                                            <Col className=" text-center">
                                                                                {x.requiredKey === "" ? "..." : x.requiredKey}
                                                                            </Col>

                                                                        </Row>
                                                                    </Card.Body>
                                                                </Card>
                                                            ))}


                                                        </Col>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Card>
                                                    <Card.Body className="px-0">
                                                        <Card.Title className="ps-3">Terminais Adicionandos</Card.Title>
                                                        <Row className="ps-3 p-2 border-terminal m-0">
                                                            <Col xs={3} md>
                                                                Nome
                                                            </Col>
                                                            <Col xs={6} md>
                                                                N.Serie
                                                            </Col>
                                                            <Col xs={3} md>
                                                                AcEntryId
                                                            </Col>
                                                        </Row>
                                                        <Col className="p-0 py-2 terminal-card" >
                                                            {
                                                                entryGroup.acEntryGroupEnrollments.map((x) => (

                                                                    <Card key={x.acEntry.acEntryId} style={{ borderRadius: "0.1px" }}>
                                                                        <Card.Body>
                                                                            <Row>
                                                                                <Col className="p-0 mx-1">
                                                                                    <strong>
                                                                                        {x.acEntry.name}
                                                                                    </strong>
                                                                                </Col>
                                                                                <Col className="pl-sm-1 p-0">
                                                                                    <span>{x.acEntry.referenceCode}</span>
                                                                                </Col>
                                                                                <Col className="pl-sm-1 p-0 text" title={x.acEntry.acEntryId}>
                                                                                    <span>{x.acEntry.acEntryId}</span>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Body>
                                                                    </Card>

                                                                ))
                                                            }


                                                        </Col>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>

                                    </Container>
                                </Row>
                            </Row> :
                            <>
                                <Container>

                                    <Row align="center" className="pt-5">
                                        <Col >
                                            <Spinner animation="border" />
                                        </Col>
                                    </Row>
                                </Container>

                            </>
                        }
                    </Col>
                </Row >
            </Container >
        </>
    );
}

export default PolicyGroupDetails;
