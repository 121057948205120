import { React, useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Card,
  Form,
  InputGroup,
  Image,
  Modal,
  FormControl
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import Switch from "react-switch";

import * as IO from "react-icons/io5";
import * as Io from "react-icons/io";
import * as MD from "react-icons/md";
import * as Bi from "react-icons/bi";
import * as FA from "react-icons/fa";

import "./styles.css";
import "react-datepicker/dist/react-datepicker.css";
import PolicyGroupImg from "../../../assets/img/policy-group.svg"

import history from "../../../services/history";

import { useSelector } from "react-redux";

import { format } from "date-fns";
import { pt } from "date-fns/esm/locale";
import api from "../../../services/api";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import { toast } from "react-toastify";

function Schedules(props) {
  const weekDays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
  const [scheduleType, setScheduleType] = useState("weekDays");

  const tenant = useSelector((state) => state.user.profile.tenants[0]);

  const [nomePolitica, setNomePolitica] = useState("");
  const [valorPolitica, setValorPolitica] = useState(0.0);
  const [nivelPolitica, setNivelPolitica] = useState(0);
  const [chavePolitica, setChavePolitica] = useState("");
  const [negarAcesso, setNegarAcesso] = useState(false);

  const [days, setDays] = useState([]);
  const [allDays, setAllDays] = useState(null);
  const [clear, setClear] = useState(null);

  const [sTime, setSTime] = useState(new Date());
  const [eTime, setETime] = useState(new Date());
  const startTime = format(new Date(sTime), "HH:mm:ss");
  const endTime = format(new Date(eTime), "HH:mm:ss");

  const [clockwise, setClockwise] = useState(true);
  const [antiClockwise, setAntiClockwise] = useState(true);

  const [startDate, setStartDate] = useState(new Date());
  const [schedules, setSchedules] = useState([]);
  const [entryGroupPolicies, setEntryGroupPolicies] = useState([]);
  const [minutesReAccess, setMinutesReAccess] = useState(null);
  const [allPolicyKey, setAllPolicyKey] = useState([]);
  const [policyGroupConfirmed, setPolicyGroupConfirmed] = useState(false);

  const [editGroupPolicy, setEditGroupPolicy] = useState(false)

  const [isEdit, setEdit] = useState(false)
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false)
  const [isImported, setImported] = useState(true)
  const [policyKeyId, setPolicyKeyId] = useState("")
  const [isLoaded, setLoaded] = useState(true)
  const [isDeleted, deleted] = useState(false)

  useEffect(() => {
    if (isLoaded) {
      allPolicyKey.map((x) => {
        if (chavePolitica === x.name) {
          if (!x.import) {
            setImported(false)
            setPolicyKeyId(x.policyKeyId)
          } else {
            setImported(true)
          }
        }
        return x
      })
    }
    if (!loading) {

      if (props.schedules.length > 0) {

        setEntryGroupPolicies(props.schedules)
      }
      setLoading(true)
      if (typeof props.edit.data != "undefined") {

        if (Object.keys(props.edit.data).length > 3) {

          setEntryGroupPolicies(props.schedules)
          setLoading(true)
        }
      }
    }
    if (!isEdit) {
      if (props.edit.status) {
        setEntryGroupPolicies(props.edit.schedules)
        var arr = props.edit.schedules
        var i = arr.indexOf(props.edit.data)
        if (i !== -1) {
          arr.splice(i, 1)
          setEntryGroupPolicies(entryGroupPolicies => ([...arr]));
        }
        EditGroupPolicy(props.edit.data)

      }
      setEdit(true)
    }
    setClear(null);
    setAllDays(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear, policyGroupConfirmed, chavePolitica]);

  useEffect(() => {
    async function GetAllPolicyKey() {
      try {
        const response = await api.get(`/AdminArea/GetAllPolicyKey/${tenant}`);
        const data = response.data;

        setAllPolicyKey(data);
      } catch (err) {
        console.log(err);
      }
    }
    GetAllPolicyKey()
  }, [tenant]);

  function SendInfo() {
    props.sched(entryGroupPolicies)
  }

  function AddSchedules() {
    if (scheduleType === "weekDays") {
      if (days.length !== 0) {

        days.map((x) => {
          let sched = {
            Clockwise: clockwise,
            Anticlockwise: antiClockwise,
            DayWeek: x === "Sáb" ? "SAB" : x.toUpperCase(),
            StartTime: startTime,
            EndTime: endTime,
            ApplyAnyOverrideDate: false,
            IsOverrideDateSchedule: false,
          };
          setSchedules((schedules) => [...schedules, sched]);
          return x
        });
        setDays([]);
      } else {
        toast.warning("Selecione pelo menos um dia!")
      }
    } else {
      const dateSchedule = format(new Date(startDate), "yyy-MM-dd", {
        locale: pt,
      });

      let sched = {
        Clockwise: clockwise,
        Anticlockwise: antiClockwise,
        DayWeek: null,
        StartTime: startTime,
        EndTime: endTime,
        ApplyAnyOverrideDate: false,
        IsOverrideDateSchedule: true,
        OverrideDate: {
          Name: nomePolitica,
          Date: dateSchedule,
        },
      };
      setSchedules((schedules) => [...schedules, sched]);
    }

    setClear(false);
  }

  // Selecionar Todos os Dias
  function AllDaysCheck() {
    if (!allDays) {
      setDays(weekDays.map((x) => x));
    } else {
      setDays([]);
    }
  }
  // Selecionar Dias Especificos
  function DaysManager(day) {
    if (days.find((x) => x === day)) {
      let arr = days.filter((y) => y !== day);
      setDays(arr);
    } else {
      setDays((days) => [...days, day]);
    }
  }

  function ConfirmPolicyGroup() {
    let data = {
      Name: nomePolitica,
      RequiredKey: chavePolitica,
      Value: valorPolitica,
      IsDeny: negarAcesso,
      PolicyLevel: nivelPolitica,
    };
    if (data.Name === "") {
      toast.warning("Preencha o nome da política!");
      return;
    }
    if (data.RequiredKey === "") {
      toast.warning("Escolha a chave da política!");
      return;
    }

    setPolicyGroupConfirmed(true);
  }

  function AddEntryGroupPolicies() {
    let data = {
      Name: nomePolitica,
      RequiredKey: chavePolitica,
      Value: valorPolitica,
      IsDeny: negarAcesso,
      PolicyLevel: nivelPolitica,
      EntryGroupPolicySecuritySchedules: schedules,
      MinutesReAccess: minutesReAccess
    };

    setEntryGroupPolicies((dataEntryGroup) => [...dataEntryGroup, data]);
    setPolicyGroupConfirmed(false);
    setSchedules([]);
    setNomePolitica("")
    setChavePolitica("")
    document.getElementById("Form").reset();
    setEditGroupPolicy(false)
  }

  function DeleteEntryGroupPolicy(policy) {

    var arr = entryGroupPolicies
    var i = arr.indexOf(policy)
    if (i !== -1) {
      arr.splice(i, 1)
      setEntryGroupPolicies(entryGroupPolicies => ([...arr]));
    }
  }

  function EditGroupPolicy(x) {

    let data = x

    setSchedules(data.EntryGroupPolicySecuritySchedules)
    setNomePolitica(data.Name)
    setChavePolitica(data.RequiredKey)
    setEditGroupPolicy(true)

    var arr = entryGroupPolicies
    var i = arr.indexOf(x)
    if (i !== -1) {
      arr.splice(i, 1)
      setEntryGroupPolicies(entryGroupPolicies => ([...arr]));
    }
  }

  function DeletePolicy(policy) {

    var arr = schedules
    var i = arr.indexOf(policy)
    if (i !== -1) {
      arr.splice(i, 1)
      setSchedules(schedules => ([...arr]));
    }
  }


  async function ConfirmDelAcEntryPolicy() {
    setLoaded(false)
    try {
      await api.delete(`/AdminArea/DeletePolicyKey/${policyKeyId}`);
      toast.success("Chave de política excluída com sucesso!")
      deleted(!isDeleted)
      setChavePolitica("")
      setImported(true)
      setLoaded(true)

    } catch (err) {
      console.log(err);
    }
    setShowModal(!showModal)
  }

  function CreatePolicyKey() {
    if (entryGroupPolicies.length > 0) {
      window.confirm("Suas Politicas serão perdidas, salve antes de continuar")
    } else {
      history.push(`/PolicyKey`)
    }
  }

  return (
    <>
      <Row id="section1">
        <Col sm={5}>
          <Row>
            <Card style={{ backgroundColor: "#212529", color: "#f1f3f5" }}>
              <Card.Body>
                <Row>
                  <Col>
                    <Image
                      fluid
                      src={PolicyGroupImg}
                      style={{ width: 65 }}
                    />
                  </Col>
                  <Col xs={9}>
                    <Card.Title as="small">IDENTIFICAÇÃO DO GRUPO</Card.Title>
                    <Card.Text>{props.ident.name}</Card.Text>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Row>
          <Row>
            <Card>
              <Form id="Form">
                <Card.Body className="my-2">
                  <Card.Title>{!editGroupPolicy ? "Adicionar" : "Editar"} Política em grupo</Card.Title>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <Bi.BiShieldQuarter size={20} />
                    </InputGroup.Text>
                    <Form.Control
                      disabled={policyGroupConfirmed}
                      id="Name"
                      autoFocus
                      placeholder="Nome da política"
                      aria-label="Nome da política"
                      aria-describedby="basic-addon1"
                      defaultValue={editGroupPolicy ? nomePolitica : ""}
                      onChange={(e) => setNomePolitica(e.target.value)}
                    />
                  </InputGroup>
                  <Row>
                    <Row>
                      <Col className="my-2">
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            <MD.MdOutlineAttachMoney size={20} />
                          </InputGroup.Text>
                          <Form.Control
                            disabled={policyGroupConfirmed}
                            placeholder="0.00"
                            defaultValue={0.0}
                            onChange={(e) => setValorPolitica(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                      <Col className="my-2">
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            <MD.MdOutlineSignalCellularAlt size={20} />
                          </InputGroup.Text>
                          <Form.Control
                            disabled={policyGroupConfirmed}
                            placeholder="(Nível da política)"
                            defaultValue={0}
                            type="number"
                            onChange={(e) =>
                              setNivelPolitica(Number(e.target.value))
                            }
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Col className="my-2">
                      <Row>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            <FA.FaKey size={18} />
                          </InputGroup.Text>
                          <Form.Group as={Col} controlId="formGridState">
                            <Form.Control
                              disabled={policyGroupConfirmed}
                              as="select"
                              onChange={(e) => setChavePolitica(e.target.value)}
                              defaultValue={editGroupPolicy ? chavePolitica : ""}
                            >
                              <option value="" disabled selected>
                                Chave de Política...
                              </option>
                              {!editGroupPolicy ? "" : <option selected={editGroupPolicy}
                                defaultValue={
                                  chavePolitica
                                }
                              >
                                {chavePolitica}
                              </option>}

                              {allPolicyKey.map((x, id) => {
                                return <option key={id} value={x.name}>{x.name}</option>;
                              })}
                            </Form.Control>
                          </Form.Group>
                          <InputGroup.Text hidden={isImported} id="basic-addon1">
                            <MD.MdDelete title="Excluir chave da política" style={{ cursor: "pointer" }} size={18} onClick={() => setShowModal(!showModal)} />
                          </InputGroup.Text>
                          <InputGroup.Text id="basic-addon1">
                            <MD.MdAddCircle
                              title="Criar chave de política"
                              size="20"
                              style={{ color: "#37b24d", cursor: "pointer" }}
                              onClick={() => CreatePolicyKey()/* history.push(`/PolicyKey`) */}
                            />
                          </InputGroup.Text>
                        </InputGroup>
                      </Row>
                    </Col>
                    <Row className="mt-2 d-flex justify-content-center align-items-center">
                      <Col className="col-10 ps-2 pe-0 ">
                        <IO.IoAlertCircleOutline size={25} />
                        <span className="ps-2">Negar acesso a catraca?</span>
                      </Col>
                      <Col className="col-2">
                        <Switch
                          disabled={policyGroupConfirmed}
                          checked={negarAcesso}
                          onChange={() => setNegarAcesso(!negarAcesso)}
                          className="react-switch"
                          height={20}
                          width={48}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          offColor={"#E03131"}
                          onColor={"#40C057"}
                        />
                      </Col>
                    </Row>
                    <Row >
                      <Col className="col-8 ps-2 pe-0 " >
                        <MD.MdLockClock size={25} />
                        <span className="ps-2">Minutos para reacesos</span>
                      </Col>
                      <Col className="col-4">
                        <Form>
                          <FormControl type="number" onChange={(e) => setMinutesReAccess(e.target.value)} />
                        </Form>
                      </Col>
                    </Row>
                    <Col className="pt-3" align="end">
                      <Button
                        hidden={policyGroupConfirmed || editGroupPolicy}
                        onClick={() => ConfirmPolicyGroup()}
                        className="BtnPrimary"
                      >
                        Confirmar
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Form>
            </Card>
          </Row>
        </Col>
        {policyGroupConfirmed || editGroupPolicy ? (
          <Col>
            <Card>
              <Card.Body className="my-2">
                <Card.Title className="">
                  Agendamento da Política de Segurança
                </Card.Title>
                <InputGroup className="mt-3">
                  <Form.Check
                    inline
                    label="Dias da semana"
                    name="group1"
                    type="radio"
                    id="allDays"
                    defaultChecked={scheduleType === "weekDays"}
                    onClick={() => setScheduleType("weekDays")}
                  />
                  <Form.Check
                    inline
                    label="Datas Fixa"
                    name="group1"
                    type="radio"
                    id="fixedDays"
                    defaultChecked={scheduleType === "fixedDays"}
                    onClick={() => setScheduleType("fixedDay")}
                  />
                </InputGroup>
                {scheduleType === "weekDays" ? (
                  <>
                    <Row className="mt-4">
                      <Col>
                        <InputGroup>
                          <Row>
                            <Row>
                              <Col className="ps-2">
                                <InputGroup.Text>
                                  <Io.IoMdCalendar size={25} />
                                  <span className="ps-1">
                                    <strong>
                                      Selecione os dias da Semana
                                    </strong>
                                  </span>
                                </InputGroup.Text>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Form.Check
                                  id="7"
                                  className="my-2"
                                  inline
                                  label="Todos os dias"
                                  type="checkbox"
                                  checked={clear}
                                  onChange={() => {
                                    setAllDays(!allDays);
                                    AllDaysCheck();
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="pe-0">
                                {weekDays.map((x, id) => (
                                  <Form.Check
                                    key={id}
                                    id={x}
                                    inline
                                    label={x}
                                    checked={allDays ? true : clear}
                                    disabled={allDays}
                                    onChange={() => {
                                      DaysManager(x);
                                    }}
                                  />
                                ))}
                              </Col>
                            </Row>
                          </Row>
                        </InputGroup>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Col className="mt-4">
                      <DatePicker
                        inline
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                    </Col>
                  </>
                )}

                <Row className="pt-3" xs={8}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Col>
                      <TimePicker
                        ampm={false}
                        openTo="hours"
                        views={["hours", "minutes", "seconds"]}
                        inputFormat="HH:mm:ss"
                        mask="__:__:__"
                        label="Hora início"
                        value={sTime}
                        onChange={(newValue) => {
                          setSTime(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Col>
                    <Col>
                      <TimePicker
                        ampm={false}
                        openTo="hours"
                        views={["hours", "minutes", "seconds"]}
                        inputFormat="HH:mm:ss"
                        mask="__:__:__"
                        label="Hora final"
                        value={eTime}
                        onChange={(newValue) => {
                          setETime(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Col>
                  </LocalizationProvider>
                </Row>

                <Row className="pt-4">
                  <span>
                    <strong>Sentido de Giro</strong>
                  </span>
                  <Col className="my-2">
                    <Form.Check
                      checked={clockwise}
                      inline
                      label="Horário"
                      onChange={(e) => setClockwise(e.target.checked)}
                    />
                    <Form.Check
                      inline
                      checked={antiClockwise}
                      label="Anti-Horário"
                      onChange={(e) => setAntiClockwise(e.target.checked)}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Button
                      onClick={() => AddSchedules()}
                      className="shadow-none mt-4 BtnPrimary"
                    >
                      ADICIONAR AGENDAMENTO
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ) : (
          ""
        )}
      </Row>
      {schedules.length > 0

        ?

        <Row>
          <Col>
            <Card className="my-2">
              <Card.Body className="px-0">
                <Row className="pb-3 py-2 ms-3">
                  <Col md={7}>
                    <Card.Title as="h5">Políticas Agendadas</Card.Title>
                  </Col>
                </Row>

                <Row className="ps-3 text-center p-2 border-terminal m-0">
                  <Col xs={2} md as="h6">
                    Dia da Semana
                  </Col>
                  <Col xs={2} md as="h6">
                    Data Fixa
                  </Col>
                  <Col xs={2} md as="h6">
                    Hora Início
                  </Col>
                  <Col xs={2} md as="h6">
                    Hora Final
                  </Col>
                  <Col xs={2} md as="h6">
                    Sentido do Giro
                  </Col>
                  <Col xs={2} md as="h6">
                    Ações
                  </Col>
                </Row>
                <Col className="py-2 p-0 terminal-card" >
                  {schedules.map((x, id) => (
                    <Card key={id} className="mt-2 py-2 text-center" style={{ borderRadius: "0.1px" }}>
                      <Card.Body>
                        <Row>
                          <Col className="pl-sm-1 p-0">
                            <span>{x.DayWeek == null ? "..." : x.DayWeek}</span>
                          </Col>
                          <Col className="pl-sm-1 p-0">
                            <span>
                              {typeof x.OverrideDate == "undefined"
                                ? "..."
                                : x.OverrideDate.Date}
                            </span>
                            {/* <span>...</span> */}
                          </Col>
                          <Col className="pl-sm-1 p-0">
                            <span>{x.StartTime}</span>
                          </Col>
                          <Col className="pl-sm-1 p-0">
                            <span>{x.EndTime}</span>
                          </Col>
                          <Col className="pl-sm-1 p-0">
                            {x.Clockwise ? <MD.MdRotateRight title="Horário" size={25} /> : ""}
                            {x.Anticlockwise ? <MD.MdRotateLeft title="Anti-Horário" size={25} /> : ""}
                          </Col>
                          <Col className="p-0 text-right">

                            <IO.IoTrash onClick={() => DeletePolicy(x)} style={{ cursor: "pointer" }} />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  ))}
                </Col>
                <Col className="pt-3 me-4" align="end">
                  <label>
                    {!editGroupPolicy ? "Adicionar" : "Salvar novos"}  agendamentos para a política "
                    <strong>{nomePolitica}</strong>"?
                  </label>
                  <a href="#section2">
                    <Button
                      onClick={() => AddEntryGroupPolicies()}
                      className="ms-2 BtnPrimary btn-sm"
                    >
                      {!editGroupPolicy ? "Adicionar" : "Salvar"}
                    </Button>
                  </a>
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        :

        ""
      }

      <Row>
        <Col>
          <Card className="my-2" hidden={entryGroupPolicies.length === 0 ? true : editGroupPolicy}>
            <Card.Body className="px-0">
              <Row className="pb-3 py-2 ms-3">
                <Col md={7}>
                  <Card.Title as="h5">Políticas em Grupo</Card.Title>
                </Col>
              </Row>
              <Row className="ps-3 p-2 border-terminal m-0">
                <Col as="h6">
                  Nome
                </Col>
                <Col className="text-center pe-1" as="h6">
                  Descrição
                </Col>
                <Col className="text-end pe-5" as="h6">
                  Ações
                </Col>

              </Row>
              <Col className="terminal-card py-2 p-0">
                {entryGroupPolicies.map((x, id) => (

                  <Card key={id + 1} className="mt-2 py-2 text-center" style={{ borderRadius: "0.1px" }}>
                    <Card.Body>
                      <Row>
                        <Col className="p-0 ps-5 mx-1 text-start">
                          <strong> <span>
                            {x.Name == null ? "..." : x.Name}
                          </span></strong>
                        </Col>
                        <Col className=" text-center  ">
                          {x.RequiredKey === "" ? "..." : x.RequiredKey}
                        </Col>
                        <Col className="p-0 pe-5 text-end">
                          <MD.MdModeEdit
                            onClick={() => { EditGroupPolicy(x) }}
                            className="me-4"
                            style={{ cursor: "pointer" }}
                          />
                          <IO.IoTrash onClick={() => DeleteEntryGroupPolicy(x)} style={{ cursor: "pointer" }} />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                )
                )}
              </Col>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Col align="start" className="mt-3">
        <a href="#section1">
          <Button hidden={schedules.length > 0 ? true : entryGroupPolicies.length <= 3 ? true : false} align="start" className="shadow-none BtnPrimary">
            Criar mais política
          </Button>
        </a>
      </Col>
      <Col id="section2" align="end" className="mt-3">
        <Button hidden={schedules.length > 0 ? true : entryGroupPolicies.length === 0 ? true : false} onClick={() => SendInfo()} className="shadow-none BtnPrimary">
          PROXIMO
        </Button>
      </Col>

      <Modal
        dialogClassName="border-radius-2"
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="sm"
        // animation={false}
        style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      >
        <Modal.Body>
          <Row className="justify-content-center text-center pb-3">
            <h5 className="my-3 pb-4 border-bottom">Excluir chave de política?</h5>

            <Button
              style={{ border: "none", backgroundColor: "var(--bg-red)" }}
              className="col-4 me-1" onClick={() => setShowModal(!showModal)}
            >Não</Button>
            <Button
              style={{ border: "none", backgroundColor: "var(--bg-green)" }}
              className="col-4 ms-1" onClick={() => ConfirmDelAcEntryPolicy()}
            >Sim</Button>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Schedules;
