import { React, useState, useEffect } from "react";
import Switch from "react-switch";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import api from "../../../services/api";
import {
  Col,
  Row,
  Button,
  Card,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";
import history from "../../../services/history";
import * as IO from "react-icons/io5";
import * as MD from "react-icons/md";
import * as FA from "react-icons/fa";
import * as Bi from "react-icons/bi";
import { toast } from "react-toastify";


function Identification(props) {
  const tenant = useSelector((state) => state.user.profile.tenants[0]);

  const [allPolicyKey, setAllPolicyKey] = useState([])

  const acEntryId = props.id.match.params.id;
  const [policyName, setPolicyName] = useState("");
  const [policyValue, setPolicyValue] = useState('');
  const [policyRequiredKey, setPolicyRequiredKey] = useState("");
  const [policyLevel, setPolicyLevel] = useState(0);
  const [policyIsDeny, setPolicyIsDeny] = useState(false);

  const [policyKeyId, setPolicyKeyId] = useState("")
  const [isImported, setImported] = useState(true)

  const [isLoaded, setLoaded] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [isDeleted, deleted] = useState(false)



  const { handleSubmit } = useForm();

  useEffect(() => {
    if (isLoaded) {
      allPolicyKey.map((x) => {
        if (policyRequiredKey === x.name) {
          if (!x.import) {
            setImported(false)
            setPolicyKeyId(x.policyKeyId)
          } else {
            setImported(true)
          }
        }
        return x
      })
    }
    GetAllPolicyKey()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyRequiredKey, isLoaded]);



  function FormatMoney(value, setX) {
    var v = value.replace(/\D/g, "");
    v = (v / 100).toFixed(2) + "";
    v = v.replace(".", ",");
    v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
    v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
    setX(`${v}`);
  }

  async function GetAllPolicyKey() {
    try {
      const response = await api.get(`/AdminArea/GetAllPolicyKey/${tenant}`);
      const data = response.data;

      setAllPolicyKey(data);
    } catch (err) {
      console.log(err);
    }
  }

  function OnSubmit() {
    props.t(acEntryId, policyName, policyValue === '' ? null : policyValue, policyRequiredKey, policyLevel, policyIsDeny)
  }


  async function ConfirmDelAcEntryPolicy() {
    setLoaded(false)
    try {
      await api.delete(`/AdminArea/DeletePolicyKey/${policyKeyId}`);
      toast.success("Chave de política excluída com sucesso!")
      deleted(!isDeleted)
      setPolicyRequiredKey("")
      setImported(true)
      setLoaded(true)

    } catch (err) {
      console.log(err);
    }
    setShowModal(!showModal)
  }
  return (
    <>

      <Card>
        <Card.Body className="my-2">
          <Form onSubmit={handleSubmit(OnSubmit)}>
            <Card.Title>Identificação</Card.Title>
            <InputGroup title="Nome da política" className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <Bi.BiShieldQuarter size={20} />
              </InputGroup.Text>
              <Form.Control
                autoFocus
                required
                onChange={(e) => setPolicyName(e.target.value)}
                placeholder="Nome da política"
                aria-label="Nome da política"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
            <Row>
              <Col className="my-2">
                <InputGroup title="Valor" className="mb-3">
                  <InputGroup.Text id="basic-addon1">

                    <MD.MdOutlineAttachMoney size={20} />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="0,00"
                    onChange={(e) => FormatMoney(e.target.value, setPolicyValue)}
                    value={policyValue}
                  />
                </InputGroup>
              </Col>
              <Col className="my-2">
                <InputGroup title="Nível da política" className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <MD.MdOutlineSignalCellularAlt size={20} />
                  </InputGroup.Text>
                  <Form.Control defaultValue={0} required type="number" placeholder="(Nível da política)"
                    onChange={(e) => setPolicyLevel(Number(e.target.value))}
                  />
                </InputGroup>
              </Col>
              <Col className="my-2">
                <InputGroup title="Chave de política" className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <FA.FaKey size={18} />
                  </InputGroup.Text>
                  <Form.Group required as={Col} controlId="formGridState">
                    <Form.Control as="select"
                      required
                      onChange={(e) => { setPolicyRequiredKey(e.target.value); }}
                      defaultValue={''}

                    >
                      <option selected={isLoaded} disabled value=""  >Chave de Política...</option>
                      {allPolicyKey.map((x, y) =>
                      (
                        <option key={y} value={x.name}>{x.name}</option>
                      )


                      )}
                    </Form.Control>
                  </Form.Group>
                  <InputGroup.Text hidden={isImported} id="basic-addon1">
                    <MD.MdDelete title="Excluir chave da política" style={{ cursor: "pointer" }} size={18} onClick={() => setShowModal(!showModal)} />
                  </InputGroup.Text>
                  <InputGroup.Text id="basic-addon1">
                    <MD.MdAddCircle
                      title="Criar chave de política"
                      size="20"
                      style={{ color: "#37b24d", cursor: "pointer" }}
                      onClick={() => history.push(`/PolicyKey`)}
                    />
                  </InputGroup.Text>

                </InputGroup>
              </Col>
              <Row className="mt-2">
                <Col onClick={() => setPolicyIsDeny(!policyIsDeny)} title="Negar acesso">
                  <IO.IoAlertCircleOutline size={25} />
                  <span className="px-2">Negar acesso a catraca?</span>
                </Col>
                <Col title="Negar acesso" className="mt-1">
                  <Switch
                    checked={policyIsDeny}
                    onChange={() => setPolicyIsDeny(!policyIsDeny)}
                    className="react-switch"
                    height={20}
                    width={48}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  />
                </Col>
                <Col align="end">
                  <Button className="shadow-none BtnPrimary" type="submit" /* onClick={() => props.t(acEntryId, policyName, policyValue, policyRequiredKey, policyLevel, policyIsDeny)} */>

                    {" "}
                    Próximo
                  </Button>
                </Col>
              </Row>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      <Modal
        dialogClassName="border-radius-2"
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="sm"
        // animation={false}
        style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      >
        <Modal.Body>
          <Row className="justify-content-center text-center pb-3">
            <h5 className="my-3 pb-4 border-bottom">Excluir chave de política?</h5>

            <Button
              style={{ border: "none", backgroundColor: "var(--bg-red)" }}
              className="col-4 me-1" onClick={() => setShowModal(!showModal)}
            >Não</Button>
            <Button
              style={{ border: "none", backgroundColor: "var(--bg-green)" }}
              className="col-4 ms-1" onClick={() => ConfirmDelAcEntryPolicy()}
            >Sim</Button>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Identification;
