import { format, addMonths, addDays } from "date-fns";
import React, { useEffect, useState } from "react";
import {
  Alert, Button, Card, Col, Container, Form,
  Modal, Row
} from "react-bootstrap";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { Controller, useForm } from "react-hook-form";
import * as MD from "react-icons/md";
import * as RI from "react-icons/ri";
import MaskedInput from "react-text-mask";
import { toast } from "react-toastify";
import api from "../../services/api";
import history from "../../services/history";
import BankSlipDisplay from "../../components/BankSlipDisplay"
import "./styles.css";


function Payment() {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { errors }, control } = useForm(); //watch, errors
  const pdfRef = React.createRef();
  const [messageError, setMessageError] = useState("");
  const [cvc, setCvc] = useState("");
  const [expiry, setExpiry] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [focus, setFocus] = useState("");
  const [modalPaymentError, setModalPaymentError] = useState(false);
  const [modalPayment, setModalPayment] = useState(false);
  const [typePayment, setTypePayment] = useState(null);
  const [maxInstallment, setMaxInstallment] = useState([0]);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [donorValue, setDonorValue] = useState("");
  const [slipData, setSlipData] = useState(null);
  const [minValue, setMinValue] = useState();
  const [value, setValue] = useState(0);
  useEffect(() => {
    GetUserCheckoutPage();
  }, []);

  useEffect(() => {
    if (paymentMethod.length > 0) {

      if (!!paymentMethod && typePayment === "CA") {
        const value = paymentMethod.find(x => x.paymentMethodCode === 50).minValue
        setMinValue((value).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }))

      } else if (!!paymentMethod && typePayment === "BO") {
        const value = paymentMethod.find(x => x.paymentMethodCode === 20).minValue
        setMinValue((value).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }))
      }
    }
  }, [paymentMethod, typePayment]);

  useEffect(() => {
    if (typePayment === "CA") {

      if (value < 200) {
        setMaxInstallment([0])
      } else {
        GetUserCheckoutPage()
      }
    }
  }, [typePayment === "CA" && value]);

  async function GetUserCheckoutPage() {
    try {
      const response = await api.get(`/Transaction/PaymentMethods`);

      const { status, data } = response;
      if (status === 200 && data.length !== 0) {

        let ca = false;
        let bo = false;
        const payMet = response.data.entity;

        // setPaymentMethod(response.data.entity);
        payMet.forEach((element) => {
          if (element.paymentMethodCode === 50) {
            ca = true;
            let installment = [];
            for (let index = 0; index < element.maxInstallment; index++) {
              installment.push(index);
            }
            setMaxInstallment(installment);
          }
          if (element.paymentMethodCode === 20) {
            bo = true;
          }
        });

        if (ca === true) {
          var re = payMet.find(x => x.paymentMethodCode === 50)
          setTypePayment("CA");


        } else if (bo === true) {
          setTypePayment("BO");


        }
        setPaymentMethod(payMet);
      } else {
        toast.warning("Nenhum método de pagamento foi selecionado!");
      }
    } catch (error) {
      if (!!error.response && error.response.status !== 401) {
        toast.error("Erro na solicitação.. tente mais tarde");
      } else {
        toast.error(error);
      }
    }
  }



  async function OnSubmit(form) {
    setLoading(true);
    document.body.style.cursor = "wait";
    if (typePayment === "CA") {

      try {
        let dateExpiry = expiry.trim().split("/");

        dateExpiry[1] =
          Number(dateExpiry[1]) < 2000
            ? Number(dateExpiry[1]) + 2000
            : Number(dateExpiry[1]);
        const newDateExpiry = dateExpiry.join("/");
        const jsonData = {
          PaymentMethodCode: 50,
          paymentMethodParameters: JSON.stringify({
            /*  isSignature: false, */ // typeDonation === "mensal" ? true : false,
            /*  signatureInterval: "", */ // typeDonation === "mensal" ? "Monthly" : "",
            /*  signatureEndDate: "", */ //
            card_number: number.trim().replace(/\s/g, ""),
            full_name: name.trim(),
            validate: newDateExpiry,
            secure_code: cvc.trim(),
            recurrent: false,
            installment: form.installment,
            country: "BRA",
          }),
          currencyCode: "BRL",
          value: Number((donorValue.replace(/\D/g, "") / 100).toFixed(2) + ""),
          //value: (donorValue.replace(/\D/g, '')).slice(0, -2), //VALOR DOADO AQUI
        };

        const response = await api.post(`/Transaction/Payment`, jsonData);
        const data = response.data;
        const { status, message } = data;
        if (status) {

          setModalPayment(true);
        } else if (!status) {
          setMessageError(message)

          setModalPaymentError(true);

        } else {
        }
      } catch (error) {
        if (!!error.response && error.response.status !== 401) {
          toast.error("Erro na solicitação.. tente mais tarde");
        }
      }
    } else {
      try {
        const value = Number((donorValue.replace(/\D/g, "") / 100).toFixed(2) + "")
        const cepSplit = form.slipCEP.trim().split("-");
        const jsonData = {
          PaymentMethodCode: 20,
          value: Number((donorValue.replace(/\D/g, "") / 100).toFixed(2) + ""),
          PaymentMethodParameters: JSON.stringify({
            dtEmissaoTitulo: format(Date.now(), "dd.MM.yyyy"),
            dtVencimentoTitulo: format(addDays(Date.now(), 4), "dd.MM.yyyy"),
            tpVencimento: "0",
            vlNominalTitulo: parseInt(Number(value) * 100),
            nomePagador: form.slipName.trim().replace(/\s{2,}/g, " "),
            logradouroPagador: form.slipPublicPlace.trim().replace(/\s{2,}/g, " "),
            nuLogradouroPagador: form.slipNumber.trim(),
            complementoLogradouroPagador: form.slipComplement.trim().replace(/\s{2,}/g, " "),
            cepPagador: cepSplit[0],
            complementoCepPagador: cepSplit[1],
            bairroPagador: form.slipNeighborhood.trim().replace(/\s{2,}/g, " "),
            municipioPagador: form.slipCity.trim().replace(/\s{2,}/g, " "),
            ufPagador: form.slipState.trim(),
            cdIndCpfcnpjPagador: "1",
            nuCpfcnpjPagador: form.slipCPF.trim().replace(/[^0-9]/g, ""),
            cdEspecieTitulo: "04",
            tpProtestoAutomaticoNegativacao: "0",
            prazoProtestoAutomaticoNegativacao: "0",
            controleParticipante: "",
            cdPagamentoParcial: "",
            qtdePagamentoParcial: "0",
            percentualJuros: "0",
            vlJuros: "0",
            qtdeDiasJuros: "0",
            percentualMulta: "0",
            vlMulta: "0",
            qtdeDiasMulta: "0",
            percentualDesconto1: "0",
            vlDesconto1: "0",
            dataLimiteDesconto1: "",
            percentualDesconto2: "0",
            vlDesconto2: "0",
            dataLimiteDesconto2: "",
            percentualDesconto3: "0",
            vlDesconto3: "0",
            dataLimiteDesconto3: "",
            prazoBonificacao: "0",
            percentualBonificacao: "0",
            vlBonificacao: "0",
            dtLimiteBonificacao: "",
            vlAbatimento: "0",
            vlIOF: "0",
            endEletronicoPagador: "",
            nomeSacadorAvalista: "",
            logradouroSacadorAvalista: "",
            nuLogradouroSacadorAvalista: "0",
            complementoLogradouroSacadorAvalista: "",
            cepSacadorAvalista: "0",
            complementoCepSacadorAvalista: "0",
            bairroSacadorAvalista: "",
            municipioSacadorAvalista: "",
            ufSacadorAvalista: "",
            cdIndCpfcnpjSacadorAvalista: "0",
            nuCpfcnpjSacadorAvalista: "0",
            endEletronicoSacadorAvalista: "",
          }),
        };

        const response = await api.post(`/Transaction/Payment`, jsonData);
        const data = response.data;
        const { status, message } = data;
        if (response.status === 200 && status) {
          setSlipData(await BankSlipParse(data.entity.pluginReceivedData));
          toast.success("Boleto gerado com sucesso!");
        } else {
          setMessageError(message)
          setModalPaymentError(true);
        }

      } catch (error) {
        if ((!!error.response && error.response.status !== 401)) {
          toast.error("Erro na solicitação.. tente mais tarde");
        }
      }
    }
    setLoading(false);
    document.body.style.cursor = "default";
  }

  function BankSlipParse(data) {
    const responsePay = JSON.parse(data);
    const entity = {
      nuTituloGerado: responsePay.nuTituloGerado,
      //nuCnpjBeneficiario: responsePay.nuCnpjBeneficiario,
      nomeBeneficiario: responsePay.nomeBeneficiario,
      logradouroBeneficiario: responsePay.logradouroBeneficiario,
      nuLogradouroBeneficiario: responsePay.nuLogradouroBeneficiario,
      complementoLogradouroBeneficiario:
        responsePay.complementoLogradouroBeneficiario,
      cepBeneficiario: `${responsePay.cepBeneficiario}-${responsePay.cepComplementoBeneficiario}`,
      municipioBeneficiario: responsePay.municipioBeneficiario,
      ufBeneficiario: responsePay.ufBeneficiario,
      nomePagador: responsePay.nomePagador,
      cpfcnpjPagador: `${responsePay.cpfcnpjPagador.substr(
        0,
        9
      )}${responsePay.cpfcnpjPagador.substr(13, 2)}`,
      enderecoPagador: responsePay.enderecoPagador,
      bairroPagador: responsePay.bairroPagador,
      municipioPagador: responsePay.municipioPagador,
      ufPagador: responsePay.ufPagador,
      cepPagador: `${responsePay.cepPagador}-${responsePay.cepComplementoPagador}`,
      dtEmissao: explodeDate(responsePay.dtEmissao, 2),
      dtVencimentoBoleto: explodeDate(responsePay.dtVencimentoBoleto, 1),
      dtLimitePagamentoBoleto: explodeDate(
        responsePay.dtLimitePagamentoBoleto,
        1
      ),
      vlTitulo: responsePay.vlTitulo,
      cdBarras: responsePay.cdBarras,
      linhaDigitavel: responsePay.linhaDigitavel,
    };

    /* cdBarras: responsePay.linhaDigitavel != "99999.99999 99999.999999 99999.999999 9 99999999999999" ? responsePay.cdBarras : "23796822800000005003368160104000000300052200", */

    return {
      ...entity,
      priceFormatted: formatPrice(parseInt(entity.vlTitulo) / 100),
      dtEmissao: format(addDays(new Date(entity.dtEmissao), 1), "dd/MM/yyyy"),
      dtLimitePagamentoBoleto: format(
        addDays(new Date(entity.dtLimitePagamentoBoleto), 1),
        "dd/MM/yyyy"
      ),
      dtVencimentoBoleto: format(
        addDays(new Date(entity.dtVencimentoBoleto), 1),
        "dd/MM/yyyy"
      ),
    };
  }

  function explodeDate(data, type) {
    if (type == 1) {
      //const dt = data.split(".");
      return `${data.substr(6, 4)}-${data.substr(3, 2)}-${data.substr(0, 2)}`;
    } else if (type == 2) {
      return `${data.substr(4, 4)}-${data.substr(2, 2)}-${data.substr(0, 2)}`;
    }
  }

  const { format: formatPrice } = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  });


  function FormatMoney(value, setX) {
    setValue(parseInt(value.replace(".", "").replace(",", "").slice(3)) / 100)
    var v = value.replace(/\D/g, "");
    v = (v / 100).toFixed(2) + "";
    v = v.replace(".", ",");
    v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
    v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
    setX(`R$ ${v}`);
  }

  return (
    <>
      <Container className="p-4" fluid="xxl">
        <Row className="justify-content-md-center">
          <Card className="my-5" style={{ width: "35rem" }}>
            <Card.Body>
              <div className="close">
                <MD.MdClose
                  title="Voltar para página principal."
                  size={30}
                  onClick={() => {
                    history.push("/");
                  }}
                />
              </div>

              <Form onSubmit={handleSubmit(OnSubmit)}>
                <Row className="mx-1 mt-4">
                  <Col>
                    <h4>Métodos de pagamentos</h4>
                  </Col>
                </Row>
                {paymentMethod.map((x) => {
                  if (x.paymentMethodCode === 50) {
                    return (
                      <>
                        {
                          x.active ?
                            <Row className="mt-2 mx-1">
                              <Col className="col-8">
                                <Form.Group>
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">
                                      <Form.Check
                                        type="radio"
                                        label="Cartão de Credito"
                                        checked={typePayment === "CA" ? true : false}
                                        name="typePayment"
                                        id="creditCard"
                                        value="CA"
                                        onClick={(e) =>
                                          setTypePayment(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>
                            : ""
                        }
                      </>
                    );
                  }
                  if (x.paymentMethodCode === 20) {
                    return (
                      <>
                        {x.active ?
                          <Row className="mt-1 mx-1">
                            <Col className="col-8">
                              <Form.Group>
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <Form.Check
                                      type="radio"
                                      label="Boleto Bancário"
                                      checked={typePayment === "BO" ? true : false}
                                      name="typePayment"
                                      id="bankSlip"
                                      value="BO"
                                      onClick={(e) =>
                                        setTypePayment(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </Form.Group>
                            </Col>
                          </Row>
                          : ""}
                      </>
                    );
                  }
                  return x

                })}
                {typePayment === "CA" ? (
                  <Row>
                    <Form.Group className="mt-4 mb-4">
                      <Cards
                        cvc={cvc}
                        expiry={expiry}
                        name={name}
                        number={number}
                        focused={focus}
                      />
                    </Form.Group>
                    <Form.Group className="mb-4 mx-1">
                      <Form.Label className="active" htmlFor="donorValue">
                        Valor
                      </Form.Label>
                      <Form.Control
                        title="Digite o valor."
                        type="text"
                        placeholder="Digite o valor"
                        id="donorValue"
                        value={donorValue}
                        onChange={(e) => {
                          FormatMoney(e.target.value, setDonorValue)
                        }
                        }
                        className="form-control"
                        required
                        disabled={loading}
                      />
                      <Form.Text muted>
                        Valor mínimo de {minValue}

                      </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-4 mx-1">
                      <Form.Label className="active" htmlFor="number">
                        Número do cartão
                      </Form.Label>
                      <MaskedInput
                        mask={[
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          " ",
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          " ",
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          " ",
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                        ]}
                        title="XXXX-XXXX-XXXX-XXXX"
                        guide={false}
                        placeholder="Digite o número do cartão"
                        type="tel"
                        id="number"
                        name="number"
                        className="form-control"
                        required
                        onFocus={(e) => setFocus(e.target.name)}
                        onChange={(e) => setNumber(e.target.value)}
                        disabled={loading}
                      />
                    </Form.Group>
                    <Form.Group className="mb-4 mx-1">
                      <Form.Label htmlFor="name" className="active">
                        Nome do titular do cartão
                      </Form.Label>
                      <Form.Control
                        title="Digite o nome do titular do cartão (igual ao nome no cartão)."
                        type="text"
                        placeholder="Digite o nome do titular do cartão"
                        id="name"
                        name="name"
                        className="form-control"
                        required
                        onChange={(e) => setName(e.target.value)}
                        onFocus={(e) => setFocus(e.target.name)}
                        disabled={loading}
                      />
                    </Form.Group>
                    <Row className="mb-3">
                      <Form.Group as={Col} className="mb-4 mx-1">
                        <Form.Label htmlFor="expiry" className="active">
                          Data de validade
                        </Form.Label>
                        <MaskedInput
                          mask={[
                            /[0-9]/,
                            /[0-9]/,
                            "/",
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                          ]}
                          title="MM/AAAA"
                          guide={false}
                          type="tel"
                          placeholder="__/____"
                          id="expiry"
                          name="expiry"
                          className="form-control"
                          required
                          onFocus={(e) => setFocus(e.target.name)}
                          onChange={(e) => setExpiry(e.target.value)}
                          disabled={loading}
                        />
                      </Form.Group>

                      <Form.Group as={Col} className="mb-4 mx-1">
                        <Form.Label htmlFor="cvc" className="active">
                          CVC
                        </Form.Label>
                        <MaskedInput
                          title="CVC - Digite os três numeros que estão atrás do seu cartão"
                          mask={[/[0-9]/, /[0-9]/, /[0-9]/]}
                          guide={false}
                          type="tel"
                          placeholder="Número atrás do cartão"
                          id="cvc"
                          name="cvc"
                          className="form-control"
                          required
                          onFocus={(e) => setFocus(e.target.name)}
                          onChange={(e) => setCvc(e.target.value)}
                          disabled={loading}
                        />
                      </Form.Group>
                    </Row>

                    <Form.Label htmlFor="installment">
                      Forma de pagamento
                    </Form.Label>

                    <select
                      title="Escolha a quantiadade de parcelas."
                      disabled={loading}
                      type="Number"
                      id="installment"
                      className="form-control"
                      {...register("installment", { required: false })}
                    >
                      {maxInstallment.map((x) => {
                        return <option value={x + 1}>{`${x + 1}x`}</option>;
                      })}
                    </select>
                  </Row>
                ) :
                  typePayment === "BO" ?
                    <>
                      <div>
                        <Row className="justify-content-center mt-4 mb-4 mx-1">


                          <Alert variant={"info"}>
                            Pagamentos feitos por Boleto levam até 3 dias para serem aprovados!
                          </Alert>
                          <Form.Group >
                            <Form.Label className="active" htmlFor="donorValue">
                              Valor
                            </Form.Label>
                            <Form.Control
                              title="Digite o valor."
                              type="text"
                              placeholder="Digite o valor"
                              id="donorValue"
                              value={donorValue}
                              onChange={(e) =>
                                FormatMoney(e.target.value, setDonorValue)
                              }
                              className="form-control"
                              required
                              disabled={loading}
                            />
                            <Form.Text muted>
                              Valor mínimo de {minValue}

                            </Form.Text>
                          </Form.Group>
                        </Row>
                        <Row className="mt-4 mb-4 mx-1">
                          <Col className="col-8">
                            <label htmlFor="slipName">Nome</label>
                            <input type="text" className="form-control" placeholder="Nome" required id="slipName" name="slipName" maxLength={40} {...register("slipName")} />
                          </Col>
                          <Col className="col-4 ps-0">
                            <label htmlFor="slipCPF">CPF</label>
                            <Controller
                              name="slipCPF"
                              control={control}
                              defaultValue={false}
                              rules={{ required: true }}

                              render={({ field }) => <MaskedInput mask={[/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]}
                                guide={false} type="tel" className="form-control " placeholder="CPF" id="slipCPF" required minLength={14} onChange={(e) => field.onChange(e.target.value)} />}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-4 mx-1">
                          <Col className="col-9">
                            <label htmlFor="slipPublicPlace">Logradouro</label>
                            <input type="text" className="form-control " placeholder="Logradouro" required id="slipPublicPlace" maxLength={40} name="slipPublicPlace" {...register("slipPublicPlace")} />
                          </Col>
                          <Col className="col-3 ps-0">
                            <label htmlFor="slipNumber">Número</label>
                            <input type="number" className="form-control" placeholder="Número" required id="slipNumber" name="slipNumber"{...register("slipNumber")} />
                          </Col>
                        </Row>
                        <Row className="mb-4 mx-1">
                          <Col className="col-8">
                            <label htmlFor="slipComplement">Complemento</label>
                            <input type="text" className="form-control " placeholder="Complemento" id="slipComplement" name="slipComplement" maxLength={15}{...register("slipComplement")} />
                          </Col>
                          <Col className="col-4 ps-0">
                            <label htmlFor="slipCEP">CEP</label>
                            <Controller
                              name="slipCEP"
                              control={control}
                              defaultValue={false}
                              rules={{ required: true }}
                              render={({ field }) => <MaskedInput mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/]}
                                guide={false} type="tel" className="form-control " placeholder="CEP" id="slipCEP" onChange={(e) => field.onChange(e.target.value)} />}

                            />

                          </Col>
                        </Row>
                        <Row className="mb-4 mx-1">
                          <Col className="col-5">
                            <label htmlFor="slipNeighborhood">Bairro</label>
                            <input type="text" className="form-control" placeholder="Bairro" required id="slipNeighborhood" name="slipNeighborhood" maxLength={30}{...register("slipNeighborhood")} />
                          </Col>
                          <Col className="col-4 px-0">
                            <label htmlFor="slipCity">Cidade</label>
                            <input type="text" className="form-control" placeholder="Cidade" required id="slipCity" name="slipCity" maxLength={20} {...register("slipCity")} />
                          </Col>
                          <Col className="col-3">
                            <label htmlFor="slipState">Estado</label>
                            <select className="form-control" id="slipState" placeholder="UF" name="slipState" required {...register("slipState")}>
                              <option value="">UF</option>
                              <option value="AC">AC</option>
                              <option value="AL">AL</option>
                              <option value="AP">AP</option>
                              <option value="AM">AM</option>
                              <option value="BA">BA</option>
                              <option value="CE">CE</option>
                              <option value="DF">DF</option>
                              <option value="ES">ES</option>
                              <option value="GO">GO</option>
                              <option value="MA">MA</option>
                              <option value="MT">MT</option>
                              <option value="MS">MS</option>
                              <option value="MG">MG</option>
                              <option value="PA">PA</option>
                              <option value="PB">PB</option>
                              <option value="pe">pe</option>
                              <option value="PE">PE</option>
                              <option value="PI">PI</option>
                              <option value="RJ">RJ</option>
                              <option value="RN">RN</option>
                              <option value="RS">RS</option>
                              <option value="RO">RO</option>
                              <option value="RR">RR</option>
                              <option value="SC">SC</option>
                              <option value="SP">SP</option>
                              <option value="SE">SE</option>
                              <option value="TO">TO</option>
                            </select>
                          </Col>
                        </Row>

                      </div>
                    </>
                    : null

                }
                <Form.Group>
                  <Row className="justify-content-center">
                    <Col className="col-12 text-center mt-3">
                      <Button
                        className="BtnPrimary"
                        title="Clique para confirmar seu pagamento."
                        type={loading ? null : "submit"}
                      >
                        {loading ? "Carregando..." : typePayment === "CA" ? "Efetuar Pagamento" : "Gerar Boleto"}
                      </Button>
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Row>
        {
          slipData !== null ?
            <Card ref={pdfRef} >

              <BankSlipDisplay data={slipData} />
            </Card>
            : null
        }

        <Modal
          centered
          className="mt-5 pt-3"
          size="lg"
          show={modalPayment}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header className="modal-content-sucess justify-content-center">
            <Modal.Title
              className="text-center"
              id="example-modal-sizes-title-lg"
            >
              <RI.RiMoneyDollarCircleLine size={30} />
              Pagamento realizado com sucesso!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-content-sucess">
            <Row className="justify-content-center">
              <Alert variant="success">
                <Alert.Heading>
                  Seu pagamento no valor de {donorValue} foi realizado com
                  sucesso!
                </Alert.Heading>
              </Alert>
              <Col className="col-3 text-center mt-3">
                <Button
                  type="button"
                  variant="success"
                  block
                  onClick={() => {
                    setModalPayment(false);
                    history.push("/");
                  }}
                >
                  OK
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          centered
          size="lg"
          show={modalPaymentError}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header
            className="justify-content-center modal-content-error"
          >
            <Modal.Title
              className="text-center"
              id="example-modal-sizes-title-lg"
            >
              <RI.RiEmotionSadLine size={30} />
              Pagamento não realizado!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-content-error">
            <Row className="justify-content-center">
              <Alert
                variant="danger"
                className="d-flex justify-content-between"
              >
                <Alert.Heading variant="danger">
                  {messageError}
                </Alert.Heading>
              </Alert>
              <Col className="col-3 text-center mt-3">
                <Button
                  className="shadow-none"
                  variant="danger"
                  onClick={() => {
                    setModalPaymentError(false);
                  }}
                >
                  OK
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
}
export default Payment;
