import React from "react";
import { Switch } from "react-router-dom";
import HomeScreen from "../pages/HomeScreen";
import PolicyGroup from "../pages/PolicyGroup";
import SignIn from "../pages/SignIn";
import UserManagement from "../pages/AdminArea/UserManagement";
import CustomerArea from "../pages/CustomerArea";
import Logout from "../pages/Logout";
import Wallet from "../pages/Wallet";
import Route from "./Route";
import Payment from "../pages/Payment";
import PasswordRecover from "../pages/PasswordRecover";
import TerminalPolicy from "../components/TerminalPolicy";
import AddPolicy from "../components/AddPolicy";
import EditPolicy from "../components/EditPolicy";
import AddGroup from "../components/AddGroup";
import AddTerminal from "../pages/AddTerminal/";
import PolicyKey from "../components/PolicyKey";
import PolicyGroupDetails from "../pages/PolicyGroup/PolicyGroupDetails";
import PolicyGroupEdit from "../pages/PolicyGroup/PolicyGroupEdit";

function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route
        path="/HomeScreen"
        exact
        component={HomeScreen}
        isPrivate
        verifiedEmail
      />
      <Route path="/PolicyGroup" exact component={PolicyGroup} isPrivate />
      <Route path="/PolicyGroupDetails/id::id" exact component={PolicyGroupDetails} isPrivate />
      <Route path="/PolicyGroupEdit/id::id" exact component={PolicyGroupEdit} isPrivate />
      <Route
        path="/TerminalPolicy/id::id"
        exact
        component={TerminalPolicy}
        isPrivate
      />
      <Route path="/AddPolicy/id::id" exact component={AddPolicy} isPrivate />
      <Route path="/PolicyKey" exact component={PolicyKey} isPrivate />
      <Route path="/EditPolicy/ac::acId/id::id" exact component={EditPolicy} isPrivate />
      <Route path="/AddGroup" exact component={AddGroup} isPrivate />
      <Route path="/AddTerminal" exact component={AddTerminal} isPrivate />

      <Route
        path="/authentication/confirmpasswordrecover"
        component={PasswordRecover}
      />
      <Route isPrivate path="/AdminArea/usermanagement" component={UserManagement} />
      <Route path="/logout" exact component={Logout} isPrivate verifiedEmail />
      <Route
        path="/Wallet/Payment"
        exact
        component={Payment}
        isPrivate
        verifiedEmail
      />
      <Route path="/customerArea" component={CustomerArea} />
      <Route path="/Wallet" exact component={Wallet} isPrivate />
    </Switch>
  );
}

export default Routes;
