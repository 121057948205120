import { Switch } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
    Accordion,
    Button, Card, Col, Container, Form,
    InputGroup, Modal, Row
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import * as IO from "react-icons/io5";
import * as MD from "react-icons/md";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { PolicyGroupDataContext } from "../../pages/PolicyGroup/Context";
import api from "../../services/api";
import Sidebar from "../SideBar";


function PolicyKey() {
    const context = useContext(PolicyGroupDataContext)

    const tenant = useSelector((state) => state.user.profile.tenants[0]);

    const { register, handleSubmit, control } = useForm();

    const [allPolicyKey, setAllPolicyKey] = useState([])

    const [newPolicy, setNewPolicy] = useState(false)

    const [showModal, setShowModal] = useState(false)
    const [showModalUsersInPolicyKey, setShowModalUsersInPolicyKey] = useState(false)
    const [showModalCoursesInPolicyKey, setShowModalCoursesInPolicyKey] = useState(false)

    const [isDeleted, deleted] = useState(false)
    const [policyKeyId, setPolicyKeyId] = useState("")

    const [policyKeyData, setPolicyKeyData] = useState({});
    const [usersInPolicyKey, setUsersInPolicyKey] = useState([]);

    const [userData, setUserData] = useState([]);
    const [searchUser, setSearchUser] = useState("");

    const [checked, setChecked] = useState(false);
    const [allCourseAvailable, setAllCourseAvailable] = useState([]);

    useEffect(() => {
        if (!showModalUsersInPolicyKey || !showModalCoursesInPolicyKey) {
            setSearchUser("")
        }
    }, [showModalUsersInPolicyKey, showModalCoursesInPolicyKey]);
    useEffect(() => {
        async function GetAllPolicyKey() {
            try {
                const response = await api.get(`/AdminArea/GetAllPolicyKey/${tenant}`);
                const data = response.data;
                setAllPolicyKey(data);

            } catch (err) {
                console.log(err);
                toast.error(err)

            }
        }

        GetAllPolicyKey()

    }, [newPolicy, isDeleted, tenant])

    async function OnSubmit(data, e) {
        const { Name, Description } = data
        e.preventDefault();
        e.target.reset();
        const PostPolicyKey = {
            "Name": Name,
            "Description": Description,
            "CoursePolicy": checked
        }

        if (Name === "") {
            toast.warning("Preencha um nome para a chave de política!")
            return

        }
        try {
            await api.post(`/AdminArea/PostPolicyKey/${tenant}`, PostPolicyKey)
            setNewPolicy(!newPolicy)
            toast.success("Chave de política criada com sucesso!")

        } catch (e) {
            console.log(e);
        }
    }

    const back = useHistory();



    async function ConfirmDelAcEntryPolicy() {
        try {
            await api.delete(`/AdminArea/DeletePolicyKey/${policyKeyId}`);
            toast.success("Chave de política excluída com sucesso!")
            deleted(!isDeleted)

        } catch (err) {
            console.log(err);
        }
        setShowModal(!showModal)
    }

    async function GetUsersInPolicyKey(policyKeyId, isCoursePolicy) {
        document.body.style.cursor = "wait"
        try {
            const response = await api.get(`/AdminArea/GetUsersInPolicyKey/${policyKeyId}`)

            if (isCoursePolicy) {
                GetAllCourseAvailable(policyKeyId)
                setShowModalCoursesInPolicyKey(true)
            } else {

                setShowModalUsersInPolicyKey(true)
            }

            setUsersInPolicyKey(response.data)
        } catch (e) {
            console.log(e);
        }
        document.body.style.cursor = "default"

    }

    async function GetUserPolicies(userId, policyKey) {
        try {
            const response = await api.get(`AdminArea/GetUserPolicies/${userId}`)
            const userPolicyKey = response.data.find(x => x.policyKey === policyKey)
            return userPolicyKey.userPolicyKeyId


        } catch (err) {
        }
    }

    async function DeleteUserPolicyKey(userId) {

        const userPolicyKeyId = await GetUserPolicies(userId, policyKeyData.name);


        try {
            const response = await api.delete(`AdminArea/DeleteUserPolicyKey/${await userPolicyKeyId}`)

            toast.success("Removido com sucesso!")


        } catch (err) {
            console.log(err);
        }
    }

    async function GetUserListByNameEmail(emailName) {
        try {
            const response = await api.get(`AdminArea/UserListByNameEmail/${emailName}`)
            setUserData(response.data)
        } catch (err) {
            console.log(err);
        }
    }
    async function PostUserPolicyKey(userId) {
        try {

            const obj = {
                userId: userId,
                policyKey: policyKeyData.name,
                dataKey: policyKeyData.dataKey
            }

            const response = await api.post(`AdminArea/PostUserPolicyKey/`, obj)
            GetUsersInPolicyKey(policyKeyData.policyKeyId)

        } catch (err) {
            console.log(err);
        }
    }
    async function PostPolicyKeyCourse(id) {
        try {

            const obj = {
                policyKeyId: policyKeyData.policyKeyId,
                CourseId: id
            }

            const response = await api.post(`AdminArea/PostPolicyKeyCourse/`, obj)
            if (response.data.status) {
                toast.success("Curso adicionado com sucesso!")
                GetUsersInPolicyKey(policyKeyData.policyKeyId, true)

                GetAllCourseAvailable(policyKeyData.policyKeyId)
            } else {
                toast.error(response.data.message)
            }

        } catch (err) {
            console.log(err);
        }
    }
    async function GetAllCourseAvailable(policyKeyId) {
        try {

            const response = await api.get(`AdminArea/GetAllCourseAvailable/${policyKeyId}/${tenant}`)
            setAllCourseAvailable(response.data)

        } catch (err) {
            console.log(err);
        }
    }
    async function DeletePolicyKeyCourse(policyKeyCourseId) {
        try {

            const response = await api.delete(`AdminArea/DeletePolicyKeyCourse/${policyKeyCourseId}`)
            if (response.data.status) {
                toast.success("Curso removido com sucesso!")

                GetAllCourseAvailable(policyKeyData.policyKeyId)
                GetUsersInPolicyKey(policyKeyData.policyKeyId, true)

            } else {
                toast.error(response.data.message)
            }


        } catch (err) {
            console.log(err);
        }
    }


    return (
        <>
            <Container className="p-4" fluid="xxl">
                <Row>
                    <Col className="col-auto">
                        <Sidebar />
                    </Col>
                    <Col>
                        <Row className="d-flex align-items-center mt-5 mb-3">
                            <Col className="col-auto" title="Voltar">
                                <h3 className="d-flex align-items-center justify-content-center ">
                                    <IO.IoArrowBackCircle
                                        style={{ cursor: "pointer" }}
                                        size="35"
                                        className="me-4"
                                        onClick={() => back.goBack()}
                                    />
                                    Criar chave de política
                                </h3>
                            </Col>
                            <Row className="pt-4">
                                <Col>
                                    <Card>
                                        <Card.Body className="my-2">
                                            <Form onSubmit={handleSubmit(OnSubmit)}>
                                                <Card.Title>Chave de Política</Card.Title>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">
                                                        <MD.MdEdit size={20} />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        title="Nome da chave de política"
                                                        {...register("Name")}
                                                        autoFocus
                                                        required
                                                        placeholder="Nome da chave de política"
                                                        aria-label="Nome da chave de política"
                                                        aria-describedby="basic-addon1"
                                                    />
                                                </InputGroup>
                                                <Row>
                                                    <Col className="my-2">
                                                        <InputGroup className="mb-3">
                                                            <InputGroup.Text id="basic-addon1">

                                                                <MD.MdDescription size={20} />
                                                            </InputGroup.Text>
                                                            <Form.Control
                                                                title="Descrição da chave de política"
                                                                {...register("Description")}
                                                                placeholder="Descrição"
                                                            />
                                                        </InputGroup>
                                                    </Col>

                                                    <Row className="mt-1">

                                                        <Col> <MD.MdSchool className="me-1" size={20} />Política de Curso

                                                            <Switch
                                                                defaultChecked={false}
                                                                onClick={(e) => setChecked(e.target.checked)}
                                                                {...register("course")}
                                                                className="react-switch"
                                                                height={20}
                                                                width={48}
                                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                            />
                                                        </Col>



                                                    </Row>
                                                    <Row className="mt-2">
                                                        <Col align="end">
                                                            <Button title="Criar chave de política" className="shadow-none BtnPrimary" type="submit">
                                                                Criar chave
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                            </Form>

                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <Card className="my-2">
                                        <Card.Body className="px-0">
                                            <Row className="pb-3 py-2 ms-3">
                                                <Col md={7}>
                                                    <Card.Title as="h5">Chaves de políticas:</Card.Title>
                                                </Col>
                                                <Col md={7}>

                                                </Col>
                                            </Row>
                                            <Row className=" text-center p-2 border-terminal m-0">
                                                <Col xs={3} sm={3} as="h6">
                                                    Nome
                                                </Col>
                                                <Col xs={9} sm={7} className="text-end pe-5" as="h6">
                                                    Descrição
                                                </Col>

                                            </Row>
                                            <Col className="terminal-card py-2 p-0">
                                                {allPolicyKey.length > 0 && allPolicyKey?.map((x, id) => (
                                                    !x.import && !x.coursePolicy ?
                                                        <Card key={x.policyKeyId} className="mt-2 py-2 text-center" style={{ borderRadius: "0.1px" }}>
                                                            <Card.Body >
                                                                <Row>
                                                                    <Col xs className="p-0 ps-5 mx-1">
                                                                        <span>
                                                                            {x.name === null ? "..." : x.name}
                                                                        </span>
                                                                    </Col>
                                                                    <Col xs={5} className=" text-center ">
                                                                        {x.description === "" ? "..." : x.description}
                                                                    </Col>


                                                                    <Col xs={1} className="p-0 text-rigth">
                                                                        {<IO.IoEye title={`Visualizar pessoas na politica`} onClick={() => { setPolicyKeyData(x); GetUsersInPolicyKey(x.policyKeyId) }} style={{ cursor: "pointer" }} />}

                                                                    </Col>
                                                                    <Col xs={1} className="p-0 text-rigth">
                                                                        {!x.import ? <IO.IoTrash title={`Excluir Chave: ${x.name}`} onClick={() => { setPolicyKeyId(x.policyKeyId); setShowModal(!showModal) }} style={{ cursor: "pointer" }} /> : ""}

                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </Card> :
                                                        ""
                                                )
                                                )}
                                            </Col>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Card className="my-2">
                                        <Card.Body className="px-0">
                                            <Row className="pb-3 py-2 ms-3">
                                                <Col md={7}>
                                                    <Card.Title as="h5">Chaves de políticas de curso:</Card.Title>
                                                </Col>
                                                <Col md={7}>

                                                </Col>
                                            </Row>
                                            <Row className=" text-center p-2 border-terminal m-0">
                                                <Col xs={3} sm={3} as="h6">
                                                    Nome
                                                </Col>
                                                <Col xs={9} sm={7} className="text-end pe-5" as="h6">
                                                    Descrição
                                                </Col>

                                            </Row>
                                            <Col className="terminal-card py-2 p-0">
                                                {allPolicyKey.length > 0 && allPolicyKey?.map((x, id) => (
                                                    !x.import && x.coursePolicy ?
                                                        <Card key={x.policyKeyId} className="mt-2 py-2 text-center" style={{ borderRadius: "0.1px" }}>
                                                            <Card.Body >
                                                                <Row>
                                                                    <Col xs className="p-0 ps-5 mx-1">
                                                                        <span>
                                                                            {x.name === null ? "..." : x.name}
                                                                        </span>
                                                                    </Col>
                                                                    <Col xs={5} className=" text-center ">
                                                                        {x.description === "" ? "..." : x.description}
                                                                    </Col>


                                                                    <Col xs={1} className="p-0 text-rigth">
                                                                        {<IO.IoEye title={`Visualizar cursos na politica`} onClick={() => { setPolicyKeyData(x); GetUsersInPolicyKey(x.policyKeyId, x.coursePolicy); }} style={{ cursor: "pointer" }} />}

                                                                    </Col>
                                                                    <Col xs={1} className="p-0 text-rigth">
                                                                        {!x.import ? <IO.IoTrash title={`Excluir Chave: ${x.name}`} onClick={() => { setPolicyKeyId(x.policyKeyId); setShowModal(!showModal) }} style={{ cursor: "pointer" }} /> : ""}

                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </Card> :
                                                        ""
                                                )
                                                )}
                                            </Col>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <Card className="my-2">
                                        <Card.Body className="px-0">
                                            <Row className="pb-3 py-2 ms-3">
                                                <Col md={7}>
                                                    <Card.Title as="h5">Chaves de políticas importadas:</Card.Title>
                                                </Col>
                                                <Col md={7}>

                                                </Col>
                                            </Row>

                                            <Row className=" text-center p-2 border-terminal m-0">
                                                <Col sm={3} as="h6">
                                                    Nome
                                                </Col>
                                                <Col sm={7} className="text-end pe-5" as="h6">
                                                    Descrição
                                                </Col>

                                            </Row>

                                            <Col className="terminal-card py-2 p-0">

                                                {allPolicyKey.length > 0 && allPolicyKey?.map((x, id) => (
                                                    x.import ?
                                                        <Card key={id + 1} style={{ cursor: "not-allowed", borderRadius: "0.1px" }} className="mt-2 ps-3 py-2">
                                                            <Card.Body>
                                                                <Row>
                                                                    <Col xs className="p-0 mx-1">
                                                                        <span>
                                                                            {x.name === null ? "..." : x.name}
                                                                        </span>
                                                                    </Col>
                                                                    <Col xs={5} className=" text-center ">
                                                                        {x.description === null ? "..." : x.description}
                                                                    </Col>


                                                                    <Col xs={1} className="p-0 text-rigth">
                                                                        {!x.import ? <IO.IoTrash onClick={() => { setPolicyKeyId(x.policyKeyId); setShowModal(!showModal) }} style={{ cursor: "pointer" }} /> : ""}

                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </Card> : ""
                                                )
                                                )}
                                            </Col>

                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                        </Row>
                    </Col>
                </Row >
            </Container >

            <Modal
                dialogClassName="border-radius-2"
                show={showModal}
                onHide={() => setShowModal(false)}
                centered
                size="sm"
                // animation={false}
                style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
            >
                <Modal.Body>
                    <Row className="justify-content-center text-center pb-3">
                        <h5 className="my-3 pb-4 border-bottom">Excluir chave de política?</h5>

                        <Button
                            style={{ border: "none", backgroundColor: "var(--bg-red)" }}
                            className="col-4 me-1" onClick={() => setShowModal(!showModal)}
                        >Não</Button>
                        <Button
                            style={{ border: "none", backgroundColor: "var(--bg-green)" }}
                            className="col-4 ms-1" onClick={() => ConfirmDelAcEntryPolicy()}
                        >Sim</Button>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal
                dialogClassName=""
                show={showModalUsersInPolicyKey}
                onHide={() => setShowModalUsersInPolicyKey(false)}
                centered
            // animation={false}

            >
                <Modal.Body >
                    <Card className="my-2">
                        <Card.Body className="px-0 border-bottom" >
                            <Row className=" py-2 ms-1 ">
                                <Col md={7}>
                                    <Card.Title as="h5">Adicionar Usuários:</Card.Title>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Col className="terminal-card p-0">
                            <div class="input-group rounded p-2">
                                <span class="input-group-text border-0" id="search-addon">
                                    <MD.MdSearch size={20} />

                                </span>
                                <input type="search" onChange={(x) => { x.target.value.length >= 3 && GetUserListByNameEmail(x.target.value); setSearchUser(x.target.value) }} class="form-control rounded" placeholder="Pesquisar" aria-label="Search" aria-describedby="search-addon" />
                            </div>
                            {searchUser.length >= 3 && userData.length > 0 ?

                                userData?.map((x) => (
                                    <Card className="mt-2 py-2 text-start" style={{ borderRadius: "0.1px" }}>
                                        <Card.Body>
                                            <Row onClick={() => PostUserPolicyKey(x.id)}>
                                                <Col xs className="p-0 mx-1">
                                                    <div>{x.userName}</div>
                                                    <Form.Text muted>{x.email}</Form.Text>

                                                </Col>

                                                <Col xs={1} className="p-0 text-right">
                                                    <MD.MdAddCircle
                                                        size="20"
                                                        style={{ color: "#37b24d", cursor: "pointer" }}
                                                    />

                                                </Col>
                                                {/*  <Col xs={1} className="p-0 text-right">
                                                <IO.IoTrash title={`Remover da politica`} onClick={() => { DeleteUserPolicyKey(x.userId) }} style={{ cursor: "pointer" }} />
                                            </Col> */}
                                            </Row>

                                        </Card.Body>
                                    </Card>
                                ))

                                :

                                <Row className="pt-2 mb-0" >
                                    <Col className="text-center">
                                        <h6><strong>Digite o nome ou email do usuário para pesquisar!</strong></h6>
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Card>
                    <div className="border-bottom my-3">

                    </div>
                    <Card>
                        <Card.Body className="px-0 border-bottom" >
                            <Row className=" py-2 ms-1 ">
                                <Col md={7}>
                                    <Card.Title as="h5">Usuários cadastrados:</Card.Title>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Col className="terminal-card p-0">
                            {!!usersInPolicyKey.user && usersInPolicyKey?.user.map((x) => (
                                <Card className="mt-2 py-2 text-start" style={{ borderRadius: "0.1px" }}>
                                    <Card.Body>
                                        <Row>
                                            <Col xs className="p-0 mx-1">
                                                <div>{x.name}</div>
                                                <Form.Text muted>{x.email}</Form.Text>

                                            </Col>

                                            <Col xs={1} className="p-0 text-right">
                                                <IO.IoTrash title={`Remover da politica`} onClick={() => { DeleteUserPolicyKey(x.userId) }} style={{ cursor: "pointer" }} />
                                            </Col>
                                        </Row>

                                    </Card.Body>
                                </Card>
                            ))}
                        </Col>
                    </Card>
                </Modal.Body>
            </Modal>


            <Modal
                dialogClassName=""
                show={showModalCoursesInPolicyKey}
                onHide={() => setShowModalCoursesInPolicyKey(false)}
                centered
            // animation={false}

            >
                <Modal.Body >
                    <Card className="my-2">
                        <Card.Body className="px-0 border-bottom" >
                            <Row className=" py-2 ms-1 ">
                                <Col md={7}>
                                    <Card.Title as="h5">Adicionar Cursos:</Card.Title>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Col className="terminal-card p-0">
                            <div class="input-group rounded p-2">
                                <span class="input-group-text border-0" id="search-addon">
                                    <MD.MdSearch size={20} />

                                </span>
                                <input type="search" onChange={(x) => { x.target.value.length >= 3 && GetUserListByNameEmail(x.target.value); setSearchUser(x.target.value) }} class="form-control rounded" placeholder="Pesquisar" aria-label="Search" aria-describedby="search-addon" />
                            </div>
                            {searchUser.length >= 3 && allCourseAvailable.length > 0 ?

                                allCourseAvailable.filter(s => s.name.toLowerCase().includes(searchUser.toLocaleLowerCase())).map((x) => (
                                    <Card className="mt-2 py-2 text-start" style={{ borderRadius: "0.1px" }}>
                                        <Card.Body>
                                            <Row onClick={() => PostPolicyKeyCourse(x.courseId)}>
                                                <Col xs className="p-0 mx-1">
                                                    <div>{x.name}</div>
                                                    <Form.Text muted>{x.period}</Form.Text>

                                                </Col>

                                                <Col xs={1} className="p-0 text-right">
                                                    <MD.MdAddCircle
                                                        size="20"
                                                        style={{ color: "#37b24d", cursor: "pointer" }}
                                                    />

                                                </Col>
                                                {/*  <Col xs={1} className="p-0 text-right">
                                                <IO.IoTrash title={`Remover da politica`} onClick={() => { DeleteUserPolicyKey(x.userId) }} style={{ cursor: "pointer" }} />
                                            </Col> */}
                                            </Row>

                                        </Card.Body>
                                    </Card>
                                ))

                                :

                                <Row className="pt-2 mb-0" >
                                    <Col className="text-center">
                                        <h6><strong>Digite o nome do curso para pesquisar!</strong></h6>
                                        {
                                            allCourseAvailable?.map((x) => (
                                                <Card className="mt-2 py-2 text-start" style={{ borderRadius: "0.1px" }}>
                                                    <Card.Body>
                                                        <Row onClick={() => PostPolicyKeyCourse(x.courseId)}>
                                                            <Col xs className="p-0 mx-1">
                                                                <div>{x.name}</div>
                                                                <Form.Text muted>{x.period}</Form.Text>

                                                            </Col>

                                                            <Col xs={1} className="p-0 text-right">
                                                                <MD.MdAddCircle
                                                                    size="20"
                                                                    style={{ color: "#37b24d", cursor: "pointer" }}
                                                                />

                                                            </Col>
                                                            {/*  <Col xs={1} className="p-0 text-right">
                                                <IO.IoTrash title={`Remover da politica`} onClick={() => { DeleteUserPolicyKey(x.userId) }} style={{ cursor: "pointer" }} />
                                            </Col> */}
                                                        </Row>

                                                    </Card.Body>
                                                </Card>
                                            ))
                                        }
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Card>
                    <div className="border-bottom my-3">

                    </div>
                    <Card>
                        <Card.Body className="px-0 border-bottom" >
                            <Row className=" py-2 ms-1 ">
                                <Col md={7}>
                                    <Card.Title as="h5">Cursos Adicionados:</Card.Title>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Col className="terminal-card p-0">
                            {!!usersInPolicyKey.courses && usersInPolicyKey?.courses.map((x) => (
                                <Card className="mt-2 py-2 text-start" style={{ borderRadius: "0.1px" }}>
                                    <Card.Body>
                                        <Row>
                                            <Col xs className="p-0 mx-1">
                                                <div>{x.name}</div>
                                                <Form.Text muted>{x.period}</Form.Text>

                                            </Col>

                                            <Col xs={1} className="p-0 text-right">
                                                <IO.IoTrash title={`Remover da politica`} onClick={() => { DeletePolicyKeyCourse(x.policyKeyCourseId) }} style={{ cursor: "pointer" }} />
                                            </Col>
                                        </Row>

                                    </Card.Body>
                                </Card>
                            ))}
                        </Col>
                    </Card>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default PolicyKey;
