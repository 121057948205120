import { combineReducers } from "redux";

import auth from "./auth/reducer";
import user from "./user/reducer";
import expiration from "./expiration/reducer";

export default combineReducers({
   auth,
   user,
   expiration
});