import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import * as MD from "react-icons/md";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../../../components/SideBar";
import history from "../../../services/history";
import { PolicyGroupDataContext } from "../Context";
import Identification from "./Identification";
import RegisteredPolicies from "./RegisteredPolicies";
import Schedules from "./Schedules";
import "./styles.css";

function PolicyGroupEdit() {
  const context = useContext(PolicyGroupDataContext)
  const Back = useHistory().goBack; // React-router-dom goBack Page Function
  const [policyOptions, setPolicyOptions] = useState("I");
  const identification = {};
  const [schedules, setSchedules] = useState([]);

  const [editPolicyGroup, setEditPolicyGroup] = useState(false);
  const [addPolicy, setAddPolicy] = useState(false);
  useEffect(() => {
    if (context.currentPage.currentPage === "pageSchedulesPGE") {
      setPolicyOptions("pageSchedulesPGE")

    }
  }, []);
  useEffect(() => {
    if (Object.keys(context.dataPolicyGroup).length === 0) {
      toast.warning("Selecione um grupo para ver detalhes ou editar!")
      history.push("/PolicyGroup")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function Ident() {
    setPolicyOptions("pageSchedulesPGE")
    context.setCurrentPageData({
      currentPage: "pageSchedulesPGE",
      lastData: {}
    })

  }

  function Sched() {
    setEditPolicyGroup(false)
    setAddPolicy(false)
    setPolicyOptions("P")
    context.setCurrentPageData({
      currentPage: "P",
      lastData: {}
    })

  }

  function EditPolicyGroup(x) {
    setEditPolicyGroup(true)
    setAddPolicy(false)
    setPolicyOptions("pageSchedulesPGE")
    context.setCurrentPageData({
      currentPage: "pageSchedulesPGE",
      lastData: {}
    })

  }

  function AddPoliciesGroup(status) {
    if (status) {
      setEditPolicyGroup(false)
      setAddPolicy(true)
      setPolicyOptions("pageSchedulesPGE")
      context.setCurrentPageData({
        currentPage: "pageSchedulesPGE",
        lastData: {}
      })

    }
  }

  function RemovePolicy(data) {
    let arr = schedules.filter((y) => y !== data);
    setSchedules(arr)
  }

  return (
    <>
      <Container className="p-4" fluid="xxl">
        <Row>
          <Col className="col-auto">
            <Sidebar />
          </Col>
          <Col>
            <Row className="d-flex align-items-center mt-5 mb-3">
              <Col className="col-auto">
                <h4 className="d-flex align-items-center justify-content-center ">
                  <MD.MdArrowBack
                    style={{ cursor: "pointer", color: "#000", backgroundColor: "#cccccc", borderRadius: "50%", padding: "5px" }}
                    size="35"
                    className="me-4"
                    onClick={() => Back()}
                  />
                  Editar Grupo
                </h4>
              </Col>
              <Col>
                <Navbar>
                  <Row>
                    <Col>
                      <Nav>
                        <Card className="px-3 py-0">
                          <Row align="center" className="d-flex align-items-end">
                            <Col xs={12} md>
                              <Nav.Link
                                onClick={() => editPolicyGroup ? toast.warning("Termine de editar a política para prosseguir!") : setPolicyOptions("I")}
                                className={
                                  policyOptions === "I"
                                    ? "border-navbar pb-2"
                                    : "navbar-link pb-2"
                                }
                              >
                                <small>IDENTIFICAÇÃO</small>
                              </Nav.Link>
                            </Col>

                            <Col xs={12} md>
                              <Nav.Link
                                onClick={() => (context.setNewData({
                                  EntryGroupId: context.dataPolicyGroup.entryGroupId,
                                  Name: context.dataPolicyGroup.name,
                                  Description: context.dataPolicyGroup.name,
                                  DataKey: context.dataPolicyGroup.dataKey,
                                  EntryGroupPolicies: context.dataPolicyGroup.entryGroupPolicies
                                }), setPolicyOptions("pageSchedulesPGE"), context.setCurrentPageData({
                                  currentPage: "pageSchedulesPGE",
                                  lastData: {}
                                }))}
                                className={
                                  policyOptions === "pageSchedulesPGE"
                                    ? "border-navbar"
                                    : "navbar-link"
                                }
                              >
                                <small>POLÍTICAS E AGENDAMENTOS</small>
                              </Nav.Link>
                            </Col>

                            <Col>
                              <Nav.Link
                                onClick={() => !editPolicyGroup ? (setPolicyOptions("P"), context.setCurrentPageData({
                                  currentPage: "P",
                                  lastData: {}
                                })) : toast.warning("Termine de editar a política para prosseguir!")}
                                className={
                                  policyOptions === "P"
                                    ? "border-navbar"
                                    : "navbar-link"
                                }
                              >
                                <small>POLÍTICAS CADASTRADAS</small>
                              </Nav.Link>
                            </Col>
                          </Row>
                        </Card>
                      </Nav>
                    </Col>
                  </Row>
                </Navbar>

              </Col>
              <Container fluid="sm">
                <Row>
                  {policyOptions === "I" ? (
                    <Col md={8}>
                      <Identification ident={Ident} />
                    </Col>
                  ) : policyOptions === "pageSchedulesPGE" ? (
                    <Schedules ident={identification} sched={Sched} edit={editPolicyGroup} schedules={schedules} addPolicy={addPolicy} />
                  ) : policyOptions === "P" ? (
                    <RegisteredPolicies edit={EditPolicyGroup} addPoliciesGroup={AddPoliciesGroup} removePolicy={RemovePolicy} ident={identification} sched={[schedules]} />
                  ) : (
                    <Identification />
                  )}
                </Row>
              </Container>
            </Row>
          </Col>
        </Row>
      </Container >
    </>
  );
}

export default PolicyGroupEdit;
