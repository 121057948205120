import { useEffect, useState } from "react";
import {
  Button, ButtonGroup, Card, Col, Container, Form, FormControl, InputGroup, Row
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as FA from "react-icons/fa";
import * as IO from "react-icons/io5";
import * as MD from "react-icons/md";
import * as RI from "react-icons/ri";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import terminalImg from "../../assets/img/terminal.svg";
import Sidebar from "../../components/SideBar";
import api from "../../services/api";
import history from "../../services/history";
import "./styles.css";

function AddTerminal() {
  const tenant = useSelector((state) => state.user.profile.tenants[0]);
  const [clockwiseAllowed, setClockwiseAllowed] = useState(false);
  const [antiClockwiseAllowed, setAntiClockwiseAllowed] = useState(true);
  const [clockwise, setClockwise] = useState(true);
  const [anticlockwise, setAnticlockwise] = useState(false);
  const [updateTerminals, setUpdateTerminals] = useState(false);
  const [isSelected, setIsSelected] = useState(true);

  const [fingerprintReading, setFingerprintReading] = useState(false);
  const [barCodeReading, setBarCodeReading] = useState(false);
  const [RFIDReading, setRFIDReading] = useState(false);

  //Pagination

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [deviceModel, setDeviceModel] = useState(5);
  const [turnstyleMode, setTurnstyleMode] = useState(3);
  const { handleSubmit } = useForm();

  const [AllAcEntryAvailable, setAllAcEntryAvailable] = useState([]);
  const [selectedTerminal, setSelectedTerminal] = useState(String);

  useEffect(() => {
    GetAllAcEntryAvailable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTerminals]);

  async function GetAllAcEntryAvailable() {
    try {
      const response = await api.get(`/AdminArea/GetAllAcEntryAvailable/${tenant}`);
      const data = response.data;
      setAllAcEntryAvailable(data);
    } catch (err) {
      console.log(err);
    }
  }



  async function OnSubmit(form) {
    if (!fingerprintReading && !barCodeReading && !RFIDReading) {
      toast.warning(`Selecione pelo menos um método de liberação!`);
    }
    else {
      const jsonData = {
        ReferenceCode: selectedTerminal.referenceCode,
        Name: name,
        Description: description,
        Type: true,
        PluginData: {
          IpAddress: selectedTerminal.ipAddress,
          Type: selectedTerminal.type,
          FwVersion: selectedTerminal.fwVersion,
          Mac: selectedTerminal.mac,
          Active: selectedTerminal.active,
          DeviceModel: deviceModel,
          TurnstyleMode: turnstyleMode,
          Clockwise: clockwise,
          Anticlockwise: anticlockwise,
          ClockwiseAllowed: clockwiseAllowed,
          AnticlockwiseAllowed: antiClockwiseAllowed,
          BarCodeReading: barCodeReading,
          RFIDReading: RFIDReading,
          FingerprintReading: fingerprintReading,
        },
        Geolocation: null,
        DataKey: tenant
      };
      try {
        await api.post(`/AdminArea/PostAcEntry`, jsonData);
        history.push("/Homescreen");
        toast.success(`Terminal salvo com sucesso!`);
      } catch (err) {
        console.log(err.message);
      }
    }
  }
  return (
    <>
      <Container className="py-4">
        <Row>
          <Col className="col-auto">
            <Sidebar />
          </Col>
          <Col>
            <Row className="justify-content-between mt-5 mb-3">
              <Col className="col-auto">
                <h3 className="d-flex align-items-center justify-content-center">
                  <IO.IoArrowBackCircle
                    style={{ cursor: "pointer" }}
                    size="35"
                    className="me-4"
                    onClick={() => history.push("/")}
                  />
                  Adicionar Terminal
                </h3>
              </Col>
              <Form onSubmit={handleSubmit(OnSubmit)}>
                <Row>
                  <Container>
                    {AllAcEntryAvailable.length === 0 ? (
                      <>
                        <Row>
                          <Col className="mt-5 d-flex justify-content-center align-items-center">
                            <h4>Nenhum novo terminal encontrado.</h4>
                          </Col>
                          <Row>
                            <Col onClick={() => { setUpdateTerminals(!updateTerminals); toast.success("Atualizado com sucesso!") }}
                              style={{ cursor: "pointer" }}
                              className="refresh d-flex justify-content-center align-items-center "
                            >
                              <MD.MdOutlineSync />
                              Atualizar
                            </Col>

                          </Row>
                        </Row>
                      </>
                    ) : (
                      <>
                        <Card className="my-4">
                          <Card.Body className="px-0">
                            <Row className="pb-3 py-2 ms-3">
                              <Col md={7}>
                                <Card.Title as="h5">
                                  Terminais Disponíveis
                                </Card.Title>
                                <small>Selecione um terminal</small>
                              </Col>
                              <Col
                                onClick={
                                  () => { setUpdateTerminals(!updateTerminals); toast.success("Atualizado com sucesso!") }
                                }
                                style={{
                                  cursor: "pointer",
                                }}
                                className="refresh d-flex justify-content-end align-items-center me-5"
                              >
                                <MD.MdOutlineSync />
                                Atualizar
                              </Col>
                            </Row>

                            <Row className=" border-terminal text-center p-2 m-0">
                              <Col xs={2} as="h6">
                                Nº Serial
                              </Col>
                              <Col xs={2} as="h6">
                                IP Address
                              </Col>
                              <Col xs={2} as="h6">
                                Tipo
                              </Col>
                              <Col xs={2} as="h6">
                                Fw. Version
                              </Col>
                              <Col xs={2} as="h6">
                                MAC
                              </Col>
                              <Col xs={2} as="h6">
                                Ativo
                              </Col>
                            </Row>

                            <Col className="p-0 terminal-card">
                              {AllAcEntryAvailable.map((r) => (
                                <a key={r.referenceCode} href="#Save-Terminal">
                                  <Card
                                    style={{
                                      cursor: "pointer",
                                      borderRadius: "0.1px",
                                    }}

                                    onClick={() => {
                                      setSelectedTerminal(r);
                                      setIsSelected(false);
                                    }}
                                    className={
                                      r.referenceCode ===
                                        selectedTerminal.referenceCode
                                        ? "mt-2 py-2 text-center selected-border"
                                        : "mt-2 py-2 text-center "
                                    }
                                  >
                                    <Card.Body>
                                      <Row>
                                        <Col
                                          title={r.referenceCode}
                                          className="p-0 mx-1"
                                        >
                                          <span>
                                            <strong>#{r.referenceCode}</strong>
                                          </span>
                                        </Col>
                                        {/* <Col className="pl-sm-1 p-0"><span>{RenameMonth(x.creationDate)}</span></Col> */}
                                        <Col className="pl-sm-1 p-0">
                                          <span>{r.ipAddress}</span>
                                        </Col>
                                        <Col className="pl-sm-1 p-0">
                                          <span>{r.type}</span>
                                        </Col>
                                        <Col className="pl-sm-1 p-0">
                                          <span>{r.fwVersion}</span>
                                        </Col>
                                        <Col className="pl-sm-1 p-0">
                                          <span>{r.mac}</span>
                                        </Col>
                                        <Col className="pl-sm-1 p-0">
                                          <RI.RiCheckboxBlankCircleFill
                                            style={{
                                              color: r.active
                                                ? "var(--bg-green)"
                                                : "var(--bg-red)",
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Card>
                                </a>
                              ))}
                            </Col>
                          </Card.Body>
                        </Card>
                      </>
                    )}
                  </Container>
                </Row>

                <Row hidden={isSelected}>
                  <Row as="h3" className="ms-2 py-2">Preencha as informações do terminal:</Row>
                  <Col sm={6}>
                    <Card id="Save-Terminal">
                      <Card.Body>
                        <Row className="my-2">
                          <Col sm={3}>
                            <Card.Img
                              src={terminalImg}
                              style={{ width: 100 }}
                            />
                          </Col>
                          <Col>
                            <Row>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                  <MD.MdDashboard size={20} />
                                </InputGroup.Text>
                                <FormControl
                                  required
                                  placeholder="Nome do terminal"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  onChange={(e) => setName(e.target.value)}
                                />
                              </InputGroup>
                            </Row>
                            <Row>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                  <MD.MdDescription size={20} />
                                </InputGroup.Text>
                                <FormControl

                                  placeholder="Descrição"
                                  aria-label="Description"
                                  aria-describedby="basic-addon1"
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                />
                              </InputGroup>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col>
                            <div className="input-group mb-3">
                              <label
                                className="input-group-text"
                                htmlFor="inputGroupSelect01"
                              >
                                <MD.MdList />
                              </label>
                              <select required
                                onChange={(e) =>
                                  setDeviceModel(e.target.value)
                                }
                                className="form-select"
                                id="inputGroupSelect01"
                              >
                                <option disabled >Modelo Terminal</option>
                                <option default value="5">TiketGateNKRollLight </option>
                              </select>
                            </div>
                          </Col>
                          <Col>
                            <div className="input-group mb-3">
                              <label
                                className="input-group-text"
                                htmlFor="inputGroupSelect01"
                              >
                                <MD.MdList />
                              </label>
                              <select
                                onChange={(e) => setTurnstyleMode(e.target.value)}
                                className="form-select"
                                id="inputGroupSelect01"
                              >
                                <option disabled  >Modo Virada</option>
                                <option default value="3">QuadratureNormal</option>

                              </select>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={3}>
                    <Card>
                      <Card.Body>
                        <Row className="pb-2 ps-3 justify-content-center">
                          <Row className="mt-2">
                            <Col>
                              <h5>Sentido Livre</h5>
                              <ButtonGroup>
                                <Button

                                  className="me-2"
                                  bsPrefix={
                                    clockwiseAllowed
                                      ? "btn-modalActive"
                                      : "btn-modal"
                                  }
                                  onClick={() =>
                                    setClockwiseAllowed(!clockwiseAllowed)
                                  }
                                >
                                  <MD.MdRotateRight size={40} />
                                </Button>{" "}
                                <Button
                                  ml="4"
                                  bsPrefix={
                                    antiClockwiseAllowed
                                      ? "btn-modalActive"
                                      : "btn-modal"
                                  }
                                  onClick={() =>
                                    setAntiClockwiseAllowed(
                                      !antiClockwiseAllowed
                                    )
                                  }
                                >
                                  <MD.MdRotateLeft size={40} />
                                </Button>{" "}
                              </ButtonGroup>
                            </Col>
                          </Row>

                          <Row className="mt-2">
                            <h5>Sentido do Giro</h5>
                            <Col>
                              <ButtonGroup>
                                <Button
                                  className="me-2"
                                  bsPrefix={
                                    clockwise ? "btn-modalActive" : "btn-modal"
                                  }
                                  onClick={() => setClockwise(!clockwise)}
                                >
                                  <MD.MdRotateRight size={40} />
                                </Button>{" "}
                                <Button
                                  bsPrefix={
                                    anticlockwise
                                      ? "btn-modalActive"
                                      : "btn-modal"
                                  }
                                  onClick={() =>
                                    setAnticlockwise(!anticlockwise)
                                  }
                                >
                                  <MD.MdRotateLeft size={40} />
                                </Button>{" "}
                              </ButtonGroup>
                            </Col>
                          </Row>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Row>
                      <Card
                        style={{ backgroundColor: "#212529", color: "#f1f3f5", cursor: "pointer" }}
                      >
                        <Card.Body
                          onClick={() =>
                            setFingerprintReading(!fingerprintReading)
                          }
                        >
                          <Row className="">
                            <Col sm={3}>
                              <MD.MdFingerprint
                                size={30}
                                style={
                                  fingerprintReading
                                    ? { color: "#ea4e23" }
                                    : { color: "#f1f3f5" }
                                }
                                onChange={(e) =>
                                  setFingerprintReading(e.target.value)
                                }
                              />
                            </Col>
                            <Col className="d-flex align-items-center">
                              <Card.Text>Biometria</Card.Text>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Row>
                    <Row>
                      <Card
                        style={{ backgroundColor: "#212529", color: "white", cursor: "pointer" }}
                      >
                        <Card.Body
                          onClick={() => setBarCodeReading(!barCodeReading)}
                        >
                          <Row>
                            <Col sm={3}>
                              <FA.FaBarcode
                                size={30}
                                style={
                                  barCodeReading
                                    ? { color: "#ea4e23" }
                                    : { color: "#f1f3f5" }
                                }
                              />
                            </Col>
                            <Col className="d-flex align-items-center">
                              <Card.Text>Cod. de Barra</Card.Text>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Row>
                    <Row>
                      <Card
                        style={{ backgroundColor: "#212529", color: "white", cursor: "pointer" }}
                      >
                        <Card.Body onClick={() => setRFIDReading(!RFIDReading)}>
                          <Row>
                            <Col sm={3}>
                              <IO.IoCardOutline
                                size={30}
                                style={
                                  RFIDReading
                                    ? { color: "#ea4e23" }
                                    : { color: "#f1f3f5" }
                                }
                              />
                            </Col>
                            <Col className="d-flex align-items-center">
                              <Card.Text>Aproximação</Card.Text>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Row>
                  </Col>
                  <Col xs={12} className="p-0" align="end">
                    <Col>
                      <Button type="submit" className="BtnPrimary shadow-none">
                        Salvar Terminal
                      </Button>
                    </Col>
                  </Col>
                </Row>
              </Form>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default AddTerminal;
