import { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import * as IO from "react-icons/io5";
import * as MD from "react-icons/md";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../../components/SideBar";
import api from "../../services/api";
import history from "../../services/history";
import './styles.css';
function TerminalPolicy(props) {



  const [getAllAcEntryPolicyByAcEntry, setGetAllAcEntryPolicyByAcEntry] = useState([])
  const [isDeleted, deleted] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [selectedAcEntryPolicyId, setSelectedAcEntryPolicyId] = useState(null)

  const [isLoading, setIsLoading] = useState(true);
  const back = useHistory();

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      back.goBack()
    }
  }, [back]);
  useEffect(() => {
    setIsLoading(true);

    GetAllAcEntryPolicyByAcEntry()
    setIsLoading(false);

    document.addEventListener("keydown", escFunction);

    return () => {
      document.removeEventListener("keydown", escFunction);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [escFunction, isDeleted, isLoading ? getAllAcEntryPolicyByAcEntry : null]);


  async function GetAllAcEntryPolicyByAcEntry() {
    try {
      const response = await api.get(`/AdminArea/GetAllAcEntryPolicyByAcEntry/${props.match.params.id}`);
      const data = response.data;
      setGetAllAcEntryPolicyByAcEntry(data);
    } catch (err) {
      console.log(err);
    }
  }


  function DeleteAcEntryPolicy(acEntryPolicyId) {
    setShowModal(!showModal)
    setSelectedAcEntryPolicyId(acEntryPolicyId)

  }

  async function ConfirmDelAcEntryPolicy() {
    try {
      await api.delete(`/AdminArea/DeleteAcEntryPolicy/${selectedAcEntryPolicyId}`);
      deleted(!isDeleted)
      toast.success("Excluído com sucesso!")

    } catch (err) {
      console.log(err);
    }
    setShowModal(!showModal)
  }


  return (
    <>
      <Container className="p-4">
        <Row>
          <Col className="col-auto">
            <Sidebar />
          </Col>
          <Col>
            <Row className="justify-content-between mt-5 mb-3">
              <Col className="col-auto">
                <h3 className="d-flex align-items-center justify-content-center">
                  <IO.IoArrowBackCircle
                    title="Voltar"
                    style={{ cursor: "pointer" }}
                    size="35"
                    className="me-4"
                    onClick={() => back.goBack()}
                  />
                  Políticas do Terminal
                </h3>
              </Col>

              <Container>
                {
                  getAllAcEntryPolicyByAcEntry.length === 0 ?
                    <>
                      <Row className="pt-5 justify-content-md-center">
                        <Col as="h4" md="auto" xs={12}> Nenhuma política cadastrada para esse terminal!</Col>
                      </Row>
                      <Row className="pt-1 justify-content-md-center">
                        <Col as="h6" md="auto" xs={12}>Adicionar Política:</Col>
                      </Row>
                      <Row title="Adicionar Política" className="justify-content-center ">
                        <Col md="auto" >
                          <MD.MdAddCircle
                            size="70"
                            style={{ color: "#003366", cursor: "pointer" }}
                            onClick={() => history.push(`/AddPolicy/id:${props.match.params.id}`)}
                          />
                        </Col>
                      </Row>
                    </>

                    :

                    <Card className="my-4">
                      <Card.Body className="px-0">
                        <Row className="pb-3 py-2 ms-3">
                          <Col md={7}>
                            <Card.Title as="h5">Lista de Políticas</Card.Title>
                          </Col>
                          <Col
                            md={{ span: 3, offset: 2 }}
                            className="d-flex align-items-center justify-content-center"
                          >
                            <small className="me-1">ADICIONAR POLÍTICAS</small>
                            <MD.MdAddCircle
                              title="Adicionar Políticas"
                              size="30"
                              style={{ color: "#37b24d", cursor: "pointer" }}
                              onClick={() => history.push(`/AddPolicy/id:${props.match.params.id}`)}
                            />
                          </Col>
                        </Row>

                        <Row
                          className="justify-content-space-between align-items-center text-center p-2 border-bottom border-terminal"
                        >
                          <Col className="ps-4" align="start" >
                            Nome
                          </Col>
                          <Col>
                            Chave Política
                          </Col>
                          <Col align="end" className="pe-4" >
                            Ações
                          </Col>
                        </Row>

                        <Col className="p-0 terminal-card">
                          {getAllAcEntryPolicyByAcEntry.map((x, y) => (

                            <Card key={y} className="mt-2 py-2 text-center" style={{ borderRadius: "0.1px" }}>
                              <Card.Body>
                                <Row>
                                  <Col align={"start"} className="p-0 ps-3 mx-1">
                                    <span>
                                      <strong>{x.name}</strong>
                                    </span>
                                  </Col>
                                  {/* <Col className="pl-sm-1 p-0"><span>{RenameMonth(x.creationDate)}</span></Col> */}
                                  <Col className=" p-0">
                                    <span>{x.requiredKey}</span>
                                  </Col>

                                  <Col align={"end"} className="p-0 pe-3 text-right">
                                    <MD.MdModeEdit
                                      onClick={() => { history.push(`/EditPolicy/ac:${x.acEntryId}/id:${x.acEntryPolicyId}`) }}
                                      title={`Editar política: ${x.name}`}
                                      className="me-4"
                                      style={{ cursor: "pointer" }}
                                    />
                                    <IO.IoTrash title={`Excluir política: ${x.name}`} onClick={(acEntryPolicyId) => DeleteAcEntryPolicy(x.acEntryPolicyId)} style={{ cursor: "pointer" }} />
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          ))}
                        </Col>

                      </Card.Body>
                    </Card>
                }
              </Container>
            </Row>
          </Col>
        </Row>
      </Container>

      {/* <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-danger">
            Are you sure you want to delete this?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(!showModal)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => ConfirmDelAcEntryPolicy()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal
        dialogClassName="border-radius-2"
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="sm"
        // animation={false}
        style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      >
        <Modal.Body>
          <Row className="justify-content-center text-center pb-3">
            <h5 className="my-3 pb-4 border-bottom">Excluir Política?</h5>

            <Button
              style={{ border: "none", backgroundColor: "var(--bg-red)" }}
              className="col-4 me-1" onClick={() => setShowModal(!showModal)}
            >Não</Button>
            <Button
              style={{ border: "none", backgroundColor: "var(--bg-green)" }}
              className="col-4 ms-1" onClick={() => ConfirmDelAcEntryPolicy()}
            >Sim</Button>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default TerminalPolicy;
