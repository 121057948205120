import React, { Fragment, useState } from 'react';
import { Row, Container, Button, Form, Card, InputGroup } from 'react-bootstrap';
import { toast } from "react-toastify";
import api from "../../services/api";
import * as MD from 'react-icons/md';
import history from "../../services/history";

import "./style.css"

function CAProfile() {

    //customerData
    //const profileName = useSelector(state => state.user.profile.name);
    //const profileEmail = useSelector(state => state.user.profile.email);


    const [oldPassword, setOldPassword] = useState();
    const [newCustomerPassword, setNewCustomerPassword] = useState();
    const [newCustomerPasswordConfirmation, setNewCustomerPasswordConfirmation] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirmation, setShowPassworConfirmation] = useState(false);

    //logOff

    //Modals
   

    async function UpdatePassword() {
        if (newCustomerPassword === newCustomerPasswordConfirmation) {

            const newPass = {
                oldPassword: oldPassword,
                newPassword: newCustomerPassword,
                confirmPassword: newCustomerPasswordConfirmation
            }

            try {
                const response = await api.post(`/Authentication/ChangeUserPassword`, newPass)
                const { status } = response

                if (response.status === 200 && status) {
                    toast.success("Senha foi alterada com sucesso!")
                } else {
                    toast.error("Erro! verifique as informações e tente novamente")
                }
            } catch (error) {
                toast.error("Erro! Verifique a senha antiga e tente novamente")
            }
        } else {
            toast.error("A senha de confirmação nao coincide com a nova senha informada!")
        }
    }


    return (
      <Fragment>
        <Container>
          <div className="center-card">
            <div className="d-flex align-items-center justify-content-center ">
              <Card.Body className="cardx">
                <div className="close">
                  <MD.MdClose
                    size={30}
                    onClick={() => {
                      history.push("/");
                    }}
                  />
                </div>
                <Card.Header as="h2">Alterar Senha</Card.Header>
                <Form>
                  <Form.Group className="mt-3 px-4">
                    <Form.Label as="h4">Senha Atual</Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      style={{ color: "#FFFFFF" }}
                      placeholder="Digite a senha atual"
                      onChange={(e) => setOldPassword(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mt-3 px-4">
                    <Form.Label as="h4">Nova Senha</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={`${showPassword !== false ? "text" : "password"}`}
                        onChange={(e) => setNewCustomerPassword(e.target.value)}
                        style={{ color: "#FFFFFF" }}
                        className="form-control"
                        placeholder="Digite a nova senha"
                      />
                      <InputGroup.Text
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <MD.MdVisibility
                            size={20}
                            style={{ color: "var(--shape" }}
                          />
                        ) : (
                          <MD.MdVisibilityOff
                            size={20}
                            style={{ color: "var(--shape" }}
                          />
                        )}
                      </InputGroup.Text>
                    </InputGroup>

                    <Form.Text id="passwordHelpBlock" muted>
                      Sua senha deve ter de 8 a 20 caracteres, conter letras e
                      números e não deve conter espaços, caracteres especiais ou
                      emoji.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mt-3 px-4">
                    <Form.Label as="h4">Confirmar Nova Senha</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={`${
                          showPasswordConfirmation !== false
                            ? "text"
                            : "password"
                        }`}
                        onChange={(e) =>
                          setNewCustomerPasswordConfirmation(e.target.value)
                        }
                        style={{ color: "#FFFFFF" }}
                        className="form-control"
                        placeholder="Confirme sua nova senha"
                      />
                      <InputGroup.Text
                        onClick={() =>
                          setShowPassworConfirmation(!showPasswordConfirmation)
                        }
                      >
                        {showPasswordConfirmation ? (
                          <MD.MdVisibility
                            size={20}
                            style={{ color: "var(--shape" }}
                          />
                        ) : (
                          <MD.MdVisibilityOff
                            size={20}
                            style={{ color: "var(--shape" }}
                          />
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Form>
                <Row className="justify-content-center mt-4">
                  <Button
                    className="btn-save shadow-none btn-block"
                    onClick={() => UpdatePassword()}
                  >
                    Salvar
                  </Button>
                </Row>
              </Card.Body>
            </div>
          </div>
        </Container>
      </Fragment>
    );
}

export default CAProfile;