import { React, useState } from "react";
import { Col, Row, Button, Card, Form, InputGroup, Image, } from "react-bootstrap";
import PolicyGroupImg from "../../../assets/img/policy-group.svg"
import * as Bi from "react-icons/bi";

function Identification(props) {
  const [groupName, setGroupName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");

  function SendInfo() {
    props.ident(groupName, groupDescription)
  }

  return (
    <Form onSubmit={() => SendInfo()} >
      <Card>
        <Card.Body className="my-0">
          <Card.Text>IDENTIFICAÇÃO DO GRUPO</Card.Text>
          <Row className="p-0">
            <Col >
              <Image
                fluid
                src={PolicyGroupImg}
                style={{ width: 120 }}
              />
            </Col>

            <Col xs={9}>

              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <Bi.BiShieldQuarter size={20} />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Nome do grupo"
                  defaultValue={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                  required
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <Bi.BiShieldQuarter size={20} />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Descrição"
                  defaultValue={groupDescription}
                  onChange={(e) => setGroupDescription(e.target.value)}
                />
              </InputGroup>

            </Col>

          </Row>


          <Row>
            <Col align="end">
              <Button className=" BtnPrimary shadow-none" type="submit" >
                Próximo
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Form >
  );
}

export default Identification;
