import React, { useState } from "react";
import {
  Card, Col, Container, Nav, Navbar, Row
} from "react-bootstrap";
import * as IO from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../SideBar";
import Identification from "./Identification";
import Schedules from "./Schedules";
import "./styles.css";


function AddPolicy(props) {

  const [policyOptions, setPolicyOptions] = useState("I");
  const back = useHistory();
  const policyOptionA = () => {
    if (Object.keys(identificationData).length === 0) {
      toast.warning("Preencha as informações de Identificação")
    } else {
      setPolicyOptions("A");
    }
  };
  const policyOptionI = () => {
    setPolicyOptions("I");
  };

  const [identificationData, setIdentificationData] = useState({});

  function GetIdentificationData(acEntryId, policyName, policyValue, policyRequiredKey, policyLevel, policyIsDeny) {
    const t = {
      AcEntryId: acEntryId,
      Name: policyName,
      Value: policyValue,
      RequiredKey: policyRequiredKey,
      PolicyLevel: policyLevel,
      IsDeny: policyIsDeny

    }
    setIdentificationData(t)
    setPolicyOptions("A")
  }
  return (
    <>
      =      <Container className="p-4" fluid="xxl">
        <Row>
          <Col className="col-auto">
            <Sidebar />
          </Col>
          <Col>
            <Row className="d-flex align-items-center mt-5 mb-3">
              <Col className="col-auto">
                <h3 className="d-flex align-items-center justify-content-center ">
                  <IO.IoArrowBackCircle
                    style={{ cursor: "pointer" }}
                    size="35"
                    className="me-4"
                    onClick={() => back.goBack()}
                  />
                  Adicionar Política
                </h3>
              </Col>
              <Col>
                <Navbar>
                  <Row>
                    <Col>
                      <Nav>
                        <Card className="px-2">
                          <Row>
                            <Col >
                              <Nav.Link
                                onClick={policyOptionI}
                                xs={6}
                                className={
                                  policyOptions === "I"
                                    ? "border-navbar p-2"
                                    : "navbar-link p-2"
                                }
                              >
                                <small>IDENTIFICAÇÃO</small>
                              </Nav.Link>
                            </Col>
                            <Col >
                              <Nav.Link

                                onClick={policyOptionA}
                                className={
                                  policyOptions === "A"
                                    ? "border-navbar p-2"
                                    : "navbar-link p-2"
                                }
                              >
                                <small>AGENDAMENTOS</small>
                              </Nav.Link>
                            </Col>
                          </Row>
                        </Card>
                      </Nav>
                    </Col>
                  </Row>
                </Navbar>
              </Col>
              <Container fluid="sm">
                <Row>
                  {policyOptions === "I" ? (
                    <Col md={8}>
                      <Identification id={props} t={GetIdentificationData} />
                    </Col>
                  ) : policyOptions === "A" ? (
                    <Schedules i={identificationData} />
                  ) : (
                    <Identification />
                  )}
                </Row>
              </Container>
            </Row>
            {/*  <Row>
              <Card>
                <Card.Title>Identificação</Card.Title>
                <Card.Body></Card.Body>
              </Card>
            </Row> */}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AddPolicy;
