import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal, Card, InputGroup, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "../../services/api";
import history from "../../services/history";

import * as MD from 'react-icons/md';

function PasswordRecover(props) {
  const { register, handleSubmit, control } = useForm(); //watch, errors
  const [loading, setLoading] = useState(false);
  const [modalSendNewPassword, setModalSendNewPassword] = useState();
  const [email, setEmail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [path, setPath] = useState(props.match.path);

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [oldPassword, setOldPassword] = useState();
  const [newCustomerPassword, setNewCustomerPassword] = useState();
  const [newCustomerPasswordConfirmation, setNewCustomerPasswordConfirmation] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPassworConfirmation] = useState(false);

  useEffect(() => {

    if (window.location.pathname !== "/authentication/passwordRecover" && window.location.search === "") {
      history.push("/")
    } else if (window.location.pathname === "/authentication/confirmpasswordrecover" && !window.location.search.includes("updateCode=")) {
      history.push("/")
    }
  }, []);


  async function ConfirmPasswordRecover(url) {
    if (!!newPassword || !!confirmNewPassword) {

      setLoading(true);
      try {

        const obj = {
          NewPassword: newPassword,
          ConfirmPassword: confirmNewPassword
        }
        const response = await api.post(`/authentication/confirmpasswordrecover${url}`, obj);
        const data = response.data;
        const { message, status } = data;
        if (response.status === 200 && status) {
          setConfirmPassword(true);
          toast.success("Senha Alterada com sucesso!");
          history.push("/")
        } else {
          toast.error(`Erro na solicitação.. ${message}`);
        }
      } catch (error) {
        if ((!!error.response)) {
          toast.error(Object.values(error.response.data.errors)[0][0]);
        }
      }
      setLoading(false);
    } else {
      toast.warn("Preencha todos os campos!");
    }
  }
  async function SendNewPassword({ emailNewPassword }) {
    setLoading(true);
    setEmail(emailNewPassword);
    try {
      const response = await api.post(`/authentication/requestpasswordrecover/${emailNewPassword}`);
      const data = response.data;
      const { message, status } = data;
      if (response.status === 200 && status) {
        setModalSendNewPassword(true);
      } else {
      }
    } catch (error) {
      if ((!!error.response && error.response.status !== 401)) {
        toast.error("Erro na solicitação.. tente mais tarde");
      }
    }
    setLoading(false);
  }

  if (path === "/authentication/confirmpasswordrecover" && confirmPassword === false) {
    return (
      <Fragment>
        <Container >
          <div className="center-card">
            <div className="d-flex align-items-center justify-content-center ">
              <Card.Body className="cardx">
                <div className="close">
                  <MD.MdClose
                    size={30}
                    onClick={() => {
                      history.push("/");
                    }}
                  />
                </div>
                <Card.Header as="h2">Alterar Senha</Card.Header>
                <Form>

                  <Form.Group className="mt-3 px-4">
                    <Form.Label as="h4">Nova Senha</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={`${showPassword !== false ? "text" : "password"}`}
                        onChange={(e) => setNewPassword(e.target.value)}
                        style={{ color: "#FFFFFF" }}
                        className="form-control"
                        placeholder="Digite a nova senha"
                      />
                      <InputGroup.Text
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <MD.MdVisibility
                            size={20}
                            style={{ color: "var(--shape" }}
                          />
                        ) : (
                          <MD.MdVisibilityOff
                            size={20}
                            style={{ color: "var(--shape" }}
                          />
                        )}
                      </InputGroup.Text>
                    </InputGroup>

                    <Form.Text id="passwordHelpBlock" muted>
                      Sua senha deve ter de 8 a 20 caracteres, conter letras e
                      números e não deve conter espaços, caracteres especiais ou
                      emoji.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mt-3 px-4">
                    <Form.Label as="h4">Confirmar Nova Senha</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={`${showPasswordConfirmation !== false
                          ? "text"
                          : "password"
                          }`}
                        onChange={(e) =>
                          setConfirmNewPassword(e.target.value)
                        }
                        style={{ color: "#FFFFFF" }}
                        className="form-control"
                        placeholder="Confirme sua nova senha"
                      />
                      <InputGroup.Text
                        onClick={() =>
                          setShowPassworConfirmation(!showPasswordConfirmation)
                        }
                      >
                        {showPasswordConfirmation ? (
                          <MD.MdVisibility
                            size={20}
                            style={{ color: "var(--shape" }}
                          />
                        ) : (
                          <MD.MdVisibilityOff
                            size={20}
                            style={{ color: "var(--shape" }}
                          />
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Form>
                <Row className="justify-content-center mt-4">
                  <Button
                    className="btn-save shadow-none btn-block"
                    onClick={() => ConfirmPasswordRecover(props.location.search)}
                  >
                    Salvar
                  </Button>
                </Row>
              </Card.Body>
            </div>
          </div>

        </Container>
      </Fragment >
    );
  }
  else if (path === "/authentication/confirmpasswordrecover" && confirmPassword === true) {
    return (
      <Fragment>
        <Container>
          <Row className="justify-content-center">
            <Col className="col-8">
              <Card className="mt-5" style={{ backgroundColor: '#2D3748' }}>
                <Row className="justify-content-center">
                  <Col className="m-4">
                    <strong><p className="text-center">Senha alterada com sucesso!</p></strong>

                  </Col>
                </Row>
                <Row className="justify-content-center mb-4">
                  <Col className="col-3">
                    <Button block onClick={() => history.push("/")}>Ok</Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
  else {
    return (
      <Fragment>
        <Container>
          <Row className="justify-content-center">
            <Col className="col-8">
              <Card className="mt-5" style={{ backgroundColor: '#2D3748' }}>
                <form className="px-4" onSubmit={handleSubmit(SendNewPassword)}>
                  <Row className="mt-4">
                    <Col>
                      <strong><p className="text-center">Não tem problema! Só precisamos do E-mail que você usou ao criar seu cadastro.</p></strong>
                    </Col>
                  </Row>
                  <Row className="mb-4 mt-2 justify-content-center">
                    <Col className="col-8 p-0">
                      <label htmlFor="emailNewPassword">E-mail de cadastro</label>
                      <input style={{ borderRadius: "3px 0px 0px 3px" }} className="form-control border-right-0 text-white" type="email" placeholder="E-mail" id="emailNewPassword" name="emailNewPassword" required ref={register} />
                    </Col>
                    <Col className="col-3 p-0 align-self-end">
                      <Button style={{ borderRadius: "0px 3px 3px 0px" }} variant="outline-primary" className="text-center" block type={loading ? null : "submit"}>
                        {loading ? "Carregando..." : "Enviar nova senha"}
                      </Button>
                    </Col>
                  </Row>
                </form>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal

          centered
          className="mt-5 pt-3"
          size="lg"
          show={modalSendNewPassword}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header className="justify-content-center">
            <Modal.Title className="text-center" id="example-modal-sizes-title-lg">
              {`Pedido recebido. Caso exista um usuário com o email ${email}, será enviado um link para recuperar a senha`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center">
              <Col className="col-3 text-center mt-3">
                <Button type="button" variant="primary" block onClick={() => { setModalSendNewPassword(false); history.push(`/`) }}>OK</Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}

export default PasswordRecover;